import React from 'react'
import { useSelector } from 'react-redux';
import { Alert } from '../../components/Alert'
import Sidebar from '../../components/Navigation/Sidebar';
import Topbar from '../../components/Navigation/Topbar';
import { LOCAL_USER_KEY } from '../../constants/keys';
import { getLocalStorage } from '../../services/localServices';

export const AuthWrapper = (Component, is_sia = false, user_type = 'administrator') => {
    // const { data: userData } = useSelector((state) => state.auth)
    const userData = JSON.parse(getLocalStorage(LOCAL_USER_KEY))
    // console.log('userData', userData)
    return (props) => (
        <>
            <div>

                <div id="wrapper">
                    {userData?.user?.user_type === 'administrator' ?
                        <Sidebar key={'sidebar'} /> : ""}
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Topbar />
                            <div className="container-fluid">
                                <Component {...props} />
                            </div>
                        </div>
                        <footer className="sticky-footer bg-white">
                            <div className="container my-auto">
                                <div className="copyright text-center my-auto">
                                    <span>Copyright &copy; alsoCms v1.0</span>
                                </div>
                            </div>
                        </footer>
                    </div>
                    <a className="scroll-to-top rounded" href="#page-top">
                        <i className="fas fa-angle-up"></i>
                    </a>
                </div>
            </div>
            <Alert />
        </>
    );

}
