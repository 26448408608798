import * as actionTypes from "../../../../constants/actionTypes";
import { updateObject } from "../../../../constants/utility";

const initialState = {
    loading: null,
    lessonCreate: null,
    lessonUpdate: null,
    lessonDelete: null,
    lesson: null,
    lessons: null,
    importCsv: null
};

const loading = (state, data) => {
    return updateObject(state, {
        loading: data
    })
}


const lessonCreate = (state, data) => {
    return updateObject(state, {
        lessonCreate: data,
    });
}

const getLesson = (state, data) => {
    return updateObject(state, {
        lesson: data,
    });
}

const getLessons = (state, data) => {
    return updateObject(state, {
        lessons: data,
    });
}

const lessonUpdate = (state, data) => {
    return updateObject(state, {
        lessonUpdate: data,
    });
}

const lessonDelete = (state, data) => {
    return updateObject(state, {
        lessonDelete: data,
    });
}

const setImportCsv = (state, data) => {
    return updateObject(state, {
        importCsv: data,
    });
}

const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
        case actionTypes.POST_IMPORT_CSV:
            return setImportCsv(state, data);
        case actionTypes.LESSON_DELETE:
            return lessonDelete(state, data);
        case actionTypes.LESSON_UPDATE:
            return lessonUpdate(state, data);
        case actionTypes.GET_LESSONS:
            return getLessons(state, data);
        case actionTypes.GET_LESSON:
            return getLesson(state, data);
        case actionTypes.LESSON_CREATE:
            return lessonCreate(state, data);
        case actionTypes.ON_LOADING:
            return loading(state, data);
        default:
            return state;
    }
};

export default reducer;
