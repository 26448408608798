import * as actionTypes from '../../../../constants/actionTypes';
import http from "../../../../services/NetworkService";

export const setOnloading = (payload) => {
    return {
        type: actionTypes.ON_LOADING,
        data: payload,
    };
};

export const setOneGetStudentClassLessons = (payload) => {
    return {
        type: actionTypes.ONE_STUDENT_CLASS_LESSONS,
        data: payload,
    };
};
export const getOneStudentClassLessons = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const params = {
            page: data?.page || 1,
            page_size: data?.page_size || 10,
            search: data?.search,
            orderby: data?.sort?.orderby || null,
            order: data?.sort?.order || null,
        };

        const result = await http.get(`/sia/activity/one-student-class-lesson`, { params });
        dispatch(setOneGetStudentClassLessons(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}


export const setGetStudentClassLesson = (payload) => {
    return {
        type: actionTypes.STUDENT_CLASS_LESSON,
        data: payload,
    };
};

export const getStudentClassLessonOne = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/sia/activity/student-class-lesson/` + data);
        dispatch(setGetStudentClassLesson(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetStudentClassLessons = (payload) => {
    return {
        type: actionTypes.STUDENT_CLASS_LESSONS,
        data: payload,
    };
};
export const getStudentClassLessons = (classroom_id, eduyear_id, semester_id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const params = {
            page: data?.page || 1,
            page_size: data?.page_size || 10,
            search: data?.search,
            orderby: data?.sort?.orderby || null,
            order: data?.sort?.order || null,
        };

        const result = await http.get(`/sia/activity/student-class-lesson/classroom/${classroom_id}/eduyear/${eduyear_id}/semester/${semester_id}`, { params });
        dispatch(setGetStudentClassLessons(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetStudentClassLessonStudents = (payload) => {
    return {
        type: actionTypes.STUDENT_CLASS_LESSON_STUDENTS,
        data: payload,
    };
};

export const getStudentClassLessonStudents = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/sia/activity/student-class-lesson/${data}/students`,);
        dispatch(setGetStudentClassLessonStudents(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

// materials

export const setGetStudentClassLessonMaterials = (payload) => {
    return {
        type: actionTypes.STUDENT_CLASS_LESSON_MATERIALS,
        data: payload,
    };
};
export const getStudentClassLessonMaterials = (lesson_class_id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/sia/activity/student-class-lesson/${lesson_class_id}/materials`);
        dispatch(setGetStudentClassLessonMaterials(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

// tasks- --------------------------

export const setGetStudentClassLessonTasks = (payload) => {
    return {
        type: actionTypes.STUDENT_CLASS_LESSON_TASKS,
        data: payload,
    };
};
export const getStudentClassLessonTasks = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))

        const result = await http.get(`/sia/activity/student-class-lesson/${data}/tasks`);
        dispatch(setGetStudentClassLessonTasks(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}


// -------------
export const setGetStudentClassLessonTaskSubmit = (payload) => {
    return {
        type: actionTypes.STUDENT_CLASS_LESSON_TASK_SUBMIT,
        data: payload,
    };
};
export const getStudentClassLessonTaskSubmit = (lesson_class_id, id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))

        const result = await http.get(`/sia/activity/student-class-lesson/${lesson_class_id}/task/${id}/submit`);
        dispatch(setGetStudentClassLessonTaskSubmit(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPostStudentClassLessonTaskSubmit = (payload) => {
    return {
        type: actionTypes.STUDENT_CLASS_LESSON_TASK_SUBMIT_CREATE,
        data: payload,
    };
};
export const postStudentClassLessonTaskSubmit = (lesson_class_id, task_id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))

        const result = await http.post(`/sia/activity/student-class-lesson/${lesson_class_id}/task/${task_id}/submit`, data);
        dispatch(setPostStudentClassLessonTaskSubmit(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}


export const setPutStudentClassLessonTaskSubmit = (payload) => {
    return {
        type: actionTypes.STUDENT_CLASS_LESSON_TASK_SUBMIT_UPDATE,
        data: payload,
    };
};
export const putStudentClassLessonTaskSubmit = (lesson_class_id, task_id, id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))

        const result = await http.put(`/sia/activity/student-class-lesson/${lesson_class_id}/task/${task_id}/submit/${id}`, data);
        dispatch(setPutStudentClassLessonTaskSubmit(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setDeleteStudentClassLessonTaskSubmit = (payload) => {
    return {
        type: actionTypes.STUDENT_CLASS_LESSON_TASK_SUBMIT_DELETE,
        data: payload,
    };
};
export const deleteStudentClassLessonTaskSubmit = (lesson_class_id, task_id, id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))

        const result = await http.delete(`/sia/activity/student-class-lesson/${lesson_class_id}/task/${task_id}/submit/${id}`);
        dispatch(setDeleteStudentClassLessonTaskSubmit(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}


export const setPostUploadAttachment = (payload) => {
    return {
        type: actionTypes.STUDENT_CLASS_LESSON_ATTACHMENT_UPLOAD,
        data: payload,
    };
};
export const postUploadAttachment = (data) => async (dispatch) => {
    try {
        const result = await http.post(`/sia/activity/student-class-lesson/upload-attachment`, data);
        // console.log('alsocodes http', result)
        dispatch(setPostUploadAttachment(result));
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}