import { Button } from 'bootstrap'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import CardBasic from '../../../../../components/Cards/Basic'
import Progressbar from '../../../../../components/Progressbar'
import SiaHeader from '../../../../../components/SiaHeader'
import TextInput from '../../../../../components/TextInput'
import { notify } from '../../../../../constants/utility'
import { collapseSidebar, setMenuActive } from '../../../../../redux/actions'
import { getClassroomOne, postCreateClassroom, putUpdateClassroom, setGetClassroom, setClassroomCreate, setClassroomUpdate } from '../../../../../redux/actions/sia-master/classroom'
import { AuthWrapper } from '../../../../AuthWrapper'

const ClassroomMasterForm = (props) => {
    const { name, text, path, title, parent } = props.data
    const { loading, classroom, classroomCreate, classroomUpdate } = useSelector((state) => state.classroom);
    let back = path.replace(`/${path.split("/")[path.split("/").length - 1]}`, "")
    if (back.includes('/edit')) back = back.replace('/edit', '')

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()

    useEffect(() => {
        dispatch(setMenuActive({ menuActive: 'sia', subMenuActive: name }))
        dispatch(collapseSidebar())
        dispatch(setGetClassroom(null))
    }, [])

    const code_options = [
        { value: 'VII', label: 'VII' },
        { value: 'VIII', label: 'VIII' },
        { value: 'IX', label: 'IX' },
    ]

    const room_options = [
        { value: 'A', label: 'A' },
        { value: 'B', label: 'B' },
        { value: 'C', label: 'C' },
        { value: 'D', label: 'D' },
        { value: 'E', label: 'E' },
        { value: 'F', label: 'F' },
        { value: 'G', label: 'G' },
        { value: 'H', label: 'H' },
        { value: 'I', label: 'I' },
        { value: 'J', label: 'J' },
    ]

    const { id } = useParams()

    useEffect(() => {
        if (id) {
            dispatch(getClassroomOne(id))
        }
    }, [id])

    useEffect(() => {
        let code_option = code_options.filter(item => item.value === classroom?.result?.code)
        let room_option = room_options.filter(item => item.value === classroom?.result?.room)
        setValue('code', code_option?.[0] || '')
        setValue('room', room_option?.[0] || '')
        setValue('name', classroom?.result?.name || '')

    }, [classroom])

    useEffect(() => {
        if (classroomCreate) {
            notify(classroomCreate.message, classroomCreate.success ? 'success' : 'error')
            navigate("/sia/master/classroom/edit/" + classroomCreate.result?.id, { replace: true })
        }

        return () => {
            dispatch(setClassroomCreate(null))
        }
    }, [classroomCreate])

    useEffect(() => {
        if (classroomUpdate) {
            notify(classroomUpdate.message, classroomUpdate.success ? 'success' : 'error')

            dispatch(getClassroomOne(id))
            dispatch(setClassroomUpdate(null))
        }

    }, [classroomUpdate])

    const submitForm = (data) => {
        data.code = data.code.value
        data.room = data.room.value
        if (loading === true) return;
        if (id) dispatch(putUpdateClassroom(id, data))
        else dispatch(postCreateClassroom(data))
    }

    return (
        <div>
            <Progressbar loading={loading} />
            <SiaHeader />
            <div className="mt-2"></div>
            <CardBasic title={title} nopadding goBack={back}>
                <div className="p-4">
                    <form onSubmit={handleSubmit(submitForm)} >
                        <div className="row">
                            <div className="col-md-6">
                                <Controller
                                    name="code"
                                    control={control}
                                    render={({ field }) =>
                                        <TextInput
                                            validations={{ required: 'Harus diisi' }}
                                            layout="horizontal"
                                            label="Kode"
                                            type="select"
                                            // name="code"
                                            placeholder="Kode Kelas"
                                            register={register}
                                            field={field}
                                            errors={formState.errors}
                                            options={code_options}
                                        />
                                    }
                                />
                                <Controller
                                    name="room"
                                    control={control}
                                    render={({ field }) =>
                                        <TextInput
                                            defaultValue={classroom?.result?.room}
                                            validations={{ required: 'Harus diisi' }}
                                            layout="horizontal"
                                            label="Ruangan"
                                            type="select"
                                            name="room"
                                            placeholder="Ruangan"
                                            register={register}
                                            errors={formState.errors}
                                            field={field}
                                            options={room_options}
                                        />
                                    }
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Nama Kelas"
                                    type="text"
                                    name="name"
                                    placeholder="Nama Kelas"
                                    register={register}

                                    errors={formState.errors}
                                />

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <hr />
                                <button type="submit" className="btn btn-primary btn-md">
                                    Simpan
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </CardBasic>

        </div>
    )
}

export default AuthWrapper(ClassroomMasterForm)
