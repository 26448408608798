import * as actionTypes from "../../../../constants/actionTypes";
import { updateObject } from "../../../../constants/utility";

const initialState = {
    loading: null,
    teacherClassLesson: null,
    teacherClassLessons: null,
    teacherClassLessonStudents: null,

    teacherClassLessonTaskStudents: null,

    teacherClassLessonTasks: null,
    teacherClassLessonTaskCreate: null,
    teacherClassLessonTaskUpdate: null,
    teacherClassLessonTaskDelete: null,
    teacherClassLessonTaskSubmits: null,

    teacherClassLessonMaterials: null,
    teacherClassLessonMaterialCreate: null,
    teacherClassLessonMaterialUpdate: null,
    teacherClassLessonMaterialDelete: null,
    teacherClassLessonAttachmentUpload: null,
};

const loading = (state, data) => {
    return updateObject(state, {
        loading: data
    })
}

const getTeacherClassLesson = (state, data) => {
    return updateObject(state, {
        teacherClassLesson: data,
    });
}

const getTeacherClassLessons = (state, data) => {
    return updateObject(state, {
        teacherClassLessons: data,
    });
}

const getTeacherClassLessonStudents = (state, data) => {
    return updateObject(state, {
        teacherClassLessonStudents: data,
    });
}

// task
const getTeacherClassLessonTasks = (state, data) => {
    return updateObject(state, {
        teacherClassLessonTasks: data,
    });
}

const getTeacherClassLessonTaskCreate = (state, data) => {
    return updateObject(state, {
        teacherClassLessonTaskCreate: data,
    });
}

const getTeacherClassLessonTaskUpdate = (state, data) => {
    return updateObject(state, {
        teacherClassLessonTaskUpdate: data,
    });
}

const getTeacherClassLessonTaskDelete = (state, data) => {
    return updateObject(state, {
        teacherClassLessonTaskDelete: data,
    });
}

const getTeacherClassLessonTaskSubmits = (state, data) => {
    return updateObject(state, {
        teacherClassLessonTaskSubmits: data,
    });
}

// //task

const getTeacherClassLessonTaskStudents = (state, data) => {
    return updateObject(state, {
        teacherClassLessonTaskStudents: data,
    });
}

const getTeacherClassLessonMaterials = (state, data) => {
    return updateObject(state, {
        teacherClassLessonMaterials: data,
    });
}

const teacherClassLessonMaterialCreate = (state, data) => {
    return updateObject(state, {
        teacherClassLessonMaterialCreate: data,
    });
}

const teacherClassLessonMaterialUpdate = (state, data) => {
    return updateObject(state, {
        teacherClassLessonMaterialUpdate: data,
    });
}

const teacherClassLessonMaterialDelete = (state, data) => {
    return updateObject(state, {
        teacherClassLessonMaterialDelete: data,
    });
}

const teacherClassLessonAttachmentUpload = (state, data) => {
    return updateObject(state, {
        teacherClassLessonAttachmentUpload: data,
    });
}

const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
        case actionTypes.TEACHER_CLASS_LESSON_MATERIAL_DELETE:
            return teacherClassLessonMaterialDelete(state, data);
        case actionTypes.TEACHER_CLASS_LESSON_MATERIAL_UPDATE:
            return teacherClassLessonMaterialUpdate(state, data);
        case actionTypes.TEACHER_CLASS_LESSON_MATERIAL_CREATE:
            return teacherClassLessonMaterialCreate(state, data);
        case actionTypes.TEACHER_CLASS_LESSON_ATTACHMENT_UPLOAD:
            return teacherClassLessonAttachmentUpload(state, data);
        case actionTypes.TEACHER_CLASS_LESSON_MATERIALS:
            return getTeacherClassLessonMaterials(state, data);

        case actionTypes.TEACHER_CLASS_LESSON_TASKS:
            return getTeacherClassLessonTasks(state, data);
        case actionTypes.TEACHER_CLASS_LESSON_TASK_CREATE:
            return getTeacherClassLessonTaskCreate(state, data);
        case actionTypes.TEACHER_CLASS_LESSON_TASK_UPDATE:
            return getTeacherClassLessonTaskUpdate(state, data);
        case actionTypes.TEACHER_CLASS_LESSON_TASK_DELETE:
            return getTeacherClassLessonTaskDelete(state, data);
        case actionTypes.TEACHER_CLASS_LESSON_TASK_SUBMITS:
            return getTeacherClassLessonTaskSubmits(state, data);

        case actionTypes.TEACHER_CLASS_LESSON_TASK_STUDENTS:
            return getTeacherClassLessonTaskStudents(state, data);

        case actionTypes.TEACHER_CLASS_LESSON_STUDENTS:
            return getTeacherClassLessonStudents(state, data);
        case actionTypes.TEACHER_CLASS_LESSONS:
            return getTeacherClassLessons(state, data);
        case actionTypes.TEACHER_CLASS_LESSON:
            return getTeacherClassLesson(state, data);
        case actionTypes.ON_LOADING:
            return loading(state, data);
        default:
            return state;
    }
};

export default reducer;
