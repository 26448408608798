import * as actionTypes from '../../../../constants/actionTypes';
import http from "../../../../services/NetworkService";

export const setOnloading = (payload) => {
    return {
        type: actionTypes.ON_LOADING,
        data: payload,
    };
};

export const setGetLessonClassStudent = (payload) => {
    return {
        type: actionTypes.GET_LESSON_CLASS_STUDENT,
        data: payload,
    };
};

export const getLessonClassStudentOne = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/sia/activity/lesson-class-student/` + data);
        dispatch(setGetLessonClassStudent(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetlessonClassStudents = (payload) => {
    return {
        type: actionTypes.GET_LESSON_CLASS_STUDENTES,
        data: payload,
    };
};

export const getLessonClassStudentAll = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const params = {
            page: data?.page || 1,
            page_size: data?.page_size || 10,
            search: data?.search,
            orderby: data?.sort?.orderby || null,
            order: data?.sort?.order || null,
            filter: data?.filter ? encodeURIComponent(JSON.stringify(data?.filter)) : null,
        };

        const result = await http.get(`/sia/activity/lesson-class-student`, { params });
        dispatch(setGetlessonClassStudents(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setLessonClassStudentCreate = (payload) => {
    return {
        type: actionTypes.LESSON_CLASS_STUDENT_CREATE,
        data: payload,
    };
}

export const postCreateLessonClassStudent = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/sia/activity/lesson-class-student`, data);
        dispatch(setOnloading(null))
        dispatch(setLessonClassStudentCreate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setLessonClassStudentUpdate = (payload) => {
    return {
        type: actionTypes.LESSON_CLASS_STUDENT_UPDATE,
        data: payload,
    };
}

export const putUpdateLessonClassStudent = (id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.put(`/sia/activity/lesson-class-student/${id}`, data);
        dispatch(setOnloading(null))
        dispatch(setLessonClassStudentUpdate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}


export const setLessonClassStudentDelete = (payload) => {
    return {
        type: actionTypes.LESSON_CLASS_STUDENT_DELETE,
        data: payload,
    };
}

export const deleteLessonClassStudent = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.delete(`/sia/activity/lesson-class-student/${id}`);
        dispatch(setOnloading(null))
        dispatch(setLessonClassStudentDelete(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetLessonClassStudentOptForm = (payload) => {
    return {
        type: actionTypes.GET_LESSON_CLASS_STUDENT_OPTFORM,
        data: payload,
    };
};

export const getLessonClassStudentOptForm = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))

        const result = await http.get(`/sia/activity/lesson-class-student-opt`);
        dispatch(setGetLessonClassStudentOptForm(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetLessonClassStudentByClassYears = (payload) => {
    return {
        type: actionTypes.GET_LESSON_CLASS_STUDENT_BY_CLASS_YEAR,
        data: payload,
    };
};

export const getLessonClassStudentByClassYears = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        console.log("awowo xx", data)
        const params = {
            lesson_class_id: data.lesson_class_id,
        }
        const result = await http.get(`/sia/activity/lesson-class-student/by-class-year`, { params });
        dispatch(setGetLessonClassStudentByClassYears(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}