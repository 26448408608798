import * as actionTypes from "../../../../constants/actionTypes";
import { updateObject } from "../../../../constants/utility";

const initialState = {
    loading: null,
    rapor: null,
    rapors: null,
    raporUpdate: null,
    raporDelete: null,
    bulkRaporUpload: null,
    formOpt: null,
};

const loading = (state, data) => {
    return updateObject(state, {
        loading: data
    })
}

const rapor = (state, data) => {
    return updateObject(state, {
        rapor: data
    })
}

const rapors = (state, data) => {
    return updateObject(state, {
        rapors: data
    })
}

const formOpt = (state, data) => {
    return updateObject(state, {
        formOpt: data
    })
}

const raporUpdate = (state, data) => {
    return updateObject(state, {
        raporUpdate: data
    })
}

const raporDelete = (state, data) => {
    return updateObject(state, {
        raporDelete: data
    })
}

const bulkRaporUpload = (state, data) => {
    return updateObject(state, {
        bulkRaporUpload: data
    })
}

const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
        case actionTypes.FORM_OPT:
            return formOpt(state, data);
        case actionTypes.BULK_RAPOR_UPLOAD:
            return bulkRaporUpload(state, data);
        case actionTypes.RAPOR_DELETE:
            return raporDelete(state, data);
        case actionTypes.RAPOR_UPDATE:
            return raporUpdate(state, data);
        case actionTypes.GET_RAPORS:
            return rapors(state, data);
        case actionTypes.GET_RAPOR:
            return rapor(state, data);
        case actionTypes.ON_LOADING:
            return loading(state, data);
        default:
            return state;
    }
};

export default reducer;
