import React, { useEffect, useState } from 'react'
import { Button, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import CardBasic from '../../../../../components/Cards/Basic';
import Progressbar from '../../../../../components/Progressbar';
import SiaHeader from '../../../../../components/SiaHeader';
import { getTeacherClassLessonOne, getTeacherClassLessonStudents } from '../../../../../redux/actions/sia-activity/teacher-class-lesson';
import { AuthWrapper } from '../../../../AuthWrapper';
import TeacherClassLessonMaterials from './Materials';
import TeacherClassLessonStudents from './Students';
import TeacherClassLessonTasks from './Tasks';

const TeacherClassLessonDetail = (props) => {
    const { name, text, path, title, parent } = props.data
    const { loading, teacherClassLesson, teacherClassLessonDelete } = useSelector((state) => state.teacherClassLesson);
    const dispatch = useDispatch()
    const { id } = useParams()
    const [searchParams] = useSearchParams();
    const tabActive = searchParams.get("tab-active")
    const [tabKey, setTabKey] = useState(tabActive);

    useEffect(() => {
        dispatch(getTeacherClassLessonOne(id))
    }, [])

    let back = path.replace(`/${path.split("/")[path.split("/").length - 1]}`, "")
    if (back.includes('/detail')) back = back.replace('/detail', '')

    return (
        <div>
            <Progressbar loading={loading} />
            <SiaHeader />
            <div className="mt-2"></div>
            <CardBasic title={title} goBack={back}>
                <table className="custom-table mb-4">
                    <thead>
                        <tr>
                            <th>Kelas/Mapel</th>
                            <th>:</th>
                            <th>{`${teacherClassLesson?.result?.classroom?.name || ''}/${teacherClassLesson?.result?.lesson?.name || ''}`}</th>
                            <th>Guru Pengampu</th>
                            <th>:</th>
                            <th>{`${teacherClassLesson?.result?.teacher?.name || ''}`}</th>
                        </tr>
                        <tr>
                            <th>Tahun/Semester</th>
                            <th>:</th>
                            <th>{`${teacherClassLesson?.result?.eduyear?.name || ''}-${teacherClassLesson?.result?.semester?.name || ''}`}</th>
                            <th>Status</th>
                            <th>:</th>
                            <th>{`${teacherClassLesson?.result?.status || ''}`}</th>
                        </tr>

                    </thead>
                </table>
                <hr />
                <Tabs
                    id="controlled-tab-example"
                    activeKey={tabKey}
                    onSelect={(k) => setTabKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="student" title="Peserta/Siswa" className="pb-4">
                        <TeacherClassLessonStudents tabKey={tabKey} id={id} />
                    </Tab>
                    <Tab eventKey="material" title="Materi Pelajaran" className="pb-4">
                        <TeacherClassLessonMaterials tabKey={tabKey} id={id} />
                    </Tab>
                    <Tab eventKey="task" title="Tugas" className="pb-4">
                        <TeacherClassLessonTasks tabKey={tabKey} id={id} />
                    </Tab>
                </Tabs>
            </CardBasic>
        </div>
    )
}

export default AuthWrapper(TeacherClassLessonDetail)
