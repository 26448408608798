import { Button } from 'bootstrap'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import CardBasic from '../../../../../components/Cards/Basic'
import Progressbar from '../../../../../components/Progressbar'
import SiaHeader from '../../../../../components/SiaHeader'
import TextInput from '../../../../../components/TextInput'
import { notify } from '../../../../../constants/utility'
import { collapseSidebar, setMenuActive } from '../../../../../redux/actions'
import { getEduyearOne, postCreateEduyear, putUpdateEduyear, setGetEduyear, setEduyearCreate, setEduyearUpdate } from '../../../../../redux/actions/sia-master/eduyear'
import { AuthWrapper } from '../../../../AuthWrapper'

const EduyearMasterForm = (props) => {
    const { name, text, path, title, parent } = props.data
    const { loading, eduyear, eduyearCreate, eduyearUpdate } = useSelector((state) => state.eduyear);
    let back = path.replace(`/${path.split("/")[path.split("/").length - 1]}`, "")
    if (back.includes('/edit')) back = back.replace('/edit', '')

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()

    const [yearOption, setYearOption] = useState([])

    useEffect(() => {
        dispatch(setMenuActive({ menuActive: 'sia', subMenuActive: name }))
        dispatch(collapseSidebar())
        dispatch(setGetEduyear(null))
        const curDate = new Date()
        let curYear = curDate.getFullYear()
        let maxYear = curYear + 10
        while (curYear < maxYear) {
            let year = { value: curYear, label: curYear }
            setYearOption(prev => [...prev, year])
            curYear++;
        }
        console.log('awowo', curYear)
    }, [])

    const { id } = useParams()

    useEffect(() => {
        if (id) {
            dispatch(getEduyearOne(id))
        }
    }, [id])

    useEffect(() => {
        if (eduyear?.result?.code) {
            setYearOption([{ value: eduyear.result.code, label: eduyear.result.code }])
            setValue('code', { value: eduyear.result.code, label: eduyear.result.code })
        } else {
            setValue('code', {})
        }
        setValue('name', eduyear?.result?.name || '')

    }, [eduyear])

    useEffect(() => {
        if (eduyearCreate) {
            notify(eduyearCreate.message, eduyearCreate.success ? 'success' : 'error')
            navigate("/sia/master/eduyear/edit/" + eduyearCreate.result?.id, { replace: true })
        }

        return () => {
            dispatch(setEduyearCreate(null))
        }
    }, [eduyearCreate])

    useEffect(() => {
        if (eduyearUpdate) {
            notify(eduyearUpdate.message, eduyearUpdate.success ? 'success' : 'error')

            dispatch(getEduyearOne(id))
            dispatch(setEduyearUpdate(null))
        }

    }, [eduyearUpdate])

    const submitForm = (data) => {
        data.code = data.code.value
        if (loading === true) return;
        if (id) dispatch(putUpdateEduyear(id, data))
        else dispatch(postCreateEduyear(data))
    }

    return (
        <div>
            <Progressbar loading={loading} />
            <SiaHeader />
            <div className="mt-2"></div>
            <CardBasic title={title} nopadding goBack={back}>
                <div className="p-4">
                    <form onSubmit={handleSubmit(submitForm)} >
                        <div className="row">
                            <div className="col-md-6">
                                <Controller
                                    name="code"
                                    control={control}
                                    render={({ field }) =>
                                        <TextInput
                                            defaultValue={eduyear?.result?.code}
                                            validations={{ required: 'Harus diisi' }}
                                            layout="horizontal"
                                            label="Kode"
                                            type="select"
                                            // name="code"
                                            placeholder="Kode Kelas"
                                            register={register}
                                            field={field}
                                            errors={formState.errors}
                                            options={yearOption}
                                        />
                                    }
                                />

                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Nama Tahun"
                                    type="text"
                                    name="name"
                                    placeholder="Nama Tahun"
                                    register={register}

                                    errors={formState.errors}
                                />

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <hr />
                                <button type="submit" className="btn btn-primary btn-md">
                                    Simpan
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </CardBasic>

        </div>
    )
}

export default AuthWrapper(EduyearMasterForm)
