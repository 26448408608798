import * as actionTypes from "../../../../constants/actionTypes";
import { updateObject } from "../../../../constants/utility";

const initialState = {
    loading: null,
    classroomCreate: null,
    classroomUpdate: null,
    classroomDelete: null,
    classroom: null,
    classrooms: null,
    importCsv: null
};

const loading = (state, data) => {
    return updateObject(state, {
        loading: data
    })
}


const classroomCreate = (state, data) => {
    return updateObject(state, {
        classroomCreate: data,
    });
}

const getClassroom = (state, data) => {
    return updateObject(state, {
        classroom: data,
    });
}

const getClassrooms = (state, data) => {
    return updateObject(state, {
        classrooms: data,
    });
}

const classroomUpdate = (state, data) => {
    return updateObject(state, {
        classroomUpdate: data,
    });
}

const classroomDelete = (state, data) => {
    return updateObject(state, {
        classroomDelete: data,
    });
}

const setImportCsv = (state, data) => {
    return updateObject(state, {
        importCsv: data,
    });
}

const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
        case actionTypes.POST_IMPORT_CSV:
            return setImportCsv(state, data);
        case actionTypes.CLASSROOM_DELETE:
            return classroomDelete(state, data);
        case actionTypes.CLASSROOM_UPDATE:
            return classroomUpdate(state, data);
        case actionTypes.GET_CLASSROOMS:
            return getClassrooms(state, data);
        case actionTypes.GET_CLASSROOM:
            return getClassroom(state, data);
        case actionTypes.CLASSROOM_CREATE:
            return classroomCreate(state, data);
        case actionTypes.ON_LOADING:
            return loading(state, data);
        default:
            return state;
    }
};

export default reducer;
