import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatDate } from '../../../../../../../constants/utility'
import { download } from '../../../../../../../redux/actions/download'
import { getTeacherClassLessonTaskStudents } from '../../../../../../../redux/actions/sia-activity/teacher-class-lesson'
import TaskStudents from './TaskStudents'

const Detail = (props) => {
    const { lesson_class_id, data, close } = props
    const { data: userData } = useSelector(state => state.auth)
    const { teacherClassLessonTaskStudents } = useSelector((state) => state.teacherClassLesson);
    const [tugasCollected, setTugasCollected] = useState(0)
    const dispatch = useDispatch()

    useEffect(() => {
        console.log('itrigs')
        if (userData?.user?.user_type === 'teacher') dispatch(getTeacherClassLessonTaskStudents(lesson_class_id, data.id))
    }, [userData])


    useEffect(() => {
        setTugasCollected(teacherClassLessonTaskStudents?.result?.filter(item => item.student.lesson_class_task_submit !== null)?.length || 0)
    }, [teacherClassLessonTaskStudents])

    return (
        <div>
            <div className="d-flex border-bottom mb-4 mt-2 py-2 ">
                <span
                    className="btn btn-sm btn-secondary mr-2"
                    onClick={() => close()}
                    style={{ height: "30px", width: "50px" }}><i className="fas fa-arrow-left"></i></span>
                <h6 className="font-weight-bold p-1">Detail Tugas</h6>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-2">Nama</div>
                <div className="col-sm-12 col-md-10">{data.name}</div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-2">Keterangan</div>
                <div className="col-sm-12 col-md-10">{data.description}</div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-2">Lampiran</div>
                <div className="col-sm-12 col-md-10">{data.attachment}
                    {data.attachment ?

                        <span className="btn btn-secondary btn-sm"
                            onClick={() => dispatch(download(data.attachment))}>
                            <i className="fas fa-download"></i>
                        </span>

                        : ""}
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-2">Status</div>
                <div className="col-sm-12 col-md-10">{data.status}</div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-2">Batas Pengumulan</div>
                <div className="col-sm-12 col-md-10">: {data.open_at ? formatDate(data.deadline_at) : '-'}</div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-2">Dibuka pada</div>
                <div className="col-sm-12 col-md-10">{data.open_at ? formatDate(data.open_at) : '-'}</div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-2">Ditutup pada</div>
                <div className="col-sm-12 col-md-10">{data.close_at ? formatDate(data.close_at) : '-'}</div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-2">Dibuat</div>
                <div className="col-sm-12 col-md-10">{formatDate(data.created_at)}</div>
            </div>
            {userData?.user?.user_type === 'teacher' ?
                <>
                    <div className="row mb-2">
                        <div className="col-sm-12 col-md-2">Tugas Terkumpul</div>
                        <div className="col-sm-12 col-md-10">{tugasCollected}</div>
                    </div>
                    <hr />

                    <TaskStudents />
                </>
                : ""}
            {userData?.user?.user_type === 'student' ? "student" : ""}

        </div>
    )
}

export default Detail
