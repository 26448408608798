import * as actionTypes from '../../../../constants/actionTypes';
import http from "../../../../services/NetworkService";

export const setOnloading = (payload) => {
    return {
        type: actionTypes.ON_LOADING,
        data: payload,
    };
};

export const setGetFromOpt = (payload) => {
    return {
        type: actionTypes.FORM_OPT,
        data: payload,
    };
};

export const getFormOpt = () => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/sia/activity/rapor/form-opt`);
        dispatch(setGetFromOpt(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetRapor = (payload) => {
    return {
        type: actionTypes.GET_RAPOR,
        data: payload,
    };
};

export const getRapor = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/sia/activity/rapor/` + data);
        dispatch(setGetRapor(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetRapors = (payload) => {
    return {
        type: actionTypes.GET_RAPORS,
        data: payload,
    };
};

export const getRapors = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/sia/activity/rapor`, data);
        dispatch(setGetRapors(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPutRapor = (payload) => {
    return {
        type: actionTypes.RAPOR_UPDATE,
        data: payload,
    };
};

export const putRapor = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.put(`/sia/activity/rapor`, data);
        dispatch(setPutRapor(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setDeleteRapor = (payload) => {
    return {
        type: actionTypes.GET_RAPORS,
        data: payload,
    };
};

export const deleteRapor = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.delete(`/sia/activity/rapor/${id}`);
        dispatch(setDeleteRapor(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPostBulkRaporUpload = (payload) => {
    return {
        type: actionTypes.BULK_RAPOR_UPLOAD,
        data: payload,
    };
};

export const postBulkRaporUpload = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/sia/activity/rapor/bulk-upload`, data);
        dispatch(setPostBulkRaporUpload(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}
