import React, { useEffect, useState } from 'react'
import { ProgressBar } from 'react-bootstrap';

const Progressbar = ({ loading }) => {
    const [progressValue, setProgressValue] = useState(0)
    useEffect(() => {
        if (loading === true) {
            setProgressValue(20)
            let timer = setTimeout(() => {
                setProgressValue(95)
            }, 100);
            return () => {
                setProgressValue(0)
                clearTimeout(timer)
            }
        } else {
            setProgressValue(100)
        }
    }, [loading])

    return (
        <div className={`top-progressbar` + (loading === true ? ` show-top-progressbar` : ``)}>
            <ProgressBar now={progressValue} />
        </div>
    )
}

export default Progressbar
