import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CardBasic from '../../../../components/Cards/Basic'
import ModalConfirm from '../../../../components/ModalConfirm';
import Progressbar from '../../../../components/Progressbar';
import { collapseSidebar, setMenuActive } from '../../../../redux/actions';
import { deleteClassroom, getClassroomAll, setClassroomDelete, postImportCsv, setPostImportCsv } from '../../../../redux/actions/sia-master/classroom';
import { AuthWrapper } from '../../../AuthWrapper';
import { Button } from 'react-bootstrap';
import SiaHeader from '../../../../components/SiaHeader';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Link } from "react-router-dom";
import Searchbar from '../../../../components/Searchbar';
import { notify } from '../../../../constants/utility';

const ClassroomMaster = (props) => {
    const { name, text, path, title, parent } = props.data
    const [modalShowConfirm, setModalShowConfirm] = useState(false)
    const [modalOption, setModalOption] = useState({})

    const dispatch = useDispatch()
    const { loading, classrooms, classroomDelete, importCsv } = useSelector((state) => state.classroom);

    const [search, setSearch] = useState("")
    const [filterred, setFilterred] = useState({})
    const [sort, setSort] = useState(null)
    const [page, setPage] = useState(1)
    const [sizePerPage, setSizePerPage] = useState(10)
    const [totalSize, setTotalSize] = useState(0)
    const [data, setData] = useState([])

    useEffect(() => {
        dispatch(setMenuActive({ menuActive: 'sia', subMenuActive: name }))
        dispatch(collapseSidebar())
    }, [])

    useEffect(() => {
        if (classrooms) {
            setData(classrooms.result?.rows)
            setTotalSize(classrooms.result?.total_count)
        }
    }, [classrooms])

    useEffect(() => {
        const params = {
            search: search,
            page_size: sizePerPage,
            page: page,
            filter: {},
            sort: sort,
        }

        if (filterred) {
            for (var x in filterred) {
                params.filter[x] = filterred[x]
            }
        }

        dispatch(getClassroomAll(params))
    }, [search, sort, page, sizePerPage, classroomDelete, importCsv])

    useEffect(() => {
        if (classroomDelete) {
            notify(classroomDelete.message, classroomDelete.success ? 'success' : 'error')

            setModalShowConfirm(false)
        }
        return () => {
            dispatch(setClassroomDelete(null))
        }
    }, [classroomDelete])

    useEffect(() => {
        if (importCsv) {
            notify(importCsv.message, importCsv.success ? 'success' : 'error')

            if (importCsv.result?.fail) {
                const link = document.createElement('a');
                link.href = importCsv.result?.fail;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        return () => {
            dispatch(setPostImportCsv(null))
        }
    }, [importCsv])

    const onDelete = (data) => {

        setModalShowConfirm(true)
        setModalOption({
            title: 'Konfirmasi hapus',
            content: (
                <div>
                    <p>Anda yakin akan menghapus <strong>{data?.name}</strong> ?</p>

                    <hr mb={2} mt={2} />
                    <Button className='float-left' size='sm' variant={`outline-secondary`} onClick={() => setModalShowConfirm(false)}>Batal</Button>
                    <Button className='float-right' variant={`danger`} size='sm'
                        onClick={() => dispatch(deleteClassroom(data.id))}>Hapus</Button>
                </div>
            ),
            color: 'danger',
            size: 'md',
            data: data,
        })

    }

    const columns = [
        {
            dataField: '',
            text: 'No',
            headerStyle: (colum, colIndex) => {
                return { width: '80px' };
            },
            headerClasses: (column, colIndex) => {
                return 'py-2 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            },

            formatter: (a, b, c) => {
                return ((page - 1) * sizePerPage) + c + 1
            }

        },
        {
            dataField: 'code',
            text: 'Kelas',
            sort: true,

            headerClasses: (column, colIndex) => {
                return 'py-2 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            },

        },
        {
            dataField: 'room',
            text: 'Ruang',
            sort: true,

            headerClasses: (column, colIndex) => {
                return 'py-2 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            },

        },
        {
            dataField: 'name',
            text: 'Nama',
            sort: true,
            headerClasses: (column, colIndex) => {
                return 'py-2 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            },

        },

        {
            dataField: 'id',
            text: '',
            headerStyle: (colum, colIndex) => {
                return { width: '100px' };
            },
            headerClasses: (column, colIndex) => {
                return 'py-2 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            },
            formatter: (val, row) => {
                return (
                    <>
                        <Link to={`/sia/master/classroom/edit/${val}`} className="btn btn-warning btn-sm m-1"><i className="fas fa-edit"></i></Link>
                        <span onClick={() => onDelete(row)} className="btn btn-danger btn-sm m-1"><i className="fas fa-trash"></i></span>
                    </>
                )
            }
        },
    ];

    const onSearchEnter = (data) => {
        setPage(1)
        setSearch(data)
    }

    const handleTableChange = (type, { sortField, sortOrder, data, page, sizePerPage }) => {
        if (type === 'sort') {
            setSort({
                orderby: sortField,
                order: sortOrder
            })
        }

        if (type === 'pagination') {
            setPage(page)
            setSizePerPage(sizePerPage)
        }
    }

    const importCsvInput = useRef();

    const clickImportCsv = () => {
        importCsvInput.current.click();
    }

    const onImportCsvChange = (e) => {
        // console.log(e.target.files[0].name)
        if (e.target.files.length > 0) {
            let formData = new FormData();
            formData.append('file', e.target.files[0])
            dispatch(postImportCsv(formData));
            importCsvInput.current.value = ""
        }

    }

    return (
        <div>
            <Progressbar loading={loading} />
            <SiaHeader />
            <div className="mt-2"></div>
            <CardBasic title={title} nopadding>
                <div className="d-flex p-3">
                    <Link to="new" className="mr-2"><span className="btn btn-primary btn-md">Tambah</span></Link>
                    <span className="btn btn-info btn-md mr-4" style={{ height: "39px" }} onClick={() => clickImportCsv()}>Import Csv</span>
                    <input hidden ref={importCsvInput} type="file" accept=".csv" onChange={(e) => onImportCsvChange(e)} />
                    <div className="flex-grow-1">
                        <Searchbar
                            placeholder="Pencarian"
                            onEnter={(data) => onSearchEnter(data)} />
                    </div>
                </div>
                <RemotePagination
                    data={data}
                    page={page}
                    sizePerPage={sizePerPage}
                    totalSize={totalSize}
                    columns={columns}
                    onTableChange={handleTableChange}
                />

            </CardBasic>
            {/* <ClassroomForm
                show={modalShow}
                data={modalData}
                onHide={() => onHide()}
            /> */}
            <ModalConfirm
                show={modalShowConfirm}
                modalOption={modalOption}
                onHide={() => setModalShowConfirm(false)}
            />
        </div >
    )
}

const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize, columns, onRowClick }) => {
    const indication = () => {
        return 'No data'
    }

    const defaultSorted = [{
        dataField: 'created_at',
        order: 'desc'
    }];
    return (
        <div className="table-fixied custom-scrollbar table-responsive my-table">
            <BootstrapTable
                remote
                className="bt-table"
                bootstrap4
                hover
                bordered
                striped
                condensed
                noDataIndication={indication}
                bordered={false}
                keyField="id"
                defaultSorted={defaultSorted}
                data={data}
                columns={columns}
                pagination={paginationFactory({ page, sizePerPage, totalSize })}
                onTableChange={onTableChange}
            />
        </div>
    )
}

export default AuthWrapper(ClassroomMaster)
