export const CLICK_OPEN_MENU = "CLICK_OPEN_MENU";

// Auth
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const AUTH_USER_DATA = "AUTH_USER_DATA";


// post
export const UPLOAD_IMAGE = "UPLOAD_IMAGE"
export const ON_LOADING = "ON_LOADING"
export const POST_CREATE = "POST_CREATE"
export const POST_UPDATE = "POST_UPDATE"
export const POST_DELETE = "POST_DELETE"
export const GET_POST = "GET_POST"
export const GET_POSTS = "GET_POSTS"

//slides
export const SLIDE_CREATE = "SLIDE_CREATE"
export const SLIDE_UPDATE = "SLIDE_UPDATE"
export const SLIDE_DELETE = "SLIDE_DELETE"
export const GET_SLIDE = "GET_SLIDE"
export const GET_SLIDES = "GET_SLIDES"
export const SLIDE_ORDERING = "SLIDE_ORDERING"

export const GALLERY_CREATE = "GALLERY_CREATE"
export const GALLERY_UPDATE = "GALLERY_UPDATE"
export const GALLERY_DELETE = "GALLERY_DELETE"
export const GALLERY = "GALLERY"
export const GALLERIES = "GALLERIES"

export const ALBUM_CREATE = "ALBUM_CREATE"
export const ALBUM_UPDATE = "ALBUM_UPDATE"
export const ALBUM_DELETE = "ALBUM_DELETE"
export const ALBUM = "ALBUM"
export const ALBUMS = "ALBUMS"

export const GENERAL = "GENERAL"
export const GENERAL_UPDATE = "GENERAL_UPDATE"

export const CONTENT_OPTS = "CONTENT_OPTS"
export const MENU = "MENU"
export const MENUS = "MENUS"
export const MENU_UPDATE = "MENU_UPDATE"
export const MENU_CREATE = "MENU_CREATE"
export const MENU_DELETE = "MENU_DELETE"
export const STRUCTURE_MENUS = "STRUCTURE_MENUS"
export const STRUCTURE_MENU_UPDATE = "STRUCTURE_MENU_UPDATE"

// SIA
//sia-setting
export const GET_SETTINGS = "GET_SETTINGS"
export const SET_SETTINGS = "SET_SETTINGS"

//lesson
export const LESSON_CREATE = "LESSON_CREATE"
export const LESSON_UPDATE = "LESSON_UPDATE"
export const LESSON_DELETE = "LESSON_DELETE"
export const GET_LESSON = "GET_LESSON"
export const GET_LESSONS = "GET_LESSONS"

//classroom
export const CLASSROOM_CREATE = "CLASSROOM_CREATE"
export const CLASSROOM_UPDATE = "CLASSROOM_UPDATE"
export const CLASSROOM_DELETE = "CLASSROOM_DELETE"
export const GET_CLASSROOM = "GET_CLASSROOM"
export const GET_CLASSROOMS = "GET_CLASSROOMS"

//eduyear
export const EDUYEAR_CREATE = "EDUYEAR_CREATE"
export const EDUYEAR_UPDATE = "EDUYEAR_UPDATE"
export const EDUYEAR_DELETE = "EDUYEAR_DELETE"
export const GET_EDUYEAR = "GET_EDUYEAR"
export const GET_EDUYEARS = "GET_EDUYEARS"

//student
export const STUDENT_CREATE = "STUDENT_CREATE"
export const STUDENT_UPDATE = "STUDENT_UPDATE"
export const STUDENT_DELETE = "STUDENT_DELETE"
export const GET_STUDENT = "GET_STUDENT"
export const GET_STUDENTS = "GET_STUDENTS"
export const POST_IMPORT_CSV = "POST_IMPORT_CSV"

//teacher
export const TEACHER_CREATE = "TEACHER_CREATE"
export const TEACHER_UPDATE = "TEACHER_UPDATE"
export const TEACHER_DELETE = "TEACHER_DELETE"
export const GET_TEACHER = "GET_TEACHER"
export const GET_TEACHERS = "GET_TEACHERS"

// sia activity
// lesson-class
export const LESSON_CLASS_CREATE = "LESSON_CLASS_CREATE"
export const LESSON_CLASS_UPDATE = "LESSON_CLASS_UPDATE"
export const LESSON_CLASS_DELETE = "LESSON_CLASS_DELETE"
export const GET_LESSON_CLASS = "GET_LESSON_CLASS"
export const GET_LESSON_CLASSES = "GET_LESSON_CLASSES"
export const GET_LESSON_CLASS_OPTFORM = "GET_LESSON_CLASS_OPTFORM"
export const GET_LESSON_CLASS_BY_CLASS_YEAR = "GET_LESSON_CLASS_BY_CLASS_YEAR"
export const POST_LESSON_CLASS_BY_CLASS_YEAR = "POST_LESSON_CLASS_BY_CLASS_YEAR"

// lesson-class-student
// lesson-class
export const LESSON_CLASS_STUDENT_CREATE = "LESSON_CLASS_STUDENT_CREATE"
export const LESSON_CLASS_STUDENT_UPDATE = "LESSON_CLASS_STUDENT_UPDATE"
export const LESSON_CLASS_STUDENT_DELETE = "LESSON_CLASS_STUDENT_DELETE"
export const GET_LESSON_CLASS_STUDENT = "GET_LESSON_CLASS_STUDENT"
export const GET_LESSON_CLASS_STUDENTES = "GET_LESSON_CLASS_STUDENTES"
export const GET_LESSON_CLASS_STUDENT_OPTFORM = "GET_LESSON_CLASS_STUDENT_OPTFORM"
export const GET_LESSON_CLASS_STUDENT_BY_CLASS_YEAR = "GET_LESSON_CLASS_STUDENT_BY_CLASS_YEAR"
export const POST_LESSON_CLASS_STUDENT_BY_CLASS_YEAR = "POST_LESSON_CLASS_STUDENT_BY_CLASS_YEAR"

// student-class
export const STUDENT_CLASS_CREATE = "STUDENT_CLASS_CREATE"
export const STUDENT_CLASS_UPDATE = "STUDENT_CLASS_UPDATE"
export const STUDENT_CLASS_DELETE = "STUDENT_CLASS_DELETE"
export const GET_STUDENT_CLASS = "GET_STUDENT_CLASS"
export const GET_STUDENT_CLASSES = "GET_STUDENT_CLASSES"
export const GET_STUDENT_CLASS_OPTFORM = "GET_STUDENT_CLASS_OPTFORM"
export const GET_STUDENT_CLASS_BY_CLASS_YEAR = "GET_STUDENT_CLASS_BY_CLASS_YEAR"
export const POST_STUDENT_CLASS_BY_CLASS_YEAR = "POST_STUDENT_CLASS_BY_CLASS_YEAR"

// teacher-class-lesson
export const TEACHER_CLASS_LESSON = "TEACHER_CLASS_LESSON"
export const TEACHER_CLASS_LESSONS = "TEACHER_CLASS_LESSONS"
export const TEACHER_CLASS_LESSON_STUDENTS = "TEACHER_CLASS_LESSON_STUDENTS"

export const TEACHER_CLASS_LESSON_TASK_STUDENTS = "TEACHER_CLASS_LESSON_TASK_STUDENTS"

export const TEACHER_CLASS_LESSON_ATTACHMENT_UPLOAD = "TEACHER_CLASS_LESSON_ATTACHMENT_UPLOAD"
export const TEACHER_CLASS_LESSON_MATERIALS = "TEACHER_CLASS_LESSON_MATERIALS"
export const TEACHER_CLASS_LESSON_MATERIAL_CREATE = "TEACHER_CLASS_LESSON_MATERIAL_CREATE"
export const TEACHER_CLASS_LESSON_MATERIAL_UPDATE = "TEACHER_CLASS_LESSON_MATERIAL_UPDATE"
export const TEACHER_CLASS_LESSON_MATERIAL_DELETE = "TEACHER_CLASS_LESSON_MATERIAL_DELETE"

export const TEACHER_CLASS_LESSON_TASKS = "TEACHER_CLASS_LESSON_TASKS"
export const TEACHER_CLASS_LESSON_TASK = "TEACHER_CLASS_LESSON_TASK"
export const TEACHER_CLASS_LESSON_TASK_CREATE = "TEACHER_CLASS_LESSON_TASK_CREATE"
export const TEACHER_CLASS_LESSON_TASK_UPDATE = "TEACHER_CLASS_LESSON_TASK_UPDATE"
export const TEACHER_CLASS_LESSON_TASK_DELETE = "TEACHER_CLASS_LESSON_TASK_DELETE"
export const TEACHER_CLASS_LESSON_TASK_SUBMITS = "TEACHER_CLASS_LESSON_TASK_SUBMITS"

// students-class-lesson
export const STUDENT_CLASS_LESSON = "STUDENT_CLASS_LESSON"
export const STUDENT_CLASS_LESSONS = "STUDENT_CLASS_LESSONS"
export const ONE_STUDENT_CLASS_LESSONS = "ONE_STUDENT_CLASS_LESSONS"
export const STUDENT_CLASS_LESSON_STUDENTS = "STUDENT_CLASS_LESSON_STUDENTS"

export const STUDENT_CLASS_LESSON_ATTACHMENT_UPLOAD = "STUDENT_CLASS_LESSON_ATTACHMENT_UPLOAD"
export const STUDENT_CLASS_LESSON_MATERIALS = "STUDENT_CLASS_LESSON_MATERIALS"

export const STUDENT_CLASS_LESSON_TASKS = "STUDENT_CLASS_LESSON_TASKS"
export const STUDENT_CLASS_LESSON_TASK_SUBMIT_CREATE = "STUDENT_CLASS_LESSON_TASK_SUBMIT_CREATE"
export const STUDENT_CLASS_LESSON_TASK_SUBMIT_UPDATE = "STUDENT_CLASS_LESSON_TASK_SUBMIT_UPDATE"
export const STUDENT_CLASS_LESSON_TASK_SUBMIT_DELETE = "STUDENT_CLASS_LESSON_TASK_SUBMIT_DELETE"
export const STUDENT_CLASS_LESSON_TASK_SUBMIT = "STUDENT_CLASS_LESSON_TASK_SUBMIT"


//user
export const USER_CREATE = "USER_CREATE"
export const USER_UPDATE = "USER_UPDATE"
export const USER_DELETE = "USER_DELETE"
export const GET_USER = "GET_USER"
export const GET_USERS = "GET_USERS"

//role
export const ROLE_CREATE = "ROLE_CREATE"
export const ROLE_UPDATE = "ROLE_UPDATE"
export const ROLE_DELETE = "ROLE_DELETE"
export const GET_ROLE = "GET_ROLE"
export const GET_ROLES = "GET_ROLES"

export const GET_ACCESSES = "GET_ACCESSES"




// error modal
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

// menu
export const SET_EXPAND_SIDEBAR = "SET_EXPAND_SIDEBAR";
export const SET_MENU_ACTIVE = "SET_MENU_ACTIVE";
export const COLLAPSE_SIDEBAR = "COLLAPSE_SIDEBAR";

// ------------- Action Report ------------ //

export const GET_REPORT_PERKEMBANGAN_JARINGAN =
  "GET_REPORT_PERKEMBANGAN_JARINGAN";
export const GET_REPORT_BONUS = "GET_REPORT_BONUS";
export const GET_REPORT_DASHBOARD = "GET_REPORT_DASHBOARD";
export const REPORT_FORM_RESULT = "REPORT_FORM_RESULT";

// ------------------------------------------------- //

// ------------- Action Profile ------------ //

export const GET_ACCOUNT_PROFILE = "GET_ACCOUNT_PROFILE";
export const GET_ACCOUNT_ADDRESS = "GET_ACCOUNT_ADDRESS";
export const ACCOUNT_FORM_RESULT = "ACCOUNT_FORM_RESULT";

// ------------------------------------------------- //

// ------------- Action Transaction ------------ //

export const GET_TRANSACTION_ORDER = "GET_TRANSACTION_ORDER";
export const GET_TRANSACTION_HISTORY = "GET_TRANSACTION_HISTORY";
export const GET_TRANSACTION_KODE = "GET_TRANSACTION_KODE";
export const GET_TRANSACTION_INVOICE = "GET_TRANSACTION_INVOICE";
export const TRANSACTION_FORM_RESULT = "TRANSACTION_FORM_RESULT";

// ------------------------------------------------- //

// ------------- Action Infomasi ------------ //

export const GET_INFORMASI_LIST = "GET_INFORMASI_LIST";
export const INFORMASI_FORM_RESULT = "INFORMASI_FORM_RESULT";

// ------------------------------------------------- //

export const GET_SIA_DASHBOARD = "GET_SIA_DASHBOARD";

export const GET_PROFILE = "GET_PROFILE"
export const PROFILE_UPDATE = "PROFILE_UPDATE"
export const CHANGE_PASSWORD_UPDATE = "CHANGE_PASSWORD_UPDATE"


// sia rapor
export const GET_RAPOR = "GET_RAPOR"
export const GET_RAPORS = "GET_RAPORS"
export const BULK_RAPOR_UPLOAD = "BULK_RAPOR_UPLOAD"
export const RAPOR_DELETE = "RAPOR_DELETE"
export const RAPOR_UPDATE = "RAPOR_UPDATE"
export const FORM_OPT = "FORM_OPT"