import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//Navigation
import * as actions from '../../redux/actions';
import { AuthWrapper } from '../AuthWrapper';


import CardBasic from '../../components/Cards/Basic';
import SiaHeader from '../../components/SiaHeader';
import { getSiaDashboard } from '../../redux/actions/sia-dashboard';
import { formatDate } from '../../constants/utility';

const Sia = (props) => {
    const { name, label, path, title, parent } = props.data
    const dispatch = useDispatch();
    const { data: userData } = useSelector(state => state.auth)

    useEffect(() => {
        dispatch(actions.setMenuActive({ menuActive: 'sia', subMenuActive: name }))
        dispatch(actions.collapseSidebar())
        dispatch(getSiaDashboard())
    }, [])

    const { siaDashboard } = useSelector(state => state.siaDashboard)

    useEffect(() => {
        console.log("siaDashboard", siaDashboard)
    }, [siaDashboard])

    return (
        <div>
            <CardBasic nopadding>
                <SiaHeader />
                <div className="container" style={{ minHeight: '300px' }}>

                    <div className="row mt-4">
                        <div className="col-sm-6 col-md-8">
                            <p className="mt-4 mb-4 text-center">Selamat datang di Sistem Informasi Akademik (SIA) <strong>SMP Negeri 33 Surabaya</strong></p>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            {userData?.user?.user_type === 'student' ?
                                <>
                                    <div className="card mb-3">
                                        <div className="card-header text-white" style={{ backgroundColor: "rgb(0, 166, 224)" }}>
                                            ROLE SISWA
                                        </div>
                                        <div className="card-body">
                                            <h6 className="text-center border-bottom">{siaDashboard?.result?.nisn} <br /> {siaDashboard?.result?.name}</h6>
                                            <div className="sia-dashboard-info">
                                                <div className="mb-2"><i class="fas fa-history"></i>&nbsp; <span className="text-warning">Login terakhir: </span><span>{formatDate(siaDashboard?.result?.last_login)}</span></div>
                                                <div className="mb-2"><i class="fas fa-user-graduate"></i>&nbsp;
                                                    <div className="d-inline-flex mr-2">
                                                        <span className="text-warning">Status:&nbsp;</span><span>Aktif</span>
                                                    </div>
                                                    <div className="d-inline-flex mr-2">
                                                        <span className="text-warning">Kelas/Semester:&nbsp;</span><span>{siaDashboard?.result?.class_active?.classroom?.name}</span>
                                                    </div>
                                                    <div className="d-inline-flex mr-2">
                                                        <span className="text-warning">Tahun Masuk:&nbsp;</span><span>{siaDashboard?.result?.entry_year?.split("-")[0]}</span>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="mb-2"><i class="fas fa-tasks"></i>&nbsp;
                                                    <div className="d-inline-flex mr-2">
                                                        <span className="text-warning">15 Mapel:&nbsp;</span><span>sedang diikuti</span>
                                                    </div>
                                                    <div className="d-inline-flex mr-2">
                                                        <span className="text-warning">3 Materi:&nbsp;</span><span>belum</span>
                                                    </div>
                                                    <div className="d-inline-flex mr-2">
                                                        <span className="text-warning">2 Tugas:&nbsp;</span><span>belum dikumpulkan</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-header text-white" style={{ backgroundColor: "rgb(0, 166, 224)" }}>
                                            PERKEMBANGAN NILAI
                                        </div>
                                        <div className="card-body">
                                            <div className="sia-dashboard-info">
                                            </div>
                                        </div>
                                    </div>
                                </> : ""}
                            {userData?.user?.user_type === 'teacher' ?
                                <>
                                    <div className="card mb-3">
                                        <div className="card-header text-white" style={{ backgroundColor: "rgb(0, 166, 224)" }}>
                                            ROLE GURU
                                        </div>
                                        <div className="card-body">
                                            <h6 className="text-center border-bottom">{siaDashboard?.result?.nip} <br /> {siaDashboard?.result?.name}</h6>
                                            <div className="sia-dashboard-info">
                                                <div className="mb-2"><i class="fas fa-history"></i>&nbsp; <span className="text-warning">Login terakhir: </span><span>{formatDate(siaDashboard?.result?.last_login)}</span></div>
                                                <div className="mb-2"><i class="fas fa-user-graduate"></i>&nbsp;
                                                    <div className="d-inline-flex mr-2">
                                                        <span className="text-warning">Status:&nbsp;</span><span>Aktif</span>
                                                    </div>
                                                    {/* <div className="mr-2">
                                                        <span className="text-warning">Mengampu Mapel:&nbsp;</span><span>Matematika VII-B</span><span> Matematika VII-C</span><span>Matematika VII-D</span>
                                                    </div>
                                                    <div className="d-inline-flex mr-2">
                                                        <span className="text-warning">Tahun Masuk:&nbsp;</span><span>2021</span>
                                                    </div> */}
                                                </div>
                                                <hr />
                                                <div className="mb-2"><i class="fas fa-tasks"></i>&nbsp;
                                                    {/* <div className="d-inline-flex mr-2">
                                                        <span className="text-warning">15 Mapel:&nbsp;</span><span>sedang diikuti</span>
                                                    </div>
                                                    <div className="d-inline-flex mr-2">
                                                        <span className="text-warning">3 Materi:&nbsp;</span><span>belum</span>
                                                    </div>
                                                    <div className="d-inline-flex mr-2">
                                                        <span className="text-warning">2 Tugas:&nbsp;</span><span>belum dikumpulkan</span>
                                                    </div> */}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </> : ""}
                            {userData?.user?.user_type === 'administrator' ?
                                <>
                                    <div className="card mb-3">
                                        <div className="card-header text-white" style={{ backgroundColor: "rgb(0, 166, 224)" }}>
                                            ROLE ADMIN/OPERATOR
                                        </div>
                                        <div className="card-body">
                                            <h6 className="text-center border-bottom">{siaDashboard?.result?.name}</h6>
                                            <div className="sia-dashboard-info">
                                                <div className="mb-2"><i class="fas fa-history"></i>&nbsp; <span className="text-warning">Login terakhir: </span><span>{formatDate(siaDashboard?.result?.last_login)}</span></div>
                                                <div className="mb-2"><i class="fas fa-user-graduate"></i>&nbsp;
                                                    <div className="d-inline-flex mr-2">
                                                        <span className="text-warning">Status:&nbsp;</span><span>Aktif</span>
                                                    </div>

                                                </div>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                </> : ""}
                        </div>
                    </div>
                </div>
            </CardBasic >
        </div >
    )
}

export default AuthWrapper(Sia, true);