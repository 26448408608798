import * as actionTypes from "../../../../constants/actionTypes";
import { updateObject } from "../../../../constants/utility";

const initialState = {
    loading: null,
    lessonClassStudentCreate: null,
    lessonClassStudentUpdate: null,
    lessonClassStudentDelete: null,
    lessonClassStudent: null,
    lessonClassStudents: null,
    lessonClassStudentOptForm: null,
    lessonClassStudentByClassYear: null,
    lessonClassStudentByClassYearCreate: null
};

const loading = (state, data) => {
    return updateObject(state, {
        loading: data
    })
}


const lessonClassStudentCreate = (state, data) => {
    return updateObject(state, {
        lessonClassStudentCreate: data,
    });
}

const getLessonClassStudent = (state, data) => {
    return updateObject(state, {
        lessonClassStudent: data,
    });
}

const getlessonClassStudents = (state, data) => {
    return updateObject(state, {
        lessonClassStudents: data,
    });
}

const lessonClassStudentUpdate = (state, data) => {
    return updateObject(state, {
        lessonClassStudentUpdate: data,
    });
}

const lessonClassStudentDelete = (state, data) => {
    return updateObject(state, {
        lessonClassStudentDelete: data,
    });
}

const setGetLessonClassStudentOptForm = (state, data) => {
    return updateObject(state, {
        lessonClassStudentOptForm: data,
    });
}

const setLessonClassStudentByClassYear = (state, data) => {
    return updateObject(state, {
        lessonClassStudentByClassYear: data,
    });
}

const setLessonClassStudentByClassYearCreate = (state, data) => {
    return updateObject(state, {
        lessonClassStudentByClassYearCreate: data,
    });
}



const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
        case actionTypes.POST_LESSON_CLASS_STUDENT_BY_CLASS_YEAR:
            return setLessonClassStudentByClassYearCreate(state, data);
        case actionTypes.GET_LESSON_CLASS_STUDENT_BY_CLASS_YEAR:
            return setLessonClassStudentByClassYear(state, data);
        case actionTypes.GET_LESSON_CLASS_STUDENT_OPTFORM:
            return setGetLessonClassStudentOptForm(state, data);
        case actionTypes.LESSON_CLASS_STUDENT_DELETE:
            return lessonClassStudentDelete(state, data);
        case actionTypes.LESSON_CLASS_STUDENT_UPDATE:
            return lessonClassStudentUpdate(state, data);
        case actionTypes.GET_LESSON_CLASS_STUDENTES:
            return getlessonClassStudents(state, data);
        case actionTypes.GET_LESSON_CLASS_STUDENT:
            return getLessonClassStudent(state, data);
        case actionTypes.LESSON_CLASS_STUDENT_CREATE:
            return lessonClassStudentCreate(state, data);
        case actionTypes.ON_LOADING:
            return loading(state, data);
        default:
            return state;
    }
};

export default reducer;
