import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import TextInput from '../../../../../../../components/TextInput'
import { postTeacherClassLessonTask, postUploadAttachment, putTeacherClassLessonTask } from '../../../../../../../redux/actions/sia-activity/teacher-class-lesson'

const Form = (props) => {
    const { lesson_class_id, form_data, close } = props
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()
    const { teacherClassLessonAttachmentUpload } = useSelector((state) => state.teacherClassLesson);
    const dispatch = useDispatch()

    const statusOptions = [
        { value: 'draft', label: 'Draft' },
        { value: 'open', label: 'Buka' },
        { value: 'close', label: 'Tutup' },
    ]

    const submitTask = (data) => {
        data.attachment = teacherClassLessonAttachmentUpload?.result?.filename || form_data?.attachment || null
        data.status = data.status.value
        // console.log(data, teacherClassLessonAttachmentUpload?.result?.filename)
        if (form_data.id === 0) {
            dispatch(postTeacherClassLessonTask(lesson_class_id, data))
        } else {
            dispatch(putTeacherClassLessonTask(lesson_class_id, form_data.id, data))
        }
    }

    useEffect(() => {
        if (watch('attachment')?.length > 0) {
            console.log(watch('attachment'))
            let fromData = new FormData();
            fromData.append('attachment', watch('attachment')[0])
            // console.log('acc', uploadImage)
            if (teacherClassLessonAttachmentUpload) fromData.append('old_attachment', teacherClassLessonAttachmentUpload.result.filename)
            dispatch(postUploadAttachment(fromData));
        }
    }, [watch('attachment')])

    useEffect(() => {
        console.log(form_data)
        setValue('name', form_data?.name || '')
        setValue('description', form_data?.description || '')
        setValue('deadline_at', form_data?.deadline_at || '')
        if (form_data?.status)
            setValue('status', statusOptions.filter(item => item.value === form_data?.status)?.[0])
        else setValue('status', null)
    }, [form_data])

    return (
        <div>
            <div className="d-flex border-bottom mb-4 mt-2 py-2 ">
                <span
                    className="btn btn-sm btn-secondary mr-2"
                    onClick={() => close()}
                    style={{ height: "30px", width: "50px" }}><i className="fas fa-arrow-left"></i></span>
                <h6 className="font-weight-bold p-1">{form_data.id === 0 ? `Tambah` : `Edit/Detail`} Tugas</h6>
            </div>
            <form onSubmit={handleSubmit(submitTask)}>
                <div className="row">
                    <div className="col-md-6">
                        <TextInput
                            layout="horizontal"
                            label="Nama Tugas"
                            type="text"
                            name="name"
                            placeholder="Nama Tugas"
                            register={register}
                            errors={formState.errors}
                            validations={{ required: true }}
                        />
                        <TextInput
                            layout="horizontal"
                            label="Keterangan"
                            type="textarea"
                            name="description"
                            placeholder="Keterangan"
                            register={register}
                            errors={formState.errors}
                        />
                        <TextInput
                            layout="horizontal"
                            label="Batas Pengumpulan"
                            type="date"
                            name="deadline_at"
                            placeholder="Batas Pengumpulan"
                            register={register}
                            errors={formState.errors}
                        />

                        <Controller
                            name="status"
                            control={control}
                            rules={{ required: 'Harus diisi' }}
                            render={({ field }) =>
                                <TextInput
                                    layout="horizontal"
                                    label="Status"
                                    type="select"
                                    placeholder="Status"
                                    register={register}
                                    field={field}
                                    errors={formState.errors}
                                    options={statusOptions}
                                />
                            }
                        />
                    </div>
                    <div className="col-md-6">
                        <TextInput
                            layout="horizontal"
                            label="Lampiran"
                            type="file"
                            name="attachment"
                            placeholder="Lampiran"
                            register={register}
                            accept=".jpg,.jpeg,.png,.doc,.txt,.csv,.xls,.xlsx,.ppt,.pptx,.zip,.rar"
                            errors={formState.errors}
                        />
                    </div>
                    <div className="col-md-12">
                        <hr />
                        <div className="d-flex justify-content-between">
                            {/* <button type="button" className="btn btn-light btn-md" onClick={() => close()}>Kembali</button> */}
                            <button type="submit" className="btn btn-primary btn-md">{form_data.id === 0 ? `Simpan` : `Update`}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Form
