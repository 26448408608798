import * as actionTypes from '../../../../constants/actionTypes';
import http from "../../../../services/NetworkService";

export const setOnloading = (payload) => {
    return {
        type: actionTypes.ON_LOADING,
        data: payload,
    };
};

export const setGetEduyear = (payload) => {
    return {
        type: actionTypes.GET_EDUYEAR,
        data: payload,
    };
};

export const getEduyearOne = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/sia/master/eduyear/` + data);
        dispatch(setGetEduyear(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null));
    }
}

export const setGetEduyears = (payload) => {
    return {
        type: actionTypes.GET_EDUYEARS,
        data: payload,
    };
};

export const getEduyearAll = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const params = {
            page: data?.page || 1,
            page_size: data?.page_size || 10,
            search: data?.search,
            orderby: data?.sort?.orderby || null,
            order: data?.sort?.order || null,
        };

        const result = await http.get(`/sia/master/eduyear`, { params });
        dispatch(setGetEduyears(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null));
    }
}

export const setEduyearCreate = (payload) => {
    return {
        type: actionTypes.EDUYEAR_CREATE,
        data: payload,
    };
}

export const postCreateEduyear = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/sia/master/eduyear`, data);
        dispatch(setOnloading(null))
        dispatch(setEduyearCreate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null));
    }
}

export const setEduyearUpdate = (payload) => {
    return {
        type: actionTypes.EDUYEAR_UPDATE,
        data: payload,
    };
}

export const putUpdateEduyear = (id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.put(`/sia/master/eduyear/${id}`, data);
        dispatch(setOnloading(null))
        dispatch(setEduyearUpdate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null));
    }
}


export const setEduyearDelete = (payload) => {
    return {
        type: actionTypes.EDUYEAR_DELETE,
        data: payload,
    };
}

export const deleteEduyear = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.delete(`/sia/master/eduyear/${id}`);
        dispatch(setOnloading(null))
        dispatch(setEduyearDelete(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null));
    }
}

export const setPostImportCsv = (payload) => {
    return {
        type: actionTypes.POST_IMPORT_CSV,
        data: payload,
    };
};


export const postImportCsv = (data) => async (dispatch) => {
    try {

        dispatch(setOnloading(true))
        const result = await http.post(`/sia/master/eduyear-import`, data);
        console.log("aaww", result)
        dispatch(setPostImportCsv(result));
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}
