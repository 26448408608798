import http from "../../services/NetworkService";

export const authLogin = (data) => {
  // response berhasil
  // return {
  //   result: {
  //     success: true,
  //     message: "Login success",
  //     access_token: "dwedhwjwjdwljdww2-e22j2k2-2dmkwdwkd2=dwndnwd2kdn2kd2ke2mk",
  //     user: {
  //       username: "alsocodes",
  //       email: "alsocodes@gmail.com",
  //       userid: "ALSOD123",
  //     },
  //   },
  // };
  /*
  // response error
  // return {
  //   result : {
  //     success : false,
  //     message : 'Login gagal. Username atau password salah',
  //   }
  // }
  */
  return http.post("/login", { ...data, from: "WEB" });
};

// ------------ API REPORT --------//

export const getPerkembanganJaringan = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
  };
  return http.get("/report-perkembangan-jaringan", { params });
};

export const getPendapatan = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
  };
  return http.get("/report-pendapatan", { params });
};

export const getBonus = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
  };
  return http.get("/report-bonus", { params });
};

export const getDashboard = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
  };
  return http.get("/dashboard", { params });
};

//--------------------------------//

// ------------ API Account --------//

export const getAccountProfile = (params) => {
  return http.get(`/account/${params}`);
};

export const getAccountAddress = () => {
  return http.get(`/shipping-address`);
};

export const putAccountProfile = (id, data) => {
  return http.put(`/account/${id}`, data);
};

export const putAccountPassword = (data) => {
  return http.put(`/account/change-password/`, data);
};

export const postAccountAddress = (data) => {
  return http.post(`/shipping-address`, data);
};

export const putAccountRekening = (id, data) => {
  return http.put(`/account/rekening/${id}`, data);
};

//--------------------------------//

// ------------ API Setting --------//

export const getSettingUserGroup = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
  };
  return http.get("/setting-usergroup", { params });
};

export const getSettingUserAccess = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
  };
  return http.get("/setting-useraccess", { params });
};

export const putSettingUserAccess = (id, data) => {
  return http.put(`/setting-useraccess/${id}`, data);
};

export const putSettingUserGroup = (id, data) => {
  return http.put(`/setting-usergroup/${id}`, data);
};

export const postSettingUserAccess = (data) => {
  return http.post(`/setting-useraccess`, data);
};

export const postSettingUserGroup = (data) => {
  return http.post(`/setting-usergroup`, data);
};

//--------------------------------//

// ------------ API Informasi --------//

export const getInformasiList = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
  };
  return http.get("/informasi", { params });
};

export const putInformasi = (id, data) => {
  return http.put(`/informasi/${id}`, data);
};

export const postInformasi = (data) => {
  return http.post(`/informasi`, data);
};

//--------------------------------//

// ------------ API Transaction --------//

export const getTransactionOrder = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
  };
  return http.get("/transaction-order", { params });
};

export const getTransactionHistory = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
  };
  return http.get("/transaction-history", { params });
};
export const getTransactionKode = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
  };
  return http.get("/transaction-kode", { params });
};
export const getTransactionInvoice = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
  };
  return http.get("/transaction-invoice", { params });
};

export const postTransaction = (data) => {
  return http.post(`/trancastion`, data);
};

export const putTransactionKode = (id, data) => {
  return http.put(`/transaction/${id}`, data);
};

//--------------------------------//
