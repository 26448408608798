import * as actionTypes from "../../../../constants/actionTypes";
import { updateObject } from "../../../../constants/utility";

const initialState = {
    loading: null,
    settings: null,
    setSettings: null
};

const loading = (state, data) => {
    return updateObject(state, {
        loading: data
    })
}

const setGetSettings = (state, data) => {
    return updateObject(state, {
        settings: data
    })
}

const setSetSettings = (state, data) => {
    return updateObject(state, {
        setSettings: data
    })
}

const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {

        case actionTypes.GET_SETTINGS:
            return setGetSettings(state, data);
        case actionTypes.SET_SETTINGS:
            return setSetSettings(state, data);
        case actionTypes.ON_LOADING:
            return loading(state, data);
        default:
            return state;
    }
};

export default reducer;
