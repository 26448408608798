import React, { useEffect } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../../../../../../../../constants/utility';
import { download } from '../../../../../../../../redux/actions/download';

const TaskStudents = (props) => {
    const { id, tabKey } = props
    const { teacherClassLessonTaskStudents } = useSelector((state) => state.teacherClassLesson);
    const dispatch = useDispatch()

    const indication = () => {
        return 'No data'
    }

    const defaultSorted = [{
        dataField: 'created_at',
        order: 'desc'
    }];

    const columns = [
        {
            dataField: '', text: 'No',
            headerStyle: (c, i) => { return { width: '80px' } },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i, k) => { return k + 1 }

        },
        {
            dataField: 'student.name', text: 'Nama', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },

        },
        {
            dataField: 'student.lesson_class_task_submit.answer', text: 'Jawaban', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },
        {
            dataField: 'student.lesson_class_task_submit.attachment', text: 'Lampiran', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i) => {

                return c ?
                    <span>{c}&nbsp;
                        <span className="btn btn-secondary btn-sm"
                            onClick={() => dispatch(download(i.attachment))}>
                            <i className="fas fa-download"></i>
                        </span>
                    </span>
                    : ""
            }
        },
        {
            dataField: 'student.lesson_class_task_submit.submit_at', text: 'Tgl Submit', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i) => { return c ? formatDate(c) : '-' }
        },
        {
            dataField: 'student.lesson_class_task_submit.status', text: 'Status', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return c ? 'p-2 text-success' : 'p2- text-danger' },
            formatter: (c, i) => { return c ? 'SUDAH' : 'BELUM' }
        },
    ];

    return (
        <div>
            <BootstrapTable
                className="bt-table"
                bootstrap4
                hover
                bordered
                striped
                condensed
                noDataIndication={indication}
                bordered={true}
                keyField="id"
                defaultSorted={defaultSorted}
                data={teacherClassLessonTaskStudents?.result || []}
                columns={columns}
            // pagination={paginationFactory({ page, sizePerPage, totalSize })}
            // onTableChange={onTableChange}
            />
        </div>
    )
}

export default TaskStudents
