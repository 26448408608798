import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CardBasic from '../../../../components/Cards/Basic'
import Progressbar from '../../../../components/Progressbar';
import { collapseSidebar, setMenuActive } from '../../../../redux/actions';
import { getOneStudentClassLessons } from '../../../../redux/actions/sia-activity/student-class-lesson';
import { AuthWrapper } from '../../../AuthWrapper';
import SiaHeader from '../../../../components/SiaHeader';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Link } from "react-router-dom";
import Searchbar from '../../../../components/Searchbar';

const OneStudentClassLesson = (props) => {
    const { name, text, path, title, parent } = props.data

    const dispatch = useDispatch()
    const { loading, oneStudentClassLessons } = useSelector((state) => state.studentClassLesson);

    const [search, setSearch] = useState("")
    //const [filterred, setFilterred] = useState({})
    const [sort, setSort] = useState(null)
    const [page, setPage] = useState(1)
    const [sizePerPage, setSizePerPage] = useState(10)
    const [totalSize, setTotalSize] = useState(0)
    const [data, setData] = useState([])
    const [filterables, setFilterables] = useState({})


    useEffect(() => {
        dispatch(setMenuActive({ menuActive: 'sia', subMenuActive: name }))
        dispatch(collapseSidebar())
    }, [])

    useEffect(() => {
        console.log("oneStudentClassLessons", oneStudentClassLessons)
        if (oneStudentClassLessons) {
            setData(oneStudentClassLessons.result)
            setTotalSize(oneStudentClassLessons.result.length)
        }
    }, [oneStudentClassLessons])

    useEffect(() => {
        const params = {
            search: search,
            page_size: sizePerPage,
            page: page,
            filter: {},
            sort: sort,
        }

        if (filterables) {
            for (var x in filterables) {
                params.filter[x] = filterables[x]
            }
        }

        dispatch(getOneStudentClassLessons(params))

    }, [search, sort, page, sizePerPage])

    const columns = [
        {
            dataField: '', text: 'No',
            headerStyle: (c, i) => { return { width: '80px' } },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i, k) => { return ((page - 1) * sizePerPage) + k + 1 }

        },
        {
            dataField: 'student.name', text: 'Siswa', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },

        },
        {
            dataField: 'lesson_class.classroom.name', text: 'Kelas', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },
        {
            dataField: 'lesson_class.eduyear.name', text: 'Tahun', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },

        {
            dataField: 'lesson_class.semester.name', text: 'Semester', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },

        },
        {
            dataField: 'status', text: 'Status', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },
        {
            dataField: 'id', text: '',
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i) => {
                console.log('wxx', i)
                if (!i['lesson_class.classroom.id'] || !i['lesson_class.eduyear.id'] || !i['lesson_class.semester.id']) return ""
                return (
                    <Link
                        to={`/sia/activity/student-class-lesson/classroom/${i['lesson_class.classroom.id']}/eduyear/${i['lesson_class.eduyear.id']}/semester/${i['lesson_class.semester.id']}`}>
                        <span className="btn btn-info btn-sm">Detail</span>
                    </Link>
                )
            }
        },
    ];

    const onSearchEnter = (data) => {
        setPage(1)
        setSearch(data)
    }

    useEffect(() => {
        console.log('awwww', data)
    }, [data])

    const indication = () => {
        return 'No data'
    }

    const defaultSorted = [{
        dataField: 'created_at',
        order: 'desc'
    }];
    return (
        <div>
            <Progressbar loading={loading} />
            <SiaHeader />
            <div className="mt-2"></div>
            <CardBasic title={title} nopadding>
                <div className="d-flex p-3">
                    <div className="flex-grow-1">
                        <Searchbar
                            placeholder="Pencarian"
                            onEnter={(data) => onSearchEnter(data)} />
                    </div>
                </div>
                <BootstrapTable
                    className="bt-table"
                    bootstrap4
                    hover
                    bordered
                    striped
                    condensed
                    noDataIndication={indication}
                    bordered={false}
                    keyField="id"
                    defaultSorted={defaultSorted}
                    data={data}
                    columns={columns}
                // pagination={paginationFactory({ page, sizePerPage, totalSize })}
                // onTableChange={onTableChange}
                />

            </CardBasic>
        </div >
    )
}

export default AuthWrapper(OneStudentClassLesson)
