import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Forbidden extends Component {
    render() {
        return (
            <div id="content">
                <div className="container-fluid">
                    {/* <!-- 401 Error Text --> */}
                    <div className="text-center">
                        <div className="error mx-auto" data-text="401">401</div>
                        <p className="lead text-gray-800 mb-5">Tidak memiliki akses</p>
                        <p className="text-gray-500 mb-0">Anda tidak memiliki hak untuk mengakses halaman ini.</p>
                        <Link to="/">&larr; Kembali</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Forbidden