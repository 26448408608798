import React, { Component, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import logoWhite from "../../../assets/img/logo-white.png";
import * as actions from "../../../redux/actions";
import { menus } from "../../../settings";
import { getLocalStorage } from "../../../services/localServices";
import { LOCAL_USER_KEY } from "../../../constants/keys";
import { has_access } from "../../../constants/utility";


const Sidebar = () => {
    const dispatch = useDispatch();
    const { expandSidebar, menuActive, subMenuActive } = useSelector((state) => state.menu);
    const setExpandSidebar = () => {
        if (menuActive === 'sia') return;
        dispatch(actions.setExpandSidebar());
    };

    const ulSidebar = useRef()

    const { data: userData } = useSelector(state => state.auth)

    useEffect(() => {
        const collapses = ulSidebar.current.getElementsByClassName('show')
        if (expandSidebar === false) {
            for (var i = 0; i < collapses.length; i++) {
                console.log('exxx', collapses[0])
                collapses[0].classList.remove('show')
            }
        }
    }, [expandSidebar, menuActive])

    return (
        <ul ref={ulSidebar}
            className={
                `
                ${userData?.user?.user_type === 'teacher' || userData?.user?.user_type === 'student' ? '' : ``}
                navbar-nav bg-gradient-darkf sidebar sidebar-dark accordion` +
                (!expandSidebar ? ` toggled` : ``)
            }
            id="accordionSidebar"
        >
            {/* <!-- Sidebar - Brand --> */}
            <a className="sidebar-brand" href="/">
                <div className="sidebar-brand-icon mb-1">
                    {/* <img src={logoWhite} width="50" /> */}
                    {/* <i className="fas fa-user"></i> */}
                    SMPN33
                    <br /><sup>Surabaya</sup>
                </div>
                <div>
                    {/* <span className="sidebar-brand-text">alsoCMS<sup>1.0</sup></span> */}
                </div>
                {/* <div className='sidebar-brand-text mx-3'>alsoCMS<sup>1.0</sup></div> */}
                {/* <div className="sidebar-brand-text">LL</div> */}
            </a>

            {/* <!-- Divider --> */}
            <hr className="sidebar-divider my-0" />

            {menus.map((menu, key) => {
                if (menu.is_sia || !menu.access || menu.hidden) return ""
                let has_access_check = has_access(menu.access, userData?.user?.role_access)
                if (!has_access_check) return ""
                return (
                    <>
                        {menu.heading ? <div className="sidebar-heading">{menu.heading}</div> : ''}
                        <li key={key} className={`nav-item` + (menuActive === menu.name ? ` active` : ``)}>
                            <Link
                                key={menu.name}
                                className={`nav-link` + (menu.subs ? ` collapsed` : ``)}
                                href="#"
                                data-toggle={menu.subs ? `collapse` : ``}
                                data-target={menu.subs ? `#` + menu.name + `Child` : ''}
                                aria-controls="collapseTwo"
                                to={menu.path}
                            // onClick={!menu.subs ? () => menu.callback(menu.name, '') : null}
                            >
                                <i className={`fas fa-fw ` + menu.icon}></i>
                                <span>{menu.label}</span>
                            </Link>
                            {menu.subs ?
                                <div
                                    id={menu.name + `Child`}
                                    className={`collapse` + (menuActive === menu.name ? ` show` : ``)}
                                    aria-labelledby="headingTwo"
                                    data-parent="#accordionSidebar"
                                >
                                    <div className="bg-white py-2 collapse-inner rounded">
                                        {
                                            menu.subs.map((sub) => {
                                                if (sub.hidden === true || !sub.access) return "";
                                                let has_access_check = has_access(sub.access, userData?.user?.role_access)
                                                if (!has_access_check) return ""
                                                return (
                                                    <Link
                                                        key={sub.name}
                                                        className={`collapse-item` + (subMenuActive === sub.name ? ` active` : ``)}
                                                        to={sub.path}
                                                    // onClick={() =>sub.callback(menu.name, sub.name)}
                                                    >
                                                        {sub.label}
                                                    </Link>
                                                )
                                            })
                                        }
                                    </div>
                                </div> : ''
                            }
                        </li>
                        {menu.devider ? <hr className="sidebar-divider" /> : ''}
                    </>
                )
            })}

            {/* <hr className="sidebar-divider" /> */}
            <div className="text-center d-none d-md-inline">
                <button
                    onClick={() => {
                        setExpandSidebar();
                    }}
                    className="rounded-circle border-0"
                    id="sidebarToggle"
                ></button>
            </div>
        </ul>
    );
};

export default Sidebar;
