import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentClassLessonMaterials } from '../../../../../../../redux/actions/sia-activity/student-class-lesson';
import { formatDate } from '../../../../../../../constants/utility';
import Detail from './Detail';
import { download } from '../../../../../../../redux/actions/download';

const StudentClassLessonMaterials = (props) => {
    const { id, tabKey } = props
    const { studentClassLessonMaterials } = useSelector((state) => state.studentClassLesson);
    const dispatch = useDispatch()
    const [activeId, setActiveId] = useState(0)

    useEffect(() => {
        if (tabKey === 'material') dispatch(getStudentClassLessonMaterials(id))
    }, [tabKey])
    const [formData, setFormData] = useState(null);
    const [formType, setFormType] = useState(null);

    const indication = () => {
        return 'No data'
    }

    const defaultSorted = [{
        dataField: 'created_at',
        order: 'desc'
    }];

    useEffect(() => {
        if (studentClassLessonMaterials?.result) {
            setFormData(studentClassLessonMaterials.result.filter(item => item.id === activeId)[0] || null)
        }
    }, [studentClassLessonMaterials])

    const onCloseForm = () => {
        setFormData(null)
        setFormType(null)
    }

    const columns = [
        {
            dataField: '', text: 'No',
            headerStyle: (c, i) => { return { width: '80px' } },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i, k) => { return k + 1 }

        },
        {
            dataField: 'name', text: 'Nama', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },

        },
        {
            dataField: 'description', text: 'Keterangan', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i) => { return c.substring(0, 100) }
        },
        {
            dataField: 'attachment', text: 'Lampiran', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i) => {
                return c ?
                    <span>{c}&nbsp;
                        <span className="btn btn-secondary btn-sm"
                            onClick={() => dispatch(download(c))}>
                            <i className="fas fa-download"></i>
                        </span>
                    </span>
                    : ""
            }
        },
        {
            dataField: 'created_at', text: 'Dibuat', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i) => {
                return formatDate(c)
            }
        },
        {
            dataField: 'id', text: '',
            // headerStyle: (colum, colIndex) => { return { width: '120px' }; },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (val, row) => {
                return (
                    <>
                        <span onClick={() => {
                            setFormData(row)
                            setFormType('detail')
                        }} className="btn btn-info btn-sm m-1"><i className="fas fa-search"></i></span>
                    </>
                )
            }
        },
    ];

    return (
        <div>

            {formData === null ?
                <>
                    <div className="mb-2 overflow-hidden">
                        <span className="btn btn-primary btn-sm float-right" onClick={() => {
                            setFormData({ id: 0 })
                            setFormType('form')
                        }}>Tambah</span>
                    </div>
                    <BootstrapTable
                        className="bt-table"
                        bootstrap4
                        hover
                        bordered
                        striped
                        condensed
                        noDataIndication={indication}
                        bordered={true}
                        keyField="id"
                        defaultSorted={defaultSorted}
                        data={studentClassLessonMaterials?.result || []}
                        columns={columns}
                    // pagination={paginationFactory({ page, sizePerPage, totalSize })}
                    // onTableChange={onTableChange}
                    />

                </>
                : formType === 'detail' ?
                    <Detail data={formData} close={() => onCloseForm()} />
                    : ""
            }
        </div>
    )
}

export default StudentClassLessonMaterials
