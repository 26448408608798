import React, { useEffect } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentClassLessonStudents } from '../../../../../../../redux/actions/sia-activity/student-class-lesson';

const StudentClassLessonStudents = (props) => {
    const { id, tabKey } = props
    const { studentClassLessonStudents } = useSelector((state) => state.studentClassLesson);
    const dispatch = useDispatch()
    useEffect(() => {
        if (tabKey === 'student') dispatch(getStudentClassLessonStudents(id))
    }, [tabKey])

    const indication = () => {
        return 'No data'
    }

    const defaultSorted = [{
        dataField: 'created_at',
        order: 'desc'
    }];

    const columns = [
        {
            dataField: '', text: 'No',
            headerStyle: (c, i) => { return { width: '80px' } },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i, k) => { return k + 1 }

        },
        {
            dataField: 'student.name', text: 'Nama', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },

        },
        {
            dataField: 'student.nisn', text: 'NISN', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },
        {
            dataField: 'student.nis', text: 'NIS', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        }
    ];

    return (
        <div>
            <BootstrapTable
                className="bt-table"
                bootstrap4
                hover
                bordered
                striped
                condensed
                noDataIndication={indication}
                bordered={true}
                keyField="id"
                defaultSorted={defaultSorted}
                data={studentClassLessonStudents?.result || []}
                columns={columns}
            // pagination={paginationFactory({ page, sizePerPage, totalSize })}
            // onTableChange={onTableChange}
            />
        </div>
    )
}

export default StudentClassLessonStudents
