import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logoBlack from "../../assets/img/logo-black.png";
import CheckBox from "../../components/CheckBox";
import TextInput from "../../components/TextInput";
import { getFormData } from "../../constants/utility";
import * as actions from "../../redux/actions";
import { useForm } from "react-hook-form";
import { Alert } from "../../components/Alert";
import { MainWrapper } from "../MainWrapper";

const SignIn = () => {
  const { loading, error, loggedIn } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const handleSignIn = (data) => {
    dispatch(actions.login(data));
  };

  if (loggedIn) {
    // <Navigate to="/"/>
  }

  return (
    <div className="container">
      {/* <!-- Outer Row --> */}
      <div className="row justify-content-center">
        <div className="col-xl-5 col-lg-5 col-md-10">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="p-5">
                <div className="text-center">
                  <div className="top-brand">
                    <a href="/">
                      {/* <img
                        className="top-brand-img"
                        src={logoBlack}
                        width="50"
                      /> */}
                      <div className="top-brand-text"><small className="font-weight-bold">Sistem Informasi Akademik (SIA)</small><br /> SMPN33 Surabaya</div>
                    </a>
                    <small>by <a href="https://also.my.id">AlsoCMS v1.0</a></small>
                  </div>
                </div>
                <hr />
                <form onSubmit={handleSubmit(handleSignIn)} className="user">
                  <TextInput
                    type="text"
                    name="email"
                    placeholder="Email"
                    // defaultValue="alsocodes@gmail.com"
                    register={register}
                    validations={{ required: "Email harus diisi" }}
                    errors={errors}
                    className="form-control-user"
                  />
                  <TextInput
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    // defaultValue="12345678"
                    register={register}
                    validations={{ required: "Password harus diisi" }}
                    errors={errors}
                    className="form-control-user"
                  />

                  <CheckBox
                    name="remember"
                    label="Remember Me"
                    register={register}
                  />

                  <button
                    disabled={loading}
                    type="submit"
                    className="btn btn-primary-f btn-user btn-block"
                  >
                    <i
                      className={
                        `fas ` +
                        (!loading ? `fa-sign-in-alt` : `fa-spinner fa-spin`)
                      }
                    ></i>
                    &nbsp; SIGN IN
                  </button>
                  {/* fa-spinner fa-spin */}
                </form>
                <hr />
                <div className="text-center">
                  <i className="small">Jika lupa password, hubungi administrator</i>
                  {/* <Link className="small" to="/forgot-password">
                    Forgot Password?
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MainWrapper(SignIn);
