import { Button } from 'bootstrap'
import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import CardBasic from '../../../../../components/Cards/Basic'
import Progressbar from '../../../../../components/Progressbar'
import SiaHeader from '../../../../../components/SiaHeader'
import TextInput from '../../../../../components/TextInput'
import { collapseSidebar, setMenuActive } from '../../../../../redux/actions'
import { getStudentClassByClassYears, getStudentClassOne, getStudentClassOptForm, postCreateStudentClass, putUpdateStudentClass, setGetStudentClass, setGetStudentClassByClassYears, setStudentClassCreate, setStudentClassUpdate } from '../../../../../redux/actions/sia-activity/student-class'
import { AuthWrapper } from '../../../../AuthWrapper'
import { Modal } from 'react-bootstrap';
import { notify } from '../../../../../constants/utility'

const StudentClassForm = (props) => {
    const { name, text, path, title, parent } = props.data
    const { loading, studentClass,
        studentClassCreate,
        studentClassUpdate, studentClassOptForm,
        studentClassByClassYear } = useSelector((state) => state.studentClass);
    let back = path.replace(`/${path.split("/")[path.split("/").length - 1]}`, "")
    if (back.includes('/edit')) back = back.replace('/edit', '')

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()

    const [eduyearOption, setEduyearOption] = useState([])
    const [studentOption, setStudentOption] = useState([])
    const [classroomOption, setClassroomOption] = useState([])
    const [searchParams] = useSearchParams();
    const { id } = useParams()
    const mass = parseInt(searchParams.get("mass"))

    useEffect(() => {
        dispatch(setMenuActive({ menuActive: 'sia', subMenuActive: name }))
        dispatch(collapseSidebar())
        dispatch(setGetStudentClass(null))
        dispatch(getStudentClassOptForm())
        console.log('awowow', mass === 1)
        dispatch(setGetStudentClassByClassYears(null))
        setPeserta([])
    }, [])

    useEffect(() => {
        if (id) {
            dispatch(getStudentClassOne(id))
        }
    }, [id])

    useEffect(() => {
        setEduyearOption([{ value: studentClassOptForm?.result?.eduyears?.id, label: studentClassOptForm?.result?.eduyears?.name }])

        let classrooms = studentClassOptForm?.result?.classrooms.map(item => {
            return { value: item.id, label: item.name }
        })
        setClassroomOption(classrooms)

        let students = studentClassOptForm?.result?.students.map(item => {
            return { value: item.id, label: item.name }
        })
        setStudentOption(students)
        // setValue('classroom_id', classrooms?.[0])
        // setValue('eduyear_id', students?.[0])
    }, [studentClassOptForm])

    useEffect(() => {
        setEduyearOption([{ value: studentClass?.result?.eduyear?.id, label: studentClass?.result?.eduyear?.name }])
        if (studentClass) {
            setValue('eduyear_id', { value: studentClass?.result?.eduyear?.id, label: studentClass?.result?.eduyear?.name })
        }

        // let student = studentOption?.filter(item => item.value === studentClass?.result?.student?.id)
        // setValue('student_id', student?.[0] || '')

        // let teacher = teacherOption?.filter(item => item.value === studentClass?.result?.teacher?.id)
        // setValue('teacher_id', teacher?.[0] || '')

    }, [studentClass])

    useEffect(() => {
        if (classroomOption) {
            let classroom = classroomOption.filter(item => item.value === studentClass?.result?.classroom?.id)
            setValue('classroom_id', classroom?.[0] || '')
        }
    }, [classroomOption, studentClass])


    useEffect(() => {
        if (studentOption) {
            let student = studentOption.filter(item => item.value === studentClass?.result?.student?.id)
            setValue('student_id', student?.[0] || '')
            console.log('awwx', student,)
        }
    }, [studentOption, studentClass])

    useEffect(() => {
        if (studentClassCreate) {
            notify(studentClassCreate.message, studentClassCreate.success ? 'success' : 'error')

            if (studentClassCreate.result?.id) navigate("/sia/activity/student-class/edit/" + studentClassCreate.result?.id, { replace: true })
            // else 1// dispatch ulang
            if (mass === 1) {
                const params = {
                    classroom_id: watch("classroom_id").value,
                    eduyear_id: watch("eduyear_id").value,
                }
                dispatch(getStudentClassByClassYears(params))
            }
        }

        return () => {
            dispatch(setStudentClassCreate(null))
        }
    }, [studentClassCreate])

    useEffect(() => {
        if (studentClassUpdate) {
            notify(studentClassUpdate.message, studentClassUpdate.success ? 'success' : 'error')
            dispatch(getStudentClassOne(id))
            dispatch(setStudentClassUpdate(null))
        }

    }, [studentClassUpdate])

    useEffect(() => {
        if ("awowowo", watch("eduyear_id") && watch("classroom_id")) {
            const params = {
                classroom_id: watch("classroom_id").value,
                eduyear_id: watch("eduyear_id").value,
            }
            dispatch(getStudentClassByClassYears(params))
        }
    }, [watch("eduyear_id"), watch("classroom_id")])

    const submitForm = (data) => {
        let newData = {}
        newData.classroom_id = data.classroom_id.value
        newData.eduyear_id = data.eduyear_id.value
        if (data.student_id !== '') {
            if (!Array.isArray(data.student_id)) newData.students = [{ student_id: data.student_id.value, }]
            else newData.students = data.student_id.map(item => item.value)
        }

        if (mass === 1) {
            newData.students = peserta.map(item => {
                return {
                    id: item.id ? item.id : null,
                    student_id: item['student.id'],
                    is_delete: item.is_delete || null
                }
            })

        }
        // console.log(newData)
        if (loading === true) return;
        if (id) dispatch(putUpdateStudentClass(id, newData))
        else dispatch(postCreateStudentClass(newData))
    }

    const [peserta, setPeserta] = useState([])
    useEffect(() => {
        setPeserta([])
        setPeserta(studentClassByClassYear?.result?.peserta || [])
        console.log('iam fired', peserta)
    }, [studentClassByClassYear])

    const pesertaColumns = [
        {
            dataField: '', text: 'No',
            headerStyle: (c, i) => { return { width: '80px' } },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return `p-2 ${i.is_delete ? 'row-deleted' : ''}` },
            formatter: (c, i, k) => { return (k + 1) }

        },
        {
            dataField: 'student.name', text: 'Nama', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return `p-2 ${i.is_delete ? 'row-deleted' : ''}` },
        },
        {
            dataField: 'student.nis', text: 'NIS', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return `p-2 ${i.is_delete ? 'row-deleted' : ''}` },
        },
        {
            dataField: 'student.nisn', text: 'NISN', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return `p-2 ${i.is_delete ? 'row-deleted' : ''}` },
        },
        {
            dataField: 'student.id', text: '',
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return `p-2 ${i.is_delete ? 'row-deletedx' : ''}` },
            formatter: (cel, row) => {
                return <span className="btn btn-danger btn-sm" onClick={() => hapusPeserta(row['student.id'])}>{!row.is_delete ? 'Hapus' : 'Batal Hapus'}</span>
            }
        },
    ]

    const calonPesertaColumns = [
        {
            dataField: '', text: 'No',
            headerStyle: (c, i) => { return { width: '80px' } },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i, k) => { return (k + 1) }

        },
        {
            dataField: 'name', text: 'Nama', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },
        {
            dataField: 'student_classes.classroom.code', text: 'Kelas Sebelumnya', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },
    ]

    const [showModal, setShowModal] = useState(false)
    const [calonSelected, setCalonSelected] = useState([])

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) setCalonSelected(prev => [...prev, row])
        else setCalonSelected(calonSelected.filter(x => x.id !== row.id))
    }

    const handleOnSelectAll = (isSelect, rows) => {
        if (isSelect) setCalonSelected(rows)
        else setCalonSelected([])
    }

    const tambahkanPeserta = () => {
        setShowModal(false)
        let temp = peserta
        setPeserta([])
        calonSelected.map(item => {
            setPeserta(prev => [...prev, {
                'id': null,
                'student.id': item.id,
                'student.name': item.name,
                'student.nis': item.nis,
                'student.nisn': item.nisn
            }])
        })
        temp.map(item => setPeserta(prev => [...prev, item]))
    }

    const hapusPeserta = (id) => {

        const newPeserta = peserta.map(item => {
            console.log('xxx', item['student.id'], id)
            if (item['student.id'] === id) {
                item['is_delete'] = item['is_delete'] === true ? false : true
            }
            return item
        })
        setPeserta(newPeserta)
    }

    useEffect(() => {
        console.log("calon ", calonSelected)
    }, [calonSelected])

    useEffect(() => {
        console.log("peserta", peserta)
    }, [peserta])

    return (
        <div>
            <Progressbar loading={loading} />
            <SiaHeader />
            <div className="mt-2"></div>
            <CardBasic title={title} nopadding goBack={back}>
                <div className="p-4">
                    <form onSubmit={handleSubmit(submitForm)} >
                        <div className="row">
                            <div className="col-md-6">
                                <Controller
                                    name="eduyear_id"
                                    control={control}
                                    rules={{ required: 'Harus diisi' }}
                                    render={({ field }) =>
                                        <TextInput
                                            layout="horizontal"
                                            label="Tahun Ajaran"
                                            type="select"
                                            // name="code"
                                            placeholder="Tahun Ajaran"
                                            register={register}
                                            field={field}
                                            errors={formState.errors}
                                            options={eduyearOption}
                                        />
                                    }
                                />

                                <Controller
                                    name="classroom_id"
                                    control={control}
                                    rules={{ required: 'Harus diisi' }}
                                    render={({ field }) =>
                                        <TextInput
                                            layout="horizontal"
                                            label="Kelas & Rombel"
                                            type="select"
                                            name="classroom_id"
                                            placeholder="Kelas & Rombel"
                                            register={register}
                                            field={field}
                                            errors={formState.errors}
                                            options={classroomOption}
                                        />
                                    }
                                />
                                {mass !== 1 ?
                                    <Controller
                                        name="student_id"
                                        control={control}
                                        rules={{ required: 'Harus diisi' }}
                                        render={({ field }) =>
                                            <TextInput
                                                layout="horizontal"
                                                label="Siswa"
                                                type="select"
                                                // name="code"
                                                placeholder="Siswa"
                                                register={register}
                                                field={field}
                                                errors={formState.errors}
                                                options={studentOption}
                                            />
                                        }
                                    /> : ""
                                }

                            </div>
                            <div className="col-md-12">
                                {mass == 1 ?
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="d-flex justify-content-between">
                                                    <h6 className="m-0 font-weight-bold text-secondary">
                                                        Peserta Kelas
                                                    </h6>
                                                    <span onClick={() => setShowModal(true)} className="btn btn-sm btn-info">Tambah</span>
                                                </div>
                                                <hr />
                                                <BootstrapTable
                                                    className="bt-table"
                                                    bootstrap4
                                                    hover
                                                    bordered
                                                    striped
                                                    condensed
                                                    noDataIndication={() => { return ('Tidak ada peserta') }}
                                                    keyField="id"
                                                    data={peserta}
                                                    columns={pesertaColumns}
                                                />
                                            </div>

                                            <Modal
                                                {...props}
                                                show={showModal}
                                                size={'lg'}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered
                                                onHide={() => setShowModal(false)}
                                            >
                                                <Modal.Header className='text-primary'>
                                                    <Modal.Title id="contained-modal-title-vcenter">
                                                        Tambah Peserta
                                                    </Modal.Title>
                                                    <span className='btn' onClick={() => setShowModal(false)}>&times;</span>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <BootstrapTable
                                                        className="bt-table"
                                                        bootstrap4
                                                        hover
                                                        bordered
                                                        striped
                                                        condensed
                                                        noDataIndication={() => { return ('Tidak ada peserta') }}
                                                        keyField="id"
                                                        data={studentClassByClassYear?.result?.calon_peserta || []}
                                                        columns={calonPesertaColumns}
                                                        selectRow={
                                                            {
                                                                mode: 'checkbox',
                                                                clickToSelect: true,
                                                                onSelect: handleOnSelect,
                                                                onSelectAll: handleOnSelectAll
                                                            }
                                                        }
                                                    />
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <button
                                                        onClick={() => tambahkanPeserta()}
                                                        className="btn btn-primary btn-sm"
                                                        disabled={calonSelected.length === 0}>Tambahkah {calonSelected.length} Peserta</button>
                                                </Modal.Footer>
                                            </Modal>

                                        </div>
                                    </>
                                    : ""}

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <hr />
                                <button type="submit" className="btn btn-primary btn-md">
                                    Simpan
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </CardBasic>

        </div>
    )
}

export default AuthWrapper(StudentClassForm)
