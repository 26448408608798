import React from 'react'
import { Link } from 'react-router-dom'

const CardBasic = (props) => {
  const {
    title, nopadding, hidden, goBack
  } = props


  return (
    <div className="card shadow mb-4" hidden={hidden}>
      {title ?
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">
            {goBack ? <Link to={goBack}><span className="btn btn-primary btn-sm mr-3" style={{ width: "50px" }}><i className="fas fa-arrow-left"></i></span></Link> : ""}
            {title}
          </h6>
        </div>
        : ""}
      <div className={`card-body ${nopadding ? 'p-0' : ''}`}>
        {props.children}
      </div>
    </div>
  )
}

export default CardBasic



// class CardBasic extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       title: '',
//     }
//   }

//   componentDidMount() {
//     this.setState({ title: this.props.title });
//   }

//   render() {

//     return (
//       <div className="card shadow mb-4" hidden={this.props.hidden}>
//         {this.state.title ?
//           <div className="card-header py-3">
//             <h6 className="m-0 font-weight-bold text-primary">{this.state.title}</h6>
//           </div>
//           : ""}
//         <div className={`card-body ${this.state.nopadding}`}>
//           {this.props.children}
//         </div>
//       </div>
//     )
//   }
// }

// export default CardBasic;