import * as actionTypes from '../../../../constants/actionTypes';
import http from "../../../../services/NetworkService";

export const setOnloading = (payload) => {
    return {
        type: actionTypes.ON_LOADING,
        data: payload,
    };
};

export const setGetTeacher = (payload) => {
    return {
        type: actionTypes.GET_TEACHER,
        data: payload,
    };
};

export const getTeacherOne = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/sia/master/teacher/` + data);
        dispatch(setGetTeacher(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetTeachers = (payload) => {
    return {
        type: actionTypes.GET_TEACHERS,
        data: payload,
    };
};

export const getTeacherAll = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const params = {
            page: data?.page || 1,
            page_size: data?.page_size || 10,
            search: data?.search,
            orderby: data?.sort?.orderby || null,
            order: data?.sort?.order || null,
        };

        const result = await http.get(`/sia/master/teacher`, { params });
        dispatch(setGetTeachers(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setTeacherCreate = (payload) => {
    return {
        type: actionTypes.TEACHER_CREATE,
        data: payload,
    };
}

export const postCreateTeacher = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/sia/master/teacher`, data);
        dispatch(setOnloading(null))
        dispatch(setTeacherCreate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setTeacherUpdate = (payload) => {
    return {
        type: actionTypes.TEACHER_UPDATE,
        data: payload,
    };
}

export const putUpdateTeacher = (id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.put(`/sia/master/teacher/${id}`, data);
        dispatch(setOnloading(null))
        dispatch(setTeacherUpdate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}


export const setTeacherDelete = (payload) => {
    return {
        type: actionTypes.TEACHER_DELETE,
        data: payload,
    };
}

export const deleteTeacher = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.delete(`/sia/master/teacher/${id}`);
        dispatch(setOnloading(null))
        dispatch(setTeacherDelete(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPostImportCsv = (payload) => {
    return {
        type: actionTypes.POST_IMPORT_CSV,
        data: payload,
    };
};


export const postImportCsv = (data) => async (dispatch) => {
    try {

        dispatch(setOnloading(true))
        const result = await http.post(`/sia/master/teacher-import`, data);
        console.log("aaww", result)
        dispatch(setPostImportCsv(result));
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}
