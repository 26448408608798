import * as actionTypes from "../../../../constants/actionTypes";
import { updateObject } from "../../../../constants/utility";

const initialState = {
    loading: null,
    studentCreate: null,
    studentUpdate: null,
    studentDelete: null,
    student: null,
    students: null,
    importCsv: null
};

const loading = (state, data) => {
    return updateObject(state, {
        loading: data
    })
}


const studentCreate = (state, data) => {
    return updateObject(state, {
        studentCreate: data,
    });
}

const getStudent = (state, data) => {
    return updateObject(state, {
        student: data,
    });
}

const getStudents = (state, data) => {
    return updateObject(state, {
        students: data,
    });
}

const studentUpdate = (state, data) => {
    return updateObject(state, {
        studentUpdate: data,
    });
}

const studentDelete = (state, data) => {
    return updateObject(state, {
        studentDelete: data,
    });
}

const setImportCsv = (state, data) => {
    return updateObject(state, {
        importCsv: data,
    });
}

const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {

        case actionTypes.POST_IMPORT_CSV:
            return setImportCsv(state, data);
        case actionTypes.STUDENT_DELETE:
            return studentDelete(state, data);
        case actionTypes.STUDENT_UPDATE:
            return studentUpdate(state, data);
        case actionTypes.GET_STUDENTS:
            return getStudents(state, data);
        case actionTypes.GET_STUDENT:
            return getStudent(state, data);
        case actionTypes.STUDENT_CREATE:
            return studentCreate(state, data);
        case actionTypes.ON_LOADING:
            return loading(state, data);
        default:
            return state;
    }
};

export default reducer;
