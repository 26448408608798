import { Button } from 'bootstrap'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import CardBasic from '../../../../../components/Cards/Basic'
import Progressbar from '../../../../../components/Progressbar'
import SiaHeader from '../../../../../components/SiaHeader'
import TextInput from '../../../../../components/TextInput'
import { notify } from '../../../../../constants/utility'
import { collapseSidebar, setMenuActive } from '../../../../../redux/actions'
import { getStudentOne, postCreateStudent, putUpdateStudent, setGetStudent, setStudentCreate, setStudentUpdate } from '../../../../../redux/actions/sia-master/student'
import { AuthWrapper } from '../../../../AuthWrapper'

const StudentMasterForm = (props) => {
    const { name, text, path, title, parent } = props.data
    const { loading, student, studentCreate, studentUpdate } = useSelector((state) => state.student);
    let back = path.replace(`/${path.split("/")[path.split("/").length - 1]}`, "")
    if (back.includes('/edit')) back = back.replace('/edit', '')

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()

    const [religionOption, setReligionOption] = useState([
        { value: 'islam', label: 'Islam' },
        { value: 'katholik', label: 'Krinten Katholik' },
        { value: 'protestan', label: 'Kristen Protestan' },
        { value: 'hindu', label: 'Hindu' },
        { value: 'budha', label: 'Budha' },
        { value: 'konghucu', label: 'Konghucu' },
    ])

    useEffect(() => {
        dispatch(setMenuActive({ menuActive: 'sia', subMenuActive: name }))
        dispatch(collapseSidebar())
        dispatch(setGetStudent(null))

    }, [])

    const { id } = useParams()

    useEffect(() => {
        if (id) {
            dispatch(getStudentOne(id))
        }
    }, [id])

    useEffect(() => {
        setValue('name', student?.result?.name || '')
        setValue('gender', student?.result?.gender || '')
        setValue('place_birth', student?.result?.place_birth || '')
        setValue('day_birth', student?.result?.day_birth || '')
        setValue('address', student?.result?.address || '')

        setValue('rt', student?.result?.rt || '')
        setValue('rw', student?.result?.rw || '')
        setValue('urban', student?.result?.urban || '')
        setValue('kecamatan', student?.result?.kecamatan || '')
        setValue('city', student?.result?.city || '')
        setValue('postal_code', student?.result?.postal_code || '')

        setValue('email', student?.result?.email || '')
        setValue('phone', student?.result?.phone || '')
        setValue('nisn', student?.result?.nisn || '')
        setValue('nis', student?.result?.nis || '')
        setValue('nik', student?.result?.nik || '')
        setValue('no_akta', student?.result?.no_akta || '')
        setValue('position', student?.result?.position || '')
        setValue('entry_year', student?.result?.entry_year || '')
        setValue('out_year', student?.result?.out_year || '')
        setValue('out_reason', student?.result?.out_reason || '')

        let religion = religionOption.filter(item => item.value === student?.result?.religion)
        setValue('religion', religion?.[0] || '')

        setValue('father_name', student?.result?.father_name || '')
        setValue('father_job', student?.result?.father_job || '')
        setValue('father_education', student?.result?.father_education || '')
        setValue('father_email', student?.result?.father_email || '')
        setValue('father_phone', student?.result?.father_phone || '')

        setValue('mother_name', student?.result?.mother_name || '')
        setValue('mother_job', student?.result?.mother_job || '')
        setValue('mother_education', student?.result?.mother_education || '')
        setValue('mother_email', student?.result?.mother_email || '')
        setValue('mother_phone', student?.result?.mother_phone || '')

        setValue('guardian_name', student?.result?.guardian_name || '')
        setValue('active_class', student?.result?.active_class?.classroom?.name || '')
    }, [student])

    useEffect(() => {
        if (studentCreate) {
            notify(studentCreate.message, studentCreate.success ? 'success' : 'error')
            navigate("/sia/master/student/edit/" + studentCreate.result?.id, { replace: true })
        }

        return () => {
            dispatch(setStudentCreate(null))
        }
    }, [studentCreate])

    useEffect(() => {
        if (studentUpdate) {
            notify(studentUpdate.message, studentUpdate.success ? 'success' : 'error')
            dispatch(getStudentOne(id))
            dispatch(setStudentUpdate(null))
        }

    }, [studentUpdate])

    const submitForm = (data) => {
        data.religion = data.religion.value

        if (loading === true) return;
        if (id) dispatch(putUpdateStudent(id, data))
        else dispatch(postCreateStudent(data))
    }

    return (
        <div>
            <Progressbar loading={loading} />
            <SiaHeader />
            <div className="mt-2"></div>
            <CardBasic title={title} nopadding goBack={back}>
                <div className="p-4">
                    <form onSubmit={handleSubmit(submitForm)} >
                        <div className="row">
                            <div className="col-md-12">
                                <h6 className="font-weight-bold mt-4">Data Siswa</h6>
                                <hr />
                            </div>
                            <div className="col-md-6">
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Nama"
                                    type="text"
                                    name="name"
                                    placeholder="Nama"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Jenis Kelamin"
                                    type="radio"
                                    name="gender"
                                    placeholder="Jenis Kelamin"
                                    defaultValue={watch('gender')}
                                    register={register}
                                    options={[
                                        { value: 'L', label: 'Laki-laki' },
                                        { value: 'P', label: 'Perempuan' },
                                    ]}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Tempat Lahir"
                                    type="text"
                                    name="place_birth"
                                    placeholder="Tempat Lahir"
                                    register={register}

                                    errors={formState.errors}
                                />

                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Tanggal Lahir"
                                    type="date"
                                    name="day_birth"
                                    placeholder="Tanggal Lahir"
                                    register={register}
                                    errors={formState.errors}
                                />

                                <Controller
                                    name="religion"
                                    control={control}
                                    render={({ field }) =>
                                        <TextInput
                                            defaultValue={student?.result?.code}
                                            validations={{ required: 'Harus diisi' }}
                                            layout="horizontal"
                                            label="Agama"
                                            type="select"
                                            // name="code"
                                            placeholder="Agama"
                                            register={register}
                                            field={field}
                                            errors={formState.errors}
                                            options={religionOption}
                                        />
                                    }
                                />

                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Email"
                                    type="text"
                                    name="email"
                                    placeholder="Email"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    layout="horizontal"
                                    label="No Telpon"
                                    type="text"
                                    name="phone"
                                    placeholder="No Telpon"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Alamat"
                                    type="textarea"
                                    name="address"
                                    placeholder="Alamat lengkap"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    layout="horizontal"
                                    label="RT"
                                    type="text"
                                    name="rt"
                                    placeholder="No RT"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    layout="horizontal"
                                    label="RW"
                                    type="text"
                                    name="rw"
                                    placeholder="No RW"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    layout="horizontal"
                                    label="Desa/Kelurahan"
                                    type="text"
                                    name="urban"
                                    placeholder="Desa/Kelurahan"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    layout="horizontal"
                                    label="Kecamatan"
                                    type="text"
                                    name="sub_district"
                                    placeholder="Kecamatan"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    layout="horizontal"
                                    label="Kota/Kabupaten"
                                    type="text"
                                    name="city"
                                    placeholder="Kota/Kabupaten"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    layout="horizontal"
                                    label="Kode Pos"
                                    type="text"
                                    name="postal_code"
                                    placeholder="Kode Pos"
                                    register={register}
                                    errors={formState.errors}
                                />

                            </div>
                            <div className="col-md-6">
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="NIS"
                                    type="text"
                                    name="nis"
                                    placeholder="NIS"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="NISN"
                                    type="text"
                                    name="nisn"
                                    placeholder="NISN"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="NIK"
                                    type="text"
                                    name="nik"
                                    placeholder="NIK"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="NO AKTA"
                                    type="text"
                                    name="no_akta"
                                    placeholder="NO AKTA"
                                    register={register}
                                    errors={formState.errors}
                                />

                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Tanggal Masuk"
                                    type="date"
                                    name="entry_year"
                                    placeholder="Tanggal Masuk"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    readOnly
                                    layout="horizontal"
                                    label="Tanggal Keluar"
                                    type="date"
                                    name="out_year"
                                    placeholder="Tanggal Keluar"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    readOnly
                                    layout="horizontal"
                                    label="Alasan Keluar"
                                    type="text"
                                    name="out_reason"
                                    placeholder="Alasan Keluar"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    readOnly
                                    layout="horizontal"
                                    label="Kelas Sekarang"
                                    type="text"
                                    name="active_class"
                                    placeholder="Kelas Sekarang"
                                    register={register}
                                    errors={formState.errors}
                                />

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h6 className="font-weight-bold mt-4">Data Orang Tua</h6>
                                <hr />
                            </div>
                            <div className="col-md-6">
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Nama Ayah"
                                    type="text"
                                    name="father_name"
                                    placeholder="Nama Ayah"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Pekerjaan Ayah"
                                    type="text"
                                    name="father_job"
                                    placeholder="Pekerjaan Ayah"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Pendidikan Ayah"
                                    type="text"
                                    name="father_education"
                                    placeholder="Pendidikan Ayah"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Alamat Ayah"
                                    type="textarea"
                                    name="father_address"
                                    placeholder="Alamat Ayah"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Email Ayah"
                                    type="text"
                                    name="father_email"
                                    placeholder="Email Ayah"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Telpon Ayah"
                                    type="text"
                                    name="father_phone"
                                    placeholder="Telpon Ayah"
                                    register={register}
                                    errors={formState.errors}
                                />
                            </div>
                            <div className="col-md-6">
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Nama Ibu"
                                    type="text"
                                    name="mother_name"
                                    placeholder="Nama Ibu"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Pekerjaan Ibu"
                                    type="text"
                                    name="mother_job"
                                    placeholder="Pekerjaan Ibu"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Pendidikan Ibu"
                                    type="text"
                                    name="mother_education"
                                    placeholder="Pendidikan Ibu"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Alamat Ibu"
                                    type="textarea"
                                    name="mother_address"
                                    placeholder="Alamat Ibu"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Email Ibu"
                                    type="text"
                                    name="mother_email"
                                    placeholder="Email Ibu"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Telpon Ibu"
                                    type="text"
                                    name="mother_phone"
                                    placeholder="Telpon Ibu"
                                    register={register}
                                    errors={formState.errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h6 className="font-weight-bold mt-4">Data Wali</h6>
                                <hr />
                            </div>
                            <div className="col-md-6">
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Nama Wali"
                                    type="text"
                                    name="guardian_name"
                                    placeholder="Nama Wali"
                                    register={register}
                                    errors={formState.errors}
                                />
                            </div>
                            <div className="col-md-6">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <hr />
                                <button type="submit" className="btn btn-primary btn-md">
                                    Simpan
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </CardBasic>

        </div>
    )
}

export default AuthWrapper(StudentMasterForm)
