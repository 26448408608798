import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CardBasic from "../../../components/Cards/Basic";
import PageHeading from "../../../components/PageHeading";
import TextInput from "../../../components/TextInput";
import { notify } from "../../../constants/utility";
import * as actions from "../../../redux/actions";
import { putChangePasswordUpdate } from "../../../redux/actions/account";
import { changePasswordUpdate } from "../../../redux/reducers/account";
import { AuthWrapper } from "../../AuthWrapper";

const ChangePassword = (props) => {
  const { name, label, path, title, parent } = props.data;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.setMenuActive({ menuActive: parent || name, subMenuActive: name })
    );
  }, [dispatch]);

  const { register, handleSubmit, watch, formState, setValue } = useForm();

  const { changePasswordUpdate } = useSelector(state => state.account)

  const submit = (data) => {
    dispatch(putChangePasswordUpdate(data));
  };

  useEffect(() => {
    if (changePasswordUpdate) {
      notify(changePasswordUpdate.message, changePasswordUpdate.success ? 'success' : 'error')

      if (changePasswordUpdate.success) {
        setValue('password_lama', '')
        setValue('password_baru', '')
        setValue('rpassword_baru', '')
      }
    }
  }, [changePasswordUpdate])

  return (
    <div>
      <PageHeading title={title} />
      <CardBasic title="Ubah Password">
        <form onSubmit={handleSubmit(submit)}>
          <div className="row">
            <div className="col-sm-12 col-md-6 offset-md-3">
              <TextInput
                layout="vertical"
                label="Password Lama"
                type="password"
                name="password_lama"
                placeholder=""
                register={register}
                validations={{ required: "Masukkan pasword lama" }}
                errors={formState.errors}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 offset-md-3">
              <TextInput
                layout="vertical"
                label="Password Baru"
                type="password"
                name="password_baru"
                register={register}
                validations={{
                  required: "Masukkan pasword baru",
                  minLength: {
                    value: 8,
                    message: "Password setidaknya 8 karakter",
                  },
                }}
                errors={formState.errors}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 offset-md-3">
              <TextInput
                layout="vertical"
                label="Ulangi Password Baru"
                type="password"
                name="rpassword_baru"
                register={register}
                validations={{
                  required: "Ulangi pasword baru",
                  validate: (value) =>
                    value === watch("password_baru") || "Password tidak sama",
                }}
                errors={formState.errors}
              />
              <hr />

              <button
                // disabled={loading}
                type="submit"
                className="btn btn-primary-f btn-user"
              >
                {/* <i className={`fas ` + (!loading ? `fa-sign-in-alt` : `fa-spinner fa-spin`)}></i>  */}
                &nbsp; SIMPAN
              </button>
            </div>
          </div>
        </form>
      </CardBasic>
    </div>
  );
};

export default AuthWrapper(ChangePassword);
