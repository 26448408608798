import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CardBasic from '../../../../components/Cards/Basic'
import ModalConfirm from '../../../../components/ModalConfirm';
import Progressbar from '../../../../components/Progressbar';
import { collapseSidebar, setMenuActive } from '../../../../redux/actions';
import { deleteStudentClass, getStudentClassAll, setStudentClassDelete } from '../../../../redux/actions/sia-activity/student-class';
import { AuthWrapper } from '../../../AuthWrapper';
import { Button, Modal } from 'react-bootstrap';
import SiaHeader from '../../../../components/SiaHeader';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Link } from "react-router-dom";
import Searchbar from '../../../../components/Searchbar';
import TextInput from '../../../../components/TextInput';
import { Controller, useForm } from 'react-hook-form';
import { notify } from '../../../../constants/utility';

const StudentClassActivity = (props) => {
    const { name, text, path, title, parent } = props.data
    const [modalShowConfirm, setModalShowConfirm] = useState(false)
    const [modalOption, setModalOption] = useState({})

    const dispatch = useDispatch()
    const { loading, studentClasses, studentClassDelete } = useSelector((state) => state.studentClass);

    const [search, setSearch] = useState("")
    //const [filterred, setFilterred] = useState({})
    const [sort, setSort] = useState(null)
    const [page, setPage] = useState(1)
    const [sizePerPage, setSizePerPage] = useState(10)
    const [totalSize, setTotalSize] = useState(0)
    const [data, setData] = useState([])
    const [filterables, setFilterables] = useState({})

    const [classroomOpt, setClassroomOpt] = useState([])
    const [eduyearOpt, setEduyearOpt] = useState([])
    const [studentOpt, setStudentOpt] = useState([])

    const [modalFilter, setModalFilter] = useState(false)
    const [filterCount, setFilterCount] = useState(0)

    useEffect(() => {
        dispatch(setMenuActive({ menuActive: 'sia', subMenuActive: name }))
        dispatch(collapseSidebar())
    }, [])

    useEffect(() => {
        if (studentClasses) {
            setData(studentClasses.result?.rows)
            setTotalSize(studentClasses.result?.total_count)

            if (studentClasses.result?.filterable?.classroom) {
                let arrays = studentClasses.result?.filterable?.classroom.map(item => { return ({ value: item.id, label: item.name }) })
                setClassroomOpt(arrays)
            }
            if (studentClasses.result?.filterable?.eduyear) {
                let arrays = studentClasses.result?.filterable?.eduyear.map(item => { return ({ value: item.id, label: item.name }) })
                setEduyearOpt(arrays)
            }

            if (studentClasses.result?.filterable?.student) {
                let arrays = studentClasses.result?.filterable?.student.map(item => { return ({ value: item.id, label: item.name }) })
                setStudentOpt(arrays)
            }
        }
    }, [studentClasses])

    useEffect(() => {
        const params = {
            search: search,
            page_size: sizePerPage,
            page: page,
            filter: {},
            sort: sort,
        }

        if (filterables) {
            for (var x in filterables) {
                params.filter[x] = filterables[x]
            }
        }

        dispatch(getStudentClassAll(params))

    }, [search, sort, page, sizePerPage, studentClassDelete, filterables])

    useEffect(() => {
        if (studentClassDelete) {
            notify(studentClassDelete.message, studentClassDelete.success ? 'success' : 'error')

            setModalShowConfirm(false)
        }
        return () => {
            dispatch(setStudentClassDelete(null))
        }
    }, [studentClassDelete])

    const onYesDelete = (id) => {
        dispatch(deleteStudentClass(id))
        setModalShowConfirm(false)
    }
    const onDelete = (data) => {

        setModalShowConfirm(true)
        setModalOption({
            title: 'Konfirmasi hapus',
            content: (
                <div>
                    <p>Anda yakin akan menghapus <strong>{data?.name}</strong> ?</p>

                    <hr mb={2} mt={2} />
                    <Button className='float-left' size='sm' variant={`outline-secondary`} onClick={() => setModalShowConfirm(false)}>Batal</Button>
                    <Button className='float-right' variant={`danger`} size='sm'
                        onClick={() => onYesDelete(data.id)}>Hapus</Button>
                </div>
            ),
            color: 'danger',
            size: 'md',
            data: data,
        })

    }

    const columns = [
        {
            dataField: '', text: 'No',
            headerStyle: (c, i) => { return { width: '80px' } },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i, k) => { return ((page - 1) * sizePerPage) + k + 1 }

        },
        {
            dataField: 'classroom.name', text: 'Kelas', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },

        },

        {
            dataField: 'eduyear.name', text: 'Tahun', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },
        {
            dataField: 'student.name', text: 'Siswa', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },

        {
            dataField: 'id', text: '',
            headerStyle: (colum, colIndex) => { return { width: '100px' }; },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (val, row) => {
                return (
                    <>
                        <Link to={`/sia/activity/student-class/edit/${val}`} className="btn btn-warning btn-sm m-1"><i className="fas fa-edit"></i></Link>
                        <span onClick={() => onDelete(row)} className="btn btn-danger btn-sm m-1"><i className="fas fa-trash"></i></span>
                    </>
                )
            }
        },
    ];

    const onSearchEnter = (data) => {
        setPage(1)
        setSearch(data)
    }

    const handleTableChange = (type, { sortField, sortOrder, data, page, sizePerPage }) => {
        if (type === 'sort') {
            setSort({
                orderby: sortField,
                order: sortOrder
            })
        }

        if (type === 'pagination') {
            setPage(page)
            setSizePerPage(sizePerPage)
        }
    }

    const { register, handleSubmit, watch, formState, setValue, control } = useForm()

    const handleFilterSubmit = (data) => {
        // let newData = {}
        let count = 0;
        let filter = {}
        for (var x in data) {
            if (data[x] !== undefined) {
                // newData[x] = data[key].map(item=>item.value)
                let item = data[x]
                if (item === true) {
                    filter[x] = {
                        op: 'equal',
                        value: item
                    }
                    count += 1
                }

                if (item?.length > 0) {
                    let value = item.map(i => i.value)

                    filter[x] = {
                        op: 'in',
                        value: value
                    }
                    count += value?.length
                }

            }
        }
        setFilterables(filter)
        setFilterCount(count)
        setPage(1)
        setModalFilter(false)
    }

    return (
        <div>
            <Progressbar loading={loading} />
            <SiaHeader />
            <div className="mt-2"></div>
            <CardBasic title={title} nopadding>
                <div className="d-flex p-3">
                    {/* <Link to="new" className="mr-2"><span className="btn btn-primary btn-md">Tambah</span></Link> */}
                    <Link to="new?mass=1" className="mr-4"><span className="btn btn-primary btn-md">Data Per Kelas</span></Link>
                    <Button
                        onClick={() => setModalFilter(true)}
                        variant="outline-secondary" style={{ height: "39px" }} className="mr-2" size="md">
                        Filter &nbsp; <span className="badge badge-primary">{filterCount}</span>
                    </Button>

                    <div className="flex-grow-1">
                        <Searchbar
                            placeholder="Pencarian"
                            onEnter={(data) => onSearchEnter(data)} />
                    </div>
                </div>
                <RemotePagination
                    data={data}
                    page={page}
                    sizePerPage={sizePerPage}
                    totalSize={totalSize}
                    columns={columns}
                    onTableChange={handleTableChange}
                />

            </CardBasic>

            <ModalConfirm
                show={modalShowConfirm}
                modalOption={modalOption}
                onHide={() => setModalShowConfirm(false)}
            />
            <Modal
                {...props}
                show={modalFilter}
                size={'lg'}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setModalFilter(false)}
            >
                <Modal.Header className='text-primary'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Filter
                    </Modal.Title>
                    <span className='btn' onClick={() => setModalFilter(false)}>&times;</span>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(handleFilterSubmit)}>
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <Controller
                                    name="classroom_id"
                                    control={control}
                                    render={({ field }) =>
                                        <TextInput
                                            isMulti={true}
                                            validations={{ required: 'Harus diisi' }}
                                            layout="verical"
                                            label="Kelas"
                                            type="select"
                                            placeholder="Kelas"
                                            register={register}
                                            field={field}
                                            errors={formState.errors}
                                            options={classroomOpt}
                                        />
                                    }
                                />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <Controller
                                    name="eduyear_id"
                                    control={control}
                                    render={({ field }) =>
                                        <TextInput
                                            isMulti={true}
                                            validations={{ required: 'Harus diisi' }}
                                            layout="verical"
                                            label="Tahun"
                                            type="select"
                                            placeholder="Tahun"
                                            register={register}
                                            field={field}
                                            errors={formState.errors}
                                            options={eduyearOpt}
                                        />
                                    }
                                />
                            </div>

                            <div className="col-md-4 col-sm-12">
                                <Controller
                                    name="student_id"
                                    control={control}
                                    render={({ field }) =>
                                        <TextInput
                                            isMulti={true}
                                            validations={{ required: 'Harus diisi' }}
                                            layout="verical"
                                            label="Siswa"
                                            type="select"
                                            placeholder="Siswa"
                                            register={register}
                                            field={field}
                                            errors={formState.errors}
                                            options={studentOpt}
                                        />
                                    }
                                />
                            </div>
                        </div>



                        <hr />
                        <button type="submit" className="btn btn-primary btn-md">Set Filter</button>
                    </form>
                </Modal.Body>
            </Modal>
        </div >
    )
}

const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize, columns, onRowClick }) => {
    const indication = () => {
        return 'No data'
    }

    const defaultSorted = [{
        dataField: 'created_at',
        order: 'desc'
    }];
    return (
        <div className="table-fixied custom-scrollbar table-responsive my-table">
            <BootstrapTable
                remote
                className="bt-table"
                bootstrap4
                hover
                bordered
                striped
                condensed
                noDataIndication={indication}
                bordered={false}
                keyField="id"
                defaultSorted={defaultSorted}
                data={data}
                columns={columns}
                pagination={paginationFactory({ page, sizePerPage, totalSize })}
                onTableChange={onTableChange}
            />
        </div>
    )
}

export default AuthWrapper(StudentClassActivity)
