import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CardBasic from '../../../../../components/Cards/Basic'
import ModalConfirm from '../../../../../components/ModalConfirm';
import Progressbar from '../../../../../components/Progressbar';
import { collapseSidebar, setMenuActive } from '../../../../../redux/actions';
import { getStudentClassLessons, } from '../../../../../redux/actions/sia-activity/student-class-lesson';
import { AuthWrapper } from '../../../../AuthWrapper';
import SiaHeader from '../../../../../components/SiaHeader';
import BootstrapTable from 'react-bootstrap-table-next';
//import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Link } from "react-router-dom";
import Searchbar from '../../../../../components/Searchbar';
import { useParams } from 'react-router-dom'

const StudentClassLesson = (props) => {
    const { name, title } = props.data
    const [modalShowConfirm, setModalShowConfirm] = useState(false)
    const [modalOption, setModalOption] = useState({})
    const { classroom_id, eduyear_id, semester_id } = useParams()
    let back = '/sia/activity/student-class-lesson'
    // path.replace(`/${path.split("/")[path.split("/").length - 1]}`, "")
    // if (back.includes('/per-class')) back = back.replace('/per-class', '')

    const dispatch = useDispatch()
    const { loading, studentClassLessons } = useSelector((state) => state.studentClassLesson);

    const [search, setSearch] = useState("")
    const [sort, setSort] = useState(null)
    const [page, setPage] = useState(1)
    const [sizePerPage, setSizePerPage] = useState(10)
    const [totalSize, setTotalSize] = useState(0)
    const [data, setData] = useState([])
    const [filterables, setFilterables] = useState({})


    useEffect(() => {
        dispatch(setMenuActive({ menuActive: 'sia', subMenuActive: name }))
        dispatch(collapseSidebar())
    }, [])

    const [headingData, setHeadingData] = useState('')
    useEffect(() => {
        if (studentClassLessons) {
            setData(studentClassLessons.result)
            setTotalSize(studentClassLessons.result?.total_count)
            setHeadingData(`${studentClassLessons.result[0]['lesson_class.classroom.name']} - ${studentClassLessons.result[0]['lesson_class.eduyear.name']} - ${studentClassLessons.result[0]['lesson_class.semester.name']}`)
        }
    }, [studentClassLessons])

    useEffect(() => {
        const params = {
            search: search,
            page_size: sizePerPage,
            page: page,
            filter: {},
            sort: sort,
        }

        if (filterables) {
            for (var x in filterables) {
                params.filter[x] = filterables[x]
            }
        }

        dispatch(getStudentClassLessons(classroom_id, eduyear_id, semester_id, params))

    }, [search, sort, page, sizePerPage, filterables])

    const columns = [
        {
            dataField: '', text: 'No',
            headerStyle: (c, i) => { return { width: '80px' } },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i, k) => { return ((page - 1) * sizePerPage) + k + 1 }

        },

        {
            dataField: 'lesson_class.lesson.name', text: 'Mapel', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },
        {
            dataField: 'lesson_class.teacher.name', text: 'Guru', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },
        {
            dataField: 'status', text: 'Status', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },

        {
            dataField: 'id', text: '',
            // headerStyle: (colum, colIndex) => { return { width: '100px' }; },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (val, row) => {
                return (
                    <>
                        <Link to={`/sia/activity/student-class-lesson/classroom/${classroom_id}/eduyear/${eduyear_id}/semester/${semester_id}/detail/${row['lesson_class.id']}?tab-active=student`} className="btn btn-info btn-sm m-1">Peserta</Link>
                        <Link to={`/sia/activity/student-class-lesson/classroom/${classroom_id}/eduyear/${eduyear_id}/semester/${semester_id}/detail/${row['lesson_class.id']}?tab-active=material`} className="btn btn-info btn-sm m-1">Materi</Link>
                        <Link to={`/sia/activity/student-class-lesson/classroom/${classroom_id}/eduyear/${eduyear_id}/semester/${semester_id}/detail/${row['lesson_class.id']}?tab-active=task`} className="btn btn-info btn-sm m-1">Tugas</Link>
                    </>
                )
            }
        },
    ];

    const onSearchEnter = (data) => {
        setPage(1)
        setSearch(data)
    }

    const indication = () => {
        return 'No data'
    }

    const defaultSorted = [{
        dataField: 'created_at',
        order: 'desc'
    }];

    return (
        <div>
            <Progressbar loading={loading} />
            <SiaHeader />
            <div className="mt-2"></div>
            <CardBasic title={`${title}  |  ${headingData}`} nopadding goBack={back}>
                <div className="d-flex p-3">
                    <div className="flex-grow-1">
                        <Searchbar
                            placeholder="Pencarian"
                            onEnter={(data) => onSearchEnter(data)} />
                    </div>
                </div>
                <BootstrapTable
                    className="bt-table"
                    bootstrap4
                    hover
                    bordered
                    striped
                    condensed
                    noDataIndication={indication}
                    bordered={false}
                    keyField="id"
                    defaultSorted={defaultSorted}
                    data={data}
                    columns={columns}
                // pagination={paginationFactory({ page, sizePerPage, totalSize })}
                // onTableChange={onTableChange}
                />

            </CardBasic>

            <ModalConfirm
                show={modalShowConfirm}
                modalOption={modalOption}
                onHide={() => setModalShowConfirm(false)}
            />
        </div >
    )
}

export default AuthWrapper(StudentClassLesson)
