import React from 'react';
import Profile from './pages/Account/Profile';
import ChangePassword from './pages/Account/ChangePassword';
import Sia from './pages/Sia';
import LessonMaster from './pages/Sia/Master/Lesson';
import LessonMasterForm from './pages/Sia/Master/Lesson/From';
import ClassroomMaster from './pages/Sia/Master/Classroom';
import ClassroomMasterForm from './pages/Sia/Master/Classroom/From';
import EduyearMaster from './pages/Sia/Master/Eduyear';
import EduyearMasterForm from './pages/Sia/Master/Eduyear/From';
import TeacherMaster from './pages/Sia/Master/Teacher';
import TeacherMasterForm from './pages/Sia/Master/Teacher/From';
import StudentMaster from './pages/Sia/Master/Student';
import StudentMasterForm from './pages/Sia/Master/Student/From';
import SiaSettingForm from './pages/Sia/Master/Setting';
import LessonClass from './pages/Sia/Activity/Lesson-class';
import LessonClassForm from './pages/Sia/Activity/Lesson-class/Form';
import StudentClassActivity from './pages/Sia/Activity/Student-class';
import StudentClassForm from './pages/Sia/Activity/Student-class/Form';
import LessonClassStudent from './pages/Sia/Activity/Lesson-class-student';
import LessonClassStudentForm from './pages/Sia/Activity/Lesson-class-student/Form';

import TeacherClassLesson from './pages/Sia/Activity/Teacher-class-lesson';
import TeacherClassLessonDetail from './pages/Sia/Activity/Teacher-class-lesson/Detail';
import OneStudentClassLesson from './pages/Sia/Activity/Student-class-lesson';
import StudentClassLesson from './pages/Sia/Activity/Student-class-lesson/Per-class';
import StudentClassLessonDetail from './pages/Sia/Activity/Student-class-lesson/Per-class/Detail';
import RaporActivity from './pages/Sia/Activity/Rapor';

export const menus = [
  {
    name: 'profile',
    label: 'profile',
    path: '/account/profile',
    title: 'Profil User',
    hidden: true,
    access: ['manage-profile'],
    component: (data) => <Profile data={data} />,
  },
  {
    name: 'change-password',
    label: 'Ubah Password',
    path: '/account/change-password',
    title: 'Ubah Password',
    hidden: true,
    access: ['manage-profile'],
    component: (data) => <ChangePassword data={data} />,
  },

  {
    name: 'sia',
    label: 'SIA',
    path: '/',
    icon: 'fa-exchange-alt',
    access: ['sia-dashboard'],
    component: (data) => <Sia data={data} />,
  },

  // {
  //   name: "sia",
  //   label: "Dashboard",
  //   path: "/sia",
  //   icon: "fa-exchange-alt",
  //   is_sia: true,
  //   access: ['sia-dashboard'],
  //   component: (data) => <Sia data={data} />
  // },

  {
    name: 'sia-master',
    label: 'Master',
    path: '/master',
    icon: 'fa-exchange-alt',
    is_sia: true,
    access: ['sia-master'],
    subs: [
      {
        name: 'sia-master-lesson',
        label: 'Pelajaran',
        path: '/master/lesson',
        title: 'Master Pelajaran',
        parent: 'sia-master',
        access: ['sia-master'],
        component: (data) => <LessonMaster data={data} />,
      },
      {
        name: 'sia-master-lesson',
        label: 'Pelajaran',
        path: '/master/lesson/new',
        title: 'Tambah Pelajaran',
        parent: 'sia-master',
        hidden: true,
        access: ['sia-master'],
        component: (data) => <LessonMasterForm data={data} />,
      },
      {
        name: 'sia-master-lesson',
        label: 'Pelajaran',
        path: '/master/lesson/edit/:id',
        title: 'Edit Pelajaran',
        parent: 'sia-master',
        hidden: true,
        access: ['sia-master'],
        component: (data) => <LessonMasterForm data={data} />,
      },
      //classroom
      {
        name: 'sia-master-classroom',
        label: 'Kelas',
        path: '/master/classroom',
        title: 'Master Kelas',
        parent: 'sia-master',
        access: ['sia-master'],
        component: (data) => <ClassroomMaster data={data} />,
      },
      {
        name: 'sia-master-classroom',
        label: 'Kelas',
        path: '/master/classroom/new',
        title: 'Tambah Kelas',
        parent: 'sia-master',
        hidden: true,
        access: ['sia-master'],
        component: (data) => <ClassroomMasterForm data={data} />,
      },
      {
        name: 'sia-master-classroom',
        label: 'Kelas',
        path: '/master/classroom/edit/:id',
        title: 'Edit Kelas',
        parent: 'sia-master',
        hidden: true,
        access: ['sia-master'],
        component: (data) => <ClassroomMasterForm data={data} />,
      },
      //eduyear
      {
        name: 'sia-master-eduyear',
        label: 'Tahun Ajaran',
        path: '/master/eduyear',
        title: 'Master Tahun Ajaran',
        parent: 'sia-master',
        access: ['sia-master'],
        component: (data) => <EduyearMaster data={data} />,
      },
      {
        name: 'sia-master-eduyear',
        label: 'Tahun Ajaran',
        path: '/master/eduyear/new',
        title: 'Tambah Tahun Ajaran',
        parent: 'sia-master',
        hidden: true,
        access: ['sia-master'],
        component: (data) => <EduyearMasterForm data={data} />,
      },
      {
        name: 'sia-master-eduyear',
        label: 'Tahun Ajaran',
        path: '/master/eduyear/edit/:id',
        title: 'Edit Tahun Ajaran',
        parent: 'sia-master',
        hidden: true,
        access: ['sia-master'],
        component: (data) => <EduyearMasterForm data={data} />,
      },
      //teacher
      {
        name: 'sia-master-teacher',
        label: 'Guru',
        path: '/master/teacher',
        title: 'Master Guru',
        parent: 'sia-master',
        access: ['sia-master'],
        component: (data) => <TeacherMaster data={data} />,
      },
      {
        name: 'sia-master-teacher',
        label: 'Guru',
        path: '/master/teacher/new',
        title: 'Tambah Guru',
        parent: 'sia-master',
        hidden: true,
        access: ['sia-master'],
        component: (data) => <TeacherMasterForm data={data} />,
      },
      {
        name: 'sia-master-teacher',
        label: 'Guru',
        path: '/master/teacher/edit/:id',
        title: 'Edit Guru',
        parent: 'sia-master',
        hidden: true,
        access: ['sia-master'],
        component: (data) => <TeacherMasterForm data={data} />,
      },
      //student
      {
        name: 'sia-master-student',
        label: 'Siswa',
        path: '/master/student',
        title: 'Master Siswa',
        parent: 'sia-master',
        access: ['sia-master'],
        component: (data) => <StudentMaster data={data} />,
      },
      {
        name: 'sia-master-student',
        label: 'Siswa',
        path: '/master/student/new',
        title: 'Tambah Siswa',
        parent: 'sia-master',
        hidden: true,
        access: ['sia-master'],
        component: (data) => <StudentMasterForm data={data} />,
      },
      {
        name: 'sia-master-student',
        label: 'Siswa',
        path: '/master/student/edit/:id',
        title: 'Edit Siswa',
        parent: 'sia-master',
        hidden: true,
        access: ['sia-master'],
        component: (data) => <StudentMasterForm data={data} />,
      },
      {
        name: 'sia-master-setting',
        label: 'Pengaturan',
        path: '/master/setting',
        title: 'Pengaturan',
        parent: 'sia-master',
        access: ['sia-master'],
        component: (data) => <SiaSettingForm data={data} />,
      },
    ],
  },
  {
    name: 'activity',
    label: 'Aktivitas',
    path: '/activity',
    icon: 'fa-exchange-alt',
    is_sia: true,
    access: ['sia-activity'],
    subs: [
      // student class lesson
      {
        name: 'sia-activity-one-student-class-lesson',
        label: 'Kelas Saya (Siswa)',
        path: '/activity/student-class-lesson',
        title: 'Kelas Saya (Siswa)',
        parent: 'activity',
        access: ['sia-one-student-class-lesson-activity'],
        component: (data) => <OneStudentClassLesson data={data} />,
      },
      {
        name: 'sia-activity-student-class-lesson-detail',
        label: 'Kelas Saya (Siswa)',
        path: '/activity/student-class-lesson/classroom/:classroom_id/eduyear/:eduyear_id/semester/:semester_id',
        title: 'Kelas Saya (Siswa)',
        parent: 'activity',
        access: ['sia-student-class-lesson-activity'],
        hidden: true,
        component: (data) => <StudentClassLesson data={data} />,
      },
      {
        name: 'sia-activity-student-class-lesson',
        label: 'Kelas Saya (Siswa)',
        path: '/activity/student-class-lesson/classroom/:classroom_id/eduyear/:eduyear_id/semester/:semester_id/detail/:id',
        title: 'Kelas Saya (Siswa)',
        parent: 'activity',
        access: ['sia-student-class-lesson-activity'],
        hidden: true,
        component: (data) => <StudentClassLessonDetail data={data} />,
      },

      // teacher class lesson
      {
        name: 'sia-activity-teacher-class-lesson',
        label: 'Kelas Saya (Guru)',
        path: '/activity/teacher-class-lesson',
        title: 'Kelas Saya (Guru)',
        parent: 'activity',
        access: ['sia-teacher-class-lesson-activity'],
        component: (data) => <TeacherClassLesson data={data} />,
      },
      {
        name: 'sia-activity-teacher-class-lesson-detail',
        label: 'Detail Kelas Saya (Guru)',
        path: '/activity/teacher-class-lesson/detail/:id',
        title: 'Detail Kelas Saya (Guru)',
        parent: 'activity',
        hidden: true,
        access: ['sia-teacher-class-lesson-activity'],
        component: (data) => <TeacherClassLessonDetail data={data} />,
      },

      // student class
      {
        name: 'sia-activity-student-class',
        label: 'Peserta Kelas',
        path: '/activity/student-class',
        title: 'Peserta Kelas',
        parent: 'activity',
        access: ['sia-student-class-activity'],
        component: (data) => <StudentClassActivity data={data} />,
      },
      {
        name: 'sia-activity-student-class',
        label: 'Tambah Peserta Kelas',
        path: '/activity/student-class/new',
        title: 'Tambah Peserta Kelas',
        parent: 'activity',
        hidden: true,
        access: ['sia-student-class-activity'],
        component: (data) => <StudentClassForm data={data} />,
      },
      {
        name: 'sia-activity-student-class',
        label: 'Edit Peserta Kelas',
        path: '/activity/student-class/edit/:id',
        title: 'Edit Peserta Kelas',
        parent: 'activity',
        hidden: true,
        access: ['sia-student-class-activity'],
        component: (data) => <StudentClassForm data={data} />,
      },

      // lesson-class
      {
        name: 'sia-activity-lesson-class',
        label: 'Pelajaran Kelas',
        path: '/activity/lesson-class',
        title: 'Aktivitas Pelajaran Kelas',
        parent: 'activity',
        access: ['sia-lesson-class-acivity'],
        component: (data) => <LessonClass data={data} />,
      },
      {
        name: 'sia-activity-lesson-class',
        label: 'Tambah Pelajaran Kelas',
        path: '/activity/lesson-class/new',
        title: 'Tambah Aktivitas Pelajaran Kelas',
        parent: 'activity',
        hidden: true,
        access: ['sia-lesson-class-acivity'],
        component: (data) => <LessonClassForm data={data} />,
      },
      {
        name: 'sia-activity-lesson-class',
        label: 'Edit Pelajaran Kelas',
        path: '/activity/lesson-class/edit/:id',
        title: 'Edit Aktivitas Pelajaran Kelas',
        parent: 'activity',
        hidden: true,
        access: ['sia-lesson-class-acivity'],
        component: (data) => <LessonClassForm data={data} />,
      },
      {
        name: 'sia-activity-lesson-class',
        label: 'Pelajaran Kelas Per Kelas',
        path: '/activity/lesson-class/perclass',
        title: 'Aktivitas Pelajaran Kelas Per Kelas',
        parent: 'activity',
        hidden: true,
        access: ['sia-lesson-class-acivity'],
        component: (data) => <LessonClassForm mass={true} data={data} />,
      },
      // lesson-class-student
      {
        name: 'sia-activity-lesson-class-student',
        label: 'Pelajaran Siswa',
        path: '/activity/lesson-class-student',
        title: 'Aktivitas Pelajaran Siswa',
        parent: 'activity',
        access: ['sia-lesson-class-student-activity'],
        component: (data) => <LessonClassStudent data={data} />,
      },
      {
        name: 'sia-activity-lesson-class-student',
        label: 'Tambah Pelajaran Siswa',
        path: '/activity/lesson-class-student/new',
        title: 'Tambah Aktivitas Pelajaran Siswa',
        parent: 'activity',
        hidden: true,
        access: ['sia-lesson-class-student-activity'],
        component: (data) => <LessonClassStudentForm data={data} />,
      },
      {
        name: 'sia-activity-lesson-class-student',
        label: 'Edit Pelajaran Siswa',
        path: '/activity/lesson-class-student/edit/:id',
        title: 'Edit Aktivitas Pelajaran Siswa',
        parent: 'activity',
        hidden: true,
        access: ['sia-lesson-class-student-activity'],
        component: (data) => <LessonClassStudentForm data={data} />,
      },
      {
        name: 'sia-activity-lesson-class-student',
        label: 'Pelajaran Siswa Per Kelas',
        path: '/activity/lesson-class-student/perlesson',
        title: 'Aktivitas Pelajaran Siswa Per Pelajaran',
        parent: 'activity',
        hidden: true,
        access: ['sia-lesson-class-student-activity'],
        component: (data) => <LessonClassStudentForm mass={true} data={data} />,
      },
      {
        name: 'sia-activity-rapor',
        label: 'Rapor',
        path: '/activity/rapor',
        title: 'Aktivitas Rapor Siswa',
        parent: 'activity',
        access: ['sia-rapor'],
        component: (data) => <RaporActivity data={data} />,
      },
      {
        name: 'sia-activity-rapor',
        label: 'Rapor',
        path: '/activity/rapor/:id',
        title: 'Update Rapor Siswa',
        parent: 'activity',
        hidden: true,
        access: ['sia-manage-rapor'],
        component: (data) => <RaporActivity data={data} />,
      },
    ],
  },
];
