import React from 'react'
import { useDispatch } from 'react-redux'
import { downloadFileAction, formatDate } from '../../../../../../../../constants/utility'
import { download } from '../../../../../../../../redux/actions/download'

const Detail = (props) => {
    const { data, close } = props
    const dispatch = useDispatch()
    return (
        <div>
            <div className="d-flex border-bottom mb-4 mt-2 py-2 ">
                <span
                    className="btn btn-sm btn-secondary mr-2"
                    onClick={() => close()}
                    style={{ height: "30px", width: "50px" }}><i className="fas fa-arrow-left"></i></span>
                <h6 className="font-weight-bold p-1">Detail Materi</h6>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-3">Nama</div>
                <div className="col-sm-12 col-md-9">{data.name}</div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-3">Keterangan</div>
                <div className="col-sm-12 col-md-9">{data.description}</div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-3">Lampiran</div>
                <div className="col-sm-12 col-md-9">{data.attachment}
                    <span
                        className="btn btn-secondary btn-sm"
                        onClick={() => dispatch(download(data.attachment))}>
                        <i className="fas fa-download"></i></span>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-3">Dibuat</div>
                <div className="col-sm-12 col-md-9">{formatDate(data.created_at)}</div>
            </div>

        </div>
    )
}

export default Detail
