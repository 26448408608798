import * as actionTypes from '../../../../constants/actionTypes';
import http from "../../../../services/NetworkService";

export const setOnloading = (payload) => {
    return {
        type: actionTypes.ON_LOADING,
        data: payload,
    };
};

export const setGetSettings = (payload) => {
    return {
        type: actionTypes.GET_SETTINGS,
        data: payload,
    };
};

export const getSettings = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const params = {
            page: data?.page || 1,
            page_size: data?.page_size || 10,
            search: data?.search,
            orderby: data?.sort?.orderby || null,
            order: data?.sort?.order || null,
        };

        const result = await http.get(`/sia/master/setting`, { params });
        dispatch(setGetSettings(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}


export const setPostSetSetting = (payload) => {
    return {
        type: actionTypes.SET_SETTINGS,
        data: payload,
    };
}

export const postSetSetting = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/sia/master/setting`, data);
        dispatch(setPostSetSetting(result))
        dispatch(setOnloading(null))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}