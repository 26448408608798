import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import TextInput from '../../../../../../../components/TextInput'
import { postTeacherClassLessonMaterial, postUploadAttachment, putTeacherClassLessonMaterial } from '../../../../../../../redux/actions/sia-activity/teacher-class-lesson'

const Form = (props) => {
    const { lesson_class_id, form_data, close } = props
    const { register, handleSubmit, watch, formState, setValue } = useForm()
    const { teacherClassLessonAttachmentUpload } = useSelector((state) => state.teacherClassLesson);
    const dispatch = useDispatch()

    const submitMateri = (data) => {
        data.attachment = teacherClassLessonAttachmentUpload?.result?.filename || form_data?.attachment || null
        console.log(data)
        if (form_data.id === 0) {
            dispatch(postTeacherClassLessonMaterial(lesson_class_id, data))
        } else {
            dispatch(putTeacherClassLessonMaterial(lesson_class_id, form_data.id, data))
        }
    }

    useEffect(() => {
        if (watch('attachment')?.length > 0) {
            console.log(watch('attachment'))
            let fromData = new FormData();
            fromData.append('attachment', watch('attachment')[0])
            // console.log('acc', uploadImage)
            if (teacherClassLessonAttachmentUpload) fromData.append('old_attachment', teacherClassLessonAttachmentUpload.result.filename)
            dispatch(postUploadAttachment(fromData));
        }
    }, [watch('attachment')])

    useEffect(() => {
        setValue('name', form_data?.name || '')
        setValue('description', form_data?.description || '')
    }, [form_data])

    return (
        <div>
            <div className="d-flex border-bottom mb-4 mt-2 py-2 ">
                <span
                    className="btn btn-sm btn-secondary mr-2"
                    onClick={() => close()}
                    style={{ height: "30px", width: "50px" }}><i className="fas fa-arrow-left"></i></span>
                <h6 className="font-weight-bold p-1">{form_data.id === 0 ? `Tambah` : `Edit/Detail`} Materi</h6>
            </div>
            <form onSubmit={handleSubmit(submitMateri)}>
                <div className="row">
                    <div className="col-md-6">
                        <TextInput
                            layout="horizontal"
                            label="Nama Materi"
                            type="text"
                            name="name"
                            placeholder="Nama Materi"
                            register={register}
                            errors={formState.errors}
                            validations={{ required: true }}
                        />
                        <TextInput
                            layout="horizontal"
                            label="Keterangan"
                            type="textarea"
                            name="description"
                            placeholder="Keterangan"
                            register={register}
                            errors={formState.errors}
                        />
                    </div>
                    <div className="col-md-6">
                        <TextInput
                            layout="horizontal"
                            label="Lampiran"
                            type="file"
                            name="attachment"
                            placeholder="Lampiran"
                            register={register}
                            accept=".jpg,.jpeg,.png,.doc,.txt,.csv,.xls,.xlsx,.ppt,.pptx,.zip,.rar"
                            errors={formState.errors}
                        />
                    </div>
                    <div className="col-md-12">
                        <hr />
                        <div className="d-flex justify-content-between">
                            {/* <button type="button" className="btn btn-light btn-md" onClick={() => close()}>Kembali</button> */}
                            <button type="submit" className="btn btn-primary btn-md">{form_data.id === 0 ? `Simpan` : `Update`}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Form
