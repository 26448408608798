import * as actionTypes from '../../../constants/actionTypes';
import http from "../../../services/NetworkService";

export const setOnloading = (payload) => { return { type: actionTypes.ON_LOADING, data: payload } };

export const setGetProfile = (payload) => { return { type: actionTypes.GET_PROFILE, data: payload } }
export const getProfile = () => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/account/profile`);
        dispatch(setGetProfile(result))
        dispatch(setOnloading(null))
    } catch (err) {
        dispatch(setOnloading(null))
        console.error(err);
    }
}

export const setPutProfileUpdate = (payload) => { return { type: actionTypes.PROFILE_UPDATE, data: payload } }
export const putProfileUpdate = (id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.put(`/account/profile/${id}`, data);
        dispatch(setPutProfileUpdate(result))
        dispatch(setOnloading(null))
    } catch (err) {
        dispatch(setOnloading(null))
        console.error(err);
    }
}

export const setPutChangePasswordUpdate = (payload) => { return { type: actionTypes.CHANGE_PASSWORD_UPDATE, data: payload } }
export const putChangePasswordUpdate = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.put(`/account/change-password`, data);
        dispatch(setPutChangePasswordUpdate(result))
        dispatch(setOnloading(null))
    } catch (err) {
        dispatch(setOnloading(null))
        console.error(err);
    }
}

