import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CardBasic from "../../../components/Cards/Basic";
import PageHeading from "../../../components/PageHeading";
import TextInput from "../../../components/TextInput";
import { notify } from "../../../constants/utility";
import * as actions from "../../../redux/actions";
import {
    getProfile,
    putProfileUpdate
} from "../../../redux/actions/account";
import { AuthWrapper } from "../../AuthWrapper";
import profileDummy from "../../../assets/img/profile.jpg";

const Profile = (props) => {
    const { name, label, path, title, parent } = props.data;
    const { data: userData } = useSelector((state) => state.auth);
    const { profile, profileUpdate } = useSelector((state) => state.account);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            actions.setMenuActive({ menuActive: parent || name, subMenuActive: name })
        );
    }, [dispatch]);

    useEffect(() => {
        if (userData) dispatch(getProfile(userData.id));
    }, [userData]);

    const { register, handleSubmit, watch, formState, setValue } = useForm();
    const [teacher, setTeacher] = useState(null)
    const [student, setStudent] = useState(null)
    const imageImg = useRef(null);
    const [imageFile, setImageFile] = useState(null);
    useEffect(() => {
        setValue("name", profile?.result?.name || null);
        setValue("email", profile?.result?.email || null);
        setValue("username", profile?.result?.username || null);
        setTeacher(profile?.result?.teacher)
        setStudent(profile?.result?.student)
        setImageFile(profile?.result?.photo || profileDummy)
    }, [profile]);

    const submit = (data) => {
        const formData = new FormData()
        formData.append('name', data.name)
        formData.append('username', data.username)
        formData.append('email', data.email)
        formData.append('photo', data.photo[0])
        dispatch(putProfileUpdate(profile?.result?.id, formData))
    };

    useEffect(() => {
        if (profileUpdate) {
            notify(profileUpdate.message, profileUpdate.success ? 'success' : 'error')
        }
    }, [profileUpdate])

    useEffect(() => {
        if (watch("photo").length > 0) {
            setImageFile(URL.createObjectURL(watch("photo")[0]));
        }
    }, [watch("photo")]);


    return (
        <div>
            <PageHeading title={title} />
            <CardBasic title="Profile">
                <form onSubmit={handleSubmit(submit)}>
                    <h6 className="font-weight-bold">Data Login</h6>
                    <div className="row">
                        <div className="col-sm-9">
                            <TextInput
                                layout="horizontal"
                                label="Nama"
                                type="text"
                                name="name"
                                placeholder="Nama"
                                register={register}
                                validations={{ required: "Harus diisi" }}
                                errors={formState.errors}
                            />
                            <TextInput
                                layout="horizontal"
                                label="Username"
                                type="text"
                                name="username"
                                placeholder="Username"
                                register={register}
                                validations={{ required: "Harus diisi" }}
                                errors={formState.errors}
                            />
                            <TextInput
                                layout="horizontal"
                                label="Email"
                                type="text"
                                name="email"
                                placeholder="Email"
                                register={register}
                                validations={{ required: "Harus diisi" }}
                                errors={formState.errors}
                            />
                        </div>
                        <div className="col-sm-3">
                            <TextInput
                                layout="vertical"
                                type="file"
                                accept={".jpg,.jpeg, .png"}
                                name="photo"
                                placeholder="Foto profil"
                                register={register}
                                validations={{ required: "Harus diisi" }}
                                errors={formState.errors}
                            />
                            <img
                                ref={imageImg}
                                style={{ width: "200px", maxWidth: "100%", height: "200px", marginRight: "auto", marginLeft: "auto" }}
                                src={imageFile} className="img-thumbnail" />
                        </div>
                        <div className="col-sm-12">
                            <hr />
                            <button
                                // disabled={loading}
                                type="submit"
                                className="btn btn-primary-f btn-user"
                            >
                                {/* <i className={`fas ` + (!loading ? `fa-sign-in-alt` : `fa-spinner fa-spin`)}></i>  */}
                                &nbsp; SIMPAN
                            </button>
                        </div>
                    </div>
                </form>
                <hr />
                {userData?.user?.user_type === 'teacher' ?
                    <>
                        <h6 className="font-weight-bold">Data Guru</h6>
                        <table className="custom-table">
                            <tbody>
                                <tr>
                                    <td>NIP</td>
                                    <td>:</td>
                                    <td>{teacher?.nip}</td>
                                </tr>
                                <tr>
                                    <td>NIK</td>
                                    <td>:</td>
                                    <td>{teacher?.nik}</td>
                                </tr>
                                <tr>
                                    <td>Nama</td>
                                    <td>:</td>
                                    <td>{teacher?.name}</td>
                                </tr>
                                <tr>
                                    <td>Jenis Kelamin</td>
                                    <td>:</td>
                                    <td>{teacher?.gender}</td>
                                </tr>
                                <tr>
                                    <td>Tempat Lahir</td>
                                    <td>:</td>
                                    <td>{teacher?.place_birth}</td>
                                </tr>
                                <tr>
                                    <td>Religion</td>
                                    <td>:</td>
                                    <td>{teacher?.day_birth}</td>
                                </tr>
                                <tr>
                                    <td>Golongan</td>
                                    <td>:</td>
                                    <td>{teacher?.employee_group}</td>
                                </tr>
                                <tr>
                                    <td>Jabatan</td>
                                    <td>:</td>
                                    <td>{teacher?.position}</td>
                                </tr>
                                <tr>
                                    <td>Pendidikan</td>
                                    <td>:</td>
                                    <td>{teacher?.last_education}</td>
                                </tr>
                                <tr>
                                    <td>Alamat</td>
                                    <td>:</td>
                                    <td>{teacher?.address}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>:</td>
                                    <td>{teacher?.email}</td>
                                </tr>
                                <tr>
                                    <td>Telp</td>
                                    <td>:</td>
                                    <td>{teacher?.phone}</td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                    : userData?.user?.user_type === 'student' ?
                        <>
                            <h6 className="font-weight-bold">Data Siswa</h6>
                            <table className="custom-table">
                                <tbody>
                                    <tr>
                                        <td>NIS</td>
                                        <td>:</td>
                                        <td>{student?.nis}</td>
                                    </tr>
                                    <tr>
                                        <td>NISN</td>
                                        <td>:</td>
                                        <td>{student?.nisn}</td>
                                    </tr>
                                    <tr>
                                        <td>NIK</td>
                                        <td>:</td>
                                        <td>{student?.nik}</td>
                                    </tr>
                                    <tr>
                                        <td>No Akta</td>
                                        <td>:</td>
                                        <td>{student?.no_akta}</td>
                                    </tr>
                                    <tr>
                                        <td>Nama</td>
                                        <td>:</td>
                                        <td>{student?.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Jenis Kelamin</td>
                                        <td>:</td>
                                        <td>{student?.gender}</td>
                                    </tr>
                                    <tr>
                                        <td>Tempat Lahir</td>
                                        <td>:</td>
                                        <td>{student?.place_birth}</td>
                                    </tr>
                                    <tr>
                                        <td>Religion</td>
                                        <td>:</td>
                                        <td>{student?.religion}</td>
                                    </tr>

                                    <tr>
                                        <td>Alamat</td>
                                        <td>:</td>
                                        <td>{student?.address}</td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>:</td>
                                        <td>{student?.email}</td>
                                    </tr>
                                    <tr>
                                        <td>Telp</td>
                                        <td>:</td>
                                        <td>{student?.phone}</td>
                                    </tr>
                                    <tr>
                                        <td>Tahun Masuk</td>
                                        <td>:</td>
                                        <td>{student?.entry_year}</td>
                                    </tr>
                                    <tr>
                                        <td>Nama Ayah</td>
                                        <td>:</td>
                                        <td>{student?.father_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Pekerjaan Ayah</td>
                                        <td>:</td>
                                        <td>{student?.father_job}</td>
                                    </tr>
                                    <tr>
                                        <td>Pendidikan Ayah</td>
                                        <td>:</td>
                                        <td>{student?.father_education}</td>
                                    </tr>
                                    <tr>
                                        <td>Nama Ibu</td>
                                        <td>:</td>
                                        <td>{student?.mother_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Pekerjaan Ibu</td>
                                        <td>:</td>
                                        <td>{student?.mother_job}</td>
                                    </tr>
                                    <tr>
                                        <td>Pendidikan Ibu</td>
                                        <td>:</td>
                                        <td>{student?.mother_education}</td>
                                    </tr>
                                    <tr>
                                        <td>Nama Wali</td>
                                        <td>:</td>
                                        <td>{student?.guardian_name}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </>
                        : ""
                }
            </CardBasic>
        </div>
    );
};

export default AuthWrapper(Profile);
