import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CardBasic from '../../../../components/Cards/Basic'
import ModalConfirm from '../../../../components/ModalConfirm';
import Progressbar from '../../../../components/Progressbar';
import { collapseSidebar, setMenuActive } from '../../../../redux/actions';
import { AuthWrapper } from '../../../AuthWrapper';
import { Button, Modal } from 'react-bootstrap';
import SiaHeader from '../../../../components/SiaHeader';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Link } from "react-router-dom";
import TextInput from '../../../../components/TextInput';
import { Controller, useForm } from 'react-hook-form';
import { fileToBase64, notify } from '../../../../constants/utility';
import { getFormOpt, getRapors, postBulkRaporUpload, putRapor, raporUpdate, setGetRapor, setPostBulkRaporUpload, setPutRapor } from '../../../../redux/actions/sia-activity/rapor';
import { download } from '../../../../redux/actions/download';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const RaporActivity = (props) => {
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()
    const { name, title } = props.data
    const [modalShowConfirm, setModalShowConfirm] = useState(false)
    const [modalOption, setModalOption] = useState({})

    const dispatch = useDispatch()

    const { loading, formOpt, rapor, rapors, raporUpdate, raporDelete, bulkRaporUpload } = useSelector((state) => state.siaRapor);
    const { data: userData } = useSelector((state) => state.auth);
    const [semester, setSemester] = useState(null)

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);


    useEffect(() => {
        dispatch(setMenuActive({ menuActive: 'sia', subMenuActive: name }))
        dispatch(collapseSidebar())
        dispatch(getFormOpt())
        dispatch(setGetRapor(null))
    }, [])

    useEffect(() => {
        console.log('formOpt', formOpt)

        return () => {
        };
    }, [formOpt]);

    useEffect(() => {
        if (watch("file")?.length > 0) {
            // console.log('iam fire', watch("file"))
            var reader = new FileReader();
            reader.readAsDataURL(watch("file")[0]);
            reader.onload = function () {
                setPdfUrl(reader.result)
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    }, [watch("file")]);



    useEffect(() => {
        if (bulkRaporUpload) {
            notify(bulkRaporUpload.message, bulkRaporUpload.success ? 'success' : 'error')
            dispatch(getRapors(raporParam))
        }
        return () => {
            dispatch(setPostBulkRaporUpload(null))
        }
    }, [bulkRaporUpload])

    useEffect(() => {
        if (raporUpdate) {
            notify(raporUpdate.message, raporUpdate.success ? 'success' : 'error')
            dispatch(getRapors(raporParam))
            setModalShowPreview(false)
            setDetailData(null)
            setPdfUrl(null)
        }

        return () => {
            dispatch(setPutRapor(null))
        };
    }, [raporUpdate]);


    const columns = [
        {
            dataField: '', text: 'No',
            headerStyle: (c, i) => { return { width: '80px' } },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i, k) => { return (k + 1) }

        },
        {
            dataField: 'student.nis', text: 'NIS', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },
        {
            dataField: 'student.nisn', text: 'NISN', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },

        {
            dataField: 'student.name', text: 'Siswa', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },

        {
            dataField: 'student_class_rapors.file', text: 'Rapor',
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i) => {
                return !c ? 'Belum upload rapor' : c
                // dispatch(download(c))
            }
        },

        {
            dataField: 'id', text: '',
            headerStyle: (colum, colIndex) => { return { width: '100px' }; },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (val, row) => {
                return (
                    <>
                        <span className="btn btn-secondary btn-sm"
                            onClick={() => {
                                setModalShowPreview(true)
                                setDetailData(row)
                                setPdfUrl(row['student_class_rapors.file_url'])
                            }}>
                            <i className="fas fa-search"></i>
                        </span>
                    </>
                )
            }
        },
    ];

    const [raporParam, setRaporParam] = useState(null);
    const submitForm = (data) => {
        data.eduyear_id = data.eduyear_id.value
        data.semester_id = data.semester_id.value
        data.classroom_id = data.classroom_id.value
        setSemester(data.semester_id.value)
        dispatch(getRapors(data))
        setRaporParam(data)
    }

    useEffect(() => {
        if (userData) {
            if (userData.user?.user_type === 'student') {
                dispatch(getRapors({}))
            }
        }
    }, [userData]);


    const indication = () => {
        return 'No data'
    }

    const defaultSorted = [{
        dataField: 'created_at',
        order: 'desc'
    }];

    const [pdfUrl, setPdfUrl] = useState(null)
    const [modalShowPreview, setModalShowPreview] = useState(false)
    const [detailData, setDetailData] = useState(null)

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const raporMasalInput = useRef();

    const clickRaporMasal = () => {
        raporMasalInput.current.click();
    }

    const onRaporMasalChange = (e) => {
        // console.log(e.target.files[0].name)
        if (e.target.files.length > 0) {
            let formData = new FormData();
            formData.append('file', e.target.files[0])
            dispatch(postBulkRaporUpload(formData));
            raporMasalInput.current.value = ""
        }
    }

    const uploadRaporOne = (data) => {
        let formData = new FormData();

        formData.append("id", detailData['student_class_rapors.id'] || 0);
        formData.append("student_id", detailData['student.id']);
        formData.append("semester_id", data.semester_id.value);
        formData.append("classroom_id", data.classroom_id.value);
        formData.append("eduyear_id", data.eduyear_id.value);
        formData.append("file", data.file[0]);
        dispatch(putRapor(formData))
    }

    return (
        <div>
            <Progressbar loading={loading} />
            <SiaHeader />
            <div className="mt-2"></div>
            <CardBasic title={title} nopadding>
                {userData?.user?.user_type === 'administrator' ?
                    <div className="p-3">
                        <form onSubmit={handleSubmit(submitForm)}>
                            <div className="row">
                                <div className="col-md-8">
                                    <Controller
                                        name="eduyear_id"
                                        control={control}
                                        rules={{ required: 'Harus diisi' }}
                                        render={({ field }) =>
                                            <TextInput
                                                layout="horizontal"
                                                label="Tahun Ajaran"
                                                type="select"
                                                placeholder="Tahun Ajaran"
                                                register={register}
                                                field={field}
                                                errors={formState.errors}
                                                options={formOpt?.result?.eduyears?.map(item => { return { value: item.id, label: item.name } }, [])}
                                            />
                                        }
                                    />
                                    <Controller
                                        name="semester_id"
                                        control={control}
                                        rules={{ required: 'Harus diisi' }}
                                        render={({ field }) =>
                                            <TextInput
                                                layout="horizontal"
                                                label="Semester"
                                                type="select"
                                                placeholder="Semester"
                                                register={register}
                                                field={field}
                                                errors={formState.errors}
                                                options={formOpt?.result?.semesters?.map(item => { return { value: item.id, label: item.name } }, [])}
                                            />
                                        }
                                    />
                                    <Controller
                                        name="classroom_id"
                                        control={control}
                                        rules={{ required: 'Harus diisi' }}
                                        render={({ field }) =>
                                            <TextInput
                                                layout="horizontal"
                                                label="Kelas & Rombel"
                                                type="select"
                                                placeholder="Kelas & Rombel"
                                                register={register}
                                                field={field}
                                                errors={formState.errors}
                                                options={formOpt?.result?.classrooms?.map(item => { return { value: item.id, label: item.name } }, [])}
                                            />
                                        }
                                    />
                                </div>
                                <div className="col-md-4">
                                    <button type="submit" className="btn btn-primary btn-md">Lihat Rapor</button>
                                </div>
                            </div>

                        </form>

                    </div>
                    : ""
                }

                {userData?.user?.user_type === 'administrator' ?
                    <div className="overflow-hidden p-3">
                        <button type="button" className="float-right btn btn-info btn-md" onClick={() => clickRaporMasal()}>Upload Rapor Masal</button>
                        <input hidden ref={raporMasalInput} type="file" accept=".zip" onChange={(e) => onRaporMasalChange(e)} />
                    </div>
                    : ""}
                <div className="table-fixied custom-scrollbar table-responsive my-table">
                    <BootstrapTable
                        className="bt-table"
                        bootstrap4
                        hover
                        bordered
                        striped
                        condensed
                        noDataIndication={indication}
                        bordered={false}
                        keyField="id"
                        defaultSorted={defaultSorted}
                        data={rapors?.result || []}
                        columns={columns}
                    />
                </div>
            </CardBasic>

            <Modal
                {...props}
                clos
                show={modalShowPreview}
                size={`lg`}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => {
                    setModalShowPreview(false)
                    setDetailData(null)
                    setPdfUrl(null)
                }}
            >
                <Modal.Header className='text-orimary'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Rapor
                    </Modal.Title>
                    <span className='btn' onClick={() => {
                        setModalShowPreview(false)
                        setDetailData(null)
                        setPdfUrl(null)
                    }}>&times;</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="fixed-modal custom-scrollbar">
                        {userData?.user?.user_type === 'administrator' ?
                            <form onSubmit={handleSubmit(uploadRaporOne)} >
                                <div className="row">
                                    <div className="col-md-8">
                                        <TextInput
                                            validations={{ required: 'Harus diisi' }}
                                            layout="vertical"
                                            name="file"
                                            type="file"
                                            accept=".pdf"
                                            placeholder="File Rapor"
                                            register={register}
                                            errors={formState.errors}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <button className="btn btn-primary btn-md">Upload</button>
                                    </div>
                                    <div className="col-md-2">
                                        {detailData?.['student_class_rapors.file'] ? <button
                                            onClick={() => {
                                                dispatch(download(detailData['student_class_rapors.file']))
                                                setModalShowPreview(false)
                                                setDetailData(null)
                                                setPdfUrl(null)
                                            }}
                                            type="button" className="btn btn-info btn-md">Download</button> : ""}
                                    </div>
                                </div>
                            </form>
                            : detailData?.['student_class_rapors.file'] ? <button
                                onClick={() => {
                                    dispatch(download(detailData['student_class_rapors.file']))
                                    setModalShowPreview(false)
                                    setDetailData(null)
                                    setPdfUrl(null)
                                }}
                                type="button" className="btn btn-info btn-md">Download</button> : ""
                        }
                        {pdfUrl ?
                            <Document
                                options={{ workerSrc: "pdf.worker.js" }}
                                file={pdfUrl}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber} />
                            </Document>
                            : ""}
                    </div>

                </Modal.Body>

            </Modal>

        </div >
    )
}



export default AuthWrapper(RaporActivity)
