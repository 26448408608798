import * as actionTypes from '../../../../constants/actionTypes';
import http from "../../../../services/NetworkService";

export const setOnloading = (payload) => {
    return {
        type: actionTypes.ON_LOADING,
        data: payload,
    };
};

export const setGetLessonClass = (payload) => {
    return {
        type: actionTypes.GET_LESSON_CLASS,
        data: payload,
    };
};

export const getLessonClassOne = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/sia/activity/lesson-class/` + data);
        dispatch(setGetLessonClass(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetLessonClasses = (payload) => {
    return {
        type: actionTypes.GET_LESSON_CLASSES,
        data: payload,
    };
};

export const getLessonClassAll = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const params = {
            page: data?.page || 1,
            page_size: data?.page_size || 10,
            search: data?.search,
            orderby: data?.sort?.orderby || null,
            order: data?.sort?.order || null,
            filter: data?.filter ? encodeURIComponent(JSON.stringify(data?.filter)) : null,
        };

        const result = await http.get(`/sia/activity/lesson-class`, { params });
        dispatch(setGetLessonClasses(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setLessonClassCreate = (payload) => {
    return {
        type: actionTypes.LESSON_CLASS_CREATE,
        data: payload,
    };
}

export const postCreateLessonClass = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/sia/activity/lesson-class`, data);
        dispatch(setOnloading(null))
        dispatch(setLessonClassCreate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setLessonClassUpdate = (payload) => {
    return {
        type: actionTypes.LESSON_CLASS_UPDATE,
        data: payload,
    };
}

export const putUpdateLessonClass = (id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.put(`/sia/activity/lesson-class/${id}`, data);
        dispatch(setOnloading(null))
        dispatch(setLessonClassUpdate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}


export const setLessonClassDelete = (payload) => {
    return {
        type: actionTypes.LESSON_CLASS_DELETE,
        data: payload,
    };
}

export const deleteLessonClass = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.delete(`/sia/activity/lesson-class/${id}`);
        dispatch(setOnloading(null))
        dispatch(setLessonClassDelete(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetLessonClassOptForm = (payload) => {
    return {
        type: actionTypes.GET_LESSON_CLASS_OPTFORM,
        data: payload,
    };
};

export const getLessonClassOptForm = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))

        const result = await http.get(`/sia/activity/lesson-class-opt`);
        dispatch(setGetLessonClassOptForm(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetLessonClassByClassYears = (payload) => {
    return {
        type: actionTypes.GET_LESSON_CLASS_BY_CLASS_YEAR,
        data: payload,
    };
};

export const getLessonClassByClassYears = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        console.log("awowo xx", data)
        const params = {
            classroom_id: data.classroom_id,
            eduyear_id: data.eduyear_id,
            semester_id: data.semester_id,
        }
        const result = await http.get(`/sia/activity/lesson-class/by-class-year`, { params });
        dispatch(setGetLessonClassByClassYears(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}