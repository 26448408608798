import React from 'react'
import { menus } from "../../settings";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { has_access } from '../../constants/utility';

const SiaHeader = () => {
    const { data: userData } = useSelector((state) => state.auth)
    return (

        <nav className="navbar navbar-expand-lg navbar-dark" style={{ backgroundColor: "#00a6e0" }} >
            <div>
                <a className="navbar-brand font-weight-bold" href="#">SIA (Sistem Informasi Akademik)</a>
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse"
                data-target="#navbarSupportedContentx" aria-controls="navbarSupportedContentx" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            {/* justify-content-end */}
            <div className="collapse navbar-collapse" id="navbarSupportedContentx">
                <ul className="navbar-nav ml-autox">

                    {menus.map((item, key) => {
                        if (item.is_sia === true) {
                            if (!item.access) return ""
                            let has_access_check = has_access(item.access, userData?.user?.role_access)
                            if (!has_access_check) return ""
                            if (item.subs?.length > 0) {
                                let subs = item.subs
                                return (
                                    <li className="nav-item dropdown" key={key}>
                                        <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {item.label}
                                        </Link>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            {subs.map((item, key) => {
                                                if (item.hidden === true) return ""
                                                if (!item.access) return ""
                                                let has_access_check = has_access(item.access, userData?.user?.role_access)
                                                if (!has_access_check) return ""
                                                return (
                                                    <Link key={key} className="dropdown-item" to={item.path}>{item.label}</Link>
                                                )
                                            })}
                                        </div>
                                    </li>
                                )
                            }
                            return (
                                <li className="nav-item" key={key}>
                                    <Link className="nav-link" to={item.path}>{item.label} <span className="sr-only">(current)</span></Link>
                                </li>
                            )
                        }
                    })}

                </ul>
            </div>
        </nav>
    )
}

export default SiaHeader
