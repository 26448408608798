import React from "react";

//Redux
import { Provider } from 'react-redux';
import store from "./redux/store";
import Router from "./router";

const App = () => (
    <Provider store={store}>
        <Router key="router" />
    </Provider>
);
export default App;
