import { combineReducers } from "redux";

// import postReducer from "./post";
// import slideReducer from "./slide";
// import galleryReducer from "./gallery";
// import generalReducer from "./general";
// import webMenuReducer from "./web-menu";
// import userReducer from "./master/user";
// import roleReducer from "./master/role";

import menuReducer from "./menu";
import authReducer from "./auth";
import alertReducer from "./alert";
import lessonReducer from "./sia-master/lesson";
import classroomReducer from "./sia-master/classroom";
import eduyearReducer from "./sia-master/eduyear";
import studentReducer from "./sia-master/student";
import teacherReducer from "./sia-master/teacher";
import siaSettingReducer from "./sia-master/setting";
import lessonClassReducer from "./sia-activity/lesson-class";
import studentClassReducer from "./sia-activity/student-class";
import lessonClassStudentReducer from "./sia-activity/lesson-class-student";
import teacherClassLessonReducer from "./sia-activity/teacher-class-lesson";
import studentClassLessonReducer from "./sia-activity/student-class-lesson";
import siaDashboardReducer from "./sia-dashboard";
import siaRaporReducer from "./sia-activity/rapor";
import accountReducer from "./account";

const reducers = combineReducers({
  // post: postReducer,
  // slide: slideReducer,
  // gallery: galleryReducer,
  // general: generalReducer,
  // webMenu: webMenuReducer,
  // user: userReducer,
  // role: roleReducer,

  auth: authReducer,
  alert: alertReducer,
  menu: menuReducer,
  lesson: lessonReducer,
  classroom: classroomReducer,
  eduyear: eduyearReducer,
  student: studentReducer,
  teacher: teacherReducer,
  siaSettings: siaSettingReducer,
  lessonClass: lessonClassReducer,
  studentClass: studentClassReducer,
  lessonClassStudent: lessonClassStudentReducer,
  teacherClassLesson: teacherClassLessonReducer,
  studentClassLesson: studentClassLessonReducer,
  siaDashboard: siaDashboardReducer,
  account: accountReducer,
  siaRapor: siaRaporReducer,

});

export default reducers;
