import React from "react";

const CheckBox = ({ name, placeholder, defaultValue, className, label, register, validations, errors }) => (
    <div className="form-group">
        <div className="custom-control custom-checkbox small">
            <input 
                type="checkbox" 
                className="custom-control-input"
                {...register(name)}
                id={name}/>
            <label className="custom-control-label" htmlFor={name}>{label}</label>
        </div>
    </div>
)

export default CheckBox