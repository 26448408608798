import * as actionTypes from '../../../../constants/actionTypes';
import http from "../../../../services/NetworkService";

export const setOnloading = (payload) => {
    return {
        type: actionTypes.ON_LOADING,
        data: payload,
    };
};

export const setGetTeacherClassLesson = (payload) => {
    return {
        type: actionTypes.TEACHER_CLASS_LESSON,
        data: payload,
    };
};

export const getTeacherClassLessonOne = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/sia/activity/teacher-class-lesson/` + data);
        dispatch(setGetTeacherClassLesson(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetTeacherClassLessons = (payload) => {
    return {
        type: actionTypes.TEACHER_CLASS_LESSONS,
        data: payload,
    };
};

export const getTeacherClassLessons = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const params = {
            page: data?.page || 1,
            page_size: data?.page_size || 10,
            search: data?.search,
            orderby: data?.sort?.orderby || null,
            order: data?.sort?.order || null,
        };

        const result = await http.get(`/sia/activity/teacher-class-lesson`, { params });
        dispatch(setGetTeacherClassLessons(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetTeacherClassLessonStudents = (payload) => {
    return {
        type: actionTypes.TEACHER_CLASS_LESSON_STUDENTS,
        data: payload,
    };
};

export const getTeacherClassLessonStudents = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/sia/activity/teacher-class-lesson/${data}/students`,);
        dispatch(setGetTeacherClassLessonStudents(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

// materials

export const setGetTeacherClassLessonMaterials = (payload) => {
    return {
        type: actionTypes.TEACHER_CLASS_LESSON_MATERIALS,
        data: payload,
    };
};
export const getTeacherClassLessonMaterials = (lesson_class_id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/sia/activity/teacher-class-lesson/${lesson_class_id}/materials`);
        dispatch(setGetTeacherClassLessonMaterials(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPostTeacherClassLessonMaterial = (payload) => {
    return {
        type: actionTypes.TEACHER_CLASS_LESSON_MATERIAL_CREATE,
        data: payload,
    };
};
export const postTeacherClassLessonMaterial = (lesson_class_id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/sia/activity/teacher-class-lesson/${lesson_class_id}/material`, data);
        dispatch(setPostTeacherClassLessonMaterial(result));
        dispatch(setOnloading(null))
    } catch (err) {
        dispatch(setOnloading(null))
        console.error(err);
    }
}

export const setPutTeacherClassLessonMaterial = (payload) => {
    return {
        type: actionTypes.TEACHER_CLASS_LESSON_MATERIAL_UPDATE,
        data: payload,
    };
};

export const putTeacherClassLessonMaterial = (lesson_class_id, id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.put(`/sia/activity/teacher-class-lesson/${lesson_class_id}/material/${id}`, data);
        dispatch(setPutTeacherClassLessonMaterial(result));
        dispatch(setOnloading(null))
    } catch (err) {
        dispatch(setOnloading(null))
        console.error(err);
    }
}

export const setDeleteTeacherClassLessonMaterial = (payload) => {
    return {
        type: actionTypes.TEACHER_CLASS_LESSON_MATERIAL_DELETE,
        data: payload,
    };
};

export const deleteTeacherClassLessonMaterial = (lesson_class_id, id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.delete(`/sia/activity/teacher-class-lesson/${lesson_class_id}/material/${id}`, data);
        dispatch(setDeleteTeacherClassLessonMaterial(result));
        dispatch(setOnloading(null))
    } catch (err) {
        dispatch(setOnloading(null))
        console.error(err);
    }
}


// tasks- --------------------------

export const setGetTeacherClassLessonTasks = (payload) => {
    return {
        type: actionTypes.TEACHER_CLASS_LESSON_TASKS,
        data: payload,
    };
};
export const getTeacherClassLessonTasks = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))

        const result = await http.get(`/sia/activity/teacher-class-lesson/${data}/tasks`);
        dispatch(setGetTeacherClassLessonTasks(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPostTeacherClassLessonTask = (payload) => {
    return {
        type: actionTypes.TEACHER_CLASS_LESSON_TASK_CREATE,
        data: payload,
    };
};
export const postTeacherClassLessonTask = (lesson_class_id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))

        const result = await http.post(`/sia/activity/teacher-class-lesson/${lesson_class_id}/task`, data);
        dispatch(setPostTeacherClassLessonTask(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPutTeacherClassLessonTask = (payload) => {
    return {
        type: actionTypes.TEACHER_CLASS_LESSON_TASK_UPDATE,
        data: payload,
    };
};
export const putTeacherClassLessonTask = (lesson_class_id, id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))

        const result = await http.put(`/sia/activity/teacher-class-lesson/${lesson_class_id}/task/${id}`, data);
        dispatch(setPutTeacherClassLessonTask(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setDeleteTeacherClassLessonTask = (payload) => {
    return {
        type: actionTypes.TEACHER_CLASS_LESSON_TASK_DELETE,
        data: payload,
    };
};
export const deleteTeacherClassLessonTask = (lesson_class_id, id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))

        const result = await http.delete(`/sia/activity/teacher-class-lesson/${lesson_class_id}/task/${id}`);
        dispatch(setDeleteTeacherClassLessonTask(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

// export const setGetTeacherClassLessonTaskSubmits = (payload) => {
//     return {
//         type: actionTypes.TEACHER_CLASS_LESSON_TASK_SUBMITS,
//         data: payload,
//     };
// };
// export const getTeacherClassLessonTask = (lesson_class_id, id) => async (dispatch) => {
//     try {
//         dispatch(setOnloading(true))

//         const result = await http.get(`/sia/activity/teacher-class-lesson/${lesson_class_id}/task/${id}/submits`);
//         dispatch(setGetTeacherClassLessonTaskSubmits(result))
//         dispatch(setOnloading(null))
//     } catch (err) {
//         console.error(err);
//         dispatch(setOnloading(null))
//     }
// }

// task student
export const setGetTeacherClassLessonTaskStudents = (payload) => {
    return {
        type: actionTypes.TEACHER_CLASS_LESSON_TASK_STUDENTS,
        data: payload,
    };
};

export const getTeacherClassLessonTaskStudents = (class_id, task_id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))

        const result = await http.get(`/sia/activity/teacher-class-lesson/${class_id}/task/${task_id}/submits`);
        dispatch(setGetTeacherClassLessonTaskStudents(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPostUploadAttachment = (payload) => {
    return {
        type: actionTypes.TEACHER_CLASS_LESSON_ATTACHMENT_UPLOAD,
        data: payload,
    };
};
export const postUploadAttachment = (data) => async (dispatch) => {
    try {
        const result = await http.post(`/sia/activity/teacher-class-lesson/upload-attachment`, data);
        // console.log('alsocodes http', result)
        dispatch(setPostUploadAttachment(result));
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}