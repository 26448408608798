import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTeacherClassLessonTask, getTeacherClassLessonTasks, setDeleteTeacherClassLessonTask, setPostTeacherClassLessonTask, setPostUploadAttachment, setPutTeacherClassLessonTask } from '../../../../../../redux/actions/sia-activity/teacher-class-lesson';
import { Link, useSearchParams } from 'react-router-dom';
import Form from './Form'
import ModalConfirm from '../../../../../../components/ModalConfirm';
import { Button, Modal } from 'react-bootstrap';
import { formatDate, notify } from '../../../../../../constants/utility';
import Detail from './Detail';
import { download } from '../../../../../../redux/actions/download';

const TeacherClassLessonTasks = (props) => {
    const { id, tabKey } = props
    const { teacherClassLessonTasks,
        teacherClassLessonTaskCreate,
        teacherClassLessonTaskUpdate,
        teacherClassLessonTaskDelete,
        teacherClassLessonTaskUpload
    } = useSelector((state) => state.teacherClassLesson);
    const dispatch = useDispatch()
    const [activeId, setActiveId] = useState(0)

    useEffect(() => {
        if (tabKey === 'task') dispatch(getTeacherClassLessonTasks(id))
    }, [tabKey])
    const [formData, setFormData] = useState(null);
    const [formType, setFormType] = useState(null);

    const indication = () => {
        return 'No data'
    }

    const defaultSorted = [{
        dataField: 'created_at',
        order: 'desc'
    }];

    useEffect(() => {
        setActiveId(teacherClassLessonTaskCreate?.result?.id || teacherClassLessonTaskUpdate?.result?.id || null)
        if (teacherClassLessonTaskCreate || teacherClassLessonTaskUpdate || teacherClassLessonTaskDelete) dispatch(getTeacherClassLessonTasks(id))
        dispatch(setPostUploadAttachment(null))

        if (teacherClassLessonTaskCreate) {
            notify(teacherClassLessonTaskCreate.message, teacherClassLessonTaskCreate.success ? 'success' : 'error')
        }

        if (teacherClassLessonTaskUpdate) {
            notify(teacherClassLessonTaskUpdate.message, teacherClassLessonTaskUpdate.success ? 'success' : 'error')
        }
        if (teacherClassLessonTaskDelete) {
            notify(teacherClassLessonTaskDelete.message, teacherClassLessonTaskDelete.success ? 'success' : 'error')
        }
    }, [teacherClassLessonTaskCreate, teacherClassLessonTaskUpdate, teacherClassLessonTaskDelete])

    useEffect(() => {
        if (teacherClassLessonTasks?.result) {
            setFormData(teacherClassLessonTasks.result.filter(item => item.id === activeId)[0] || null)
        }
    }, [teacherClassLessonTasks])

    const onCloseForm = () => {
        dispatch(setPostTeacherClassLessonTask(null))
        dispatch(setPutTeacherClassLessonTask(null))
        dispatch(setDeleteTeacherClassLessonTask(null))
        dispatch(setPostUploadAttachment(null))
        setFormData(null)
        setFormType(null)
    }

    const [modalShowConfirm, setModalShowConfirm] = useState(false)
    const [modalOption, setModalOption] = useState({})
    const onDelete = (data) => {

        setModalShowConfirm(true)
        setModalOption({
            title: 'Konfirmasi hapus',
            content: (
                <div>
                    <p>Anda yakin akan menghapus <strong>{data?.name}</strong> ?</p>

                    <hr mb={2} mt={2} />
                    <Button className='float-left' size='sm' variant={`outline-secondary`} onClick={() => setModalShowConfirm(false)}>Batal</Button>
                    <Button className='float-right' variant={`danger`} size='sm'
                        onClick={() => onYesDelete(data.id)}>Hapus</Button>
                </div>
            ),
            color: 'danger',
            size: 'md',
            data: data,
        })
    }

    const onYesDelete = (id_) => {
        dispatch(deleteTeacherClassLessonTask(id, id_))
        setModalShowConfirm(false)
    }

    const columns = [
        {
            dataField: '', text: 'No',
            headerStyle: (c, i) => { return { width: '80px' } },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i, k) => { return k + 1 }

        },
        {
            dataField: 'name', text: 'Nama', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },

        },
        {
            dataField: 'description', text: 'Keterangan', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i) => { return c.substring(0, 100) }
        },
        {
            dataField: 'attachment', text: 'Lampiran', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i) => {
                return c ?
                    <span>{c}&nbsp;
                        <span className="btn btn-secondary btn-sm"
                            onClick={() => dispatch(download(i.attachment))}>
                            <i className="fas fa-download"></i>
                        </span>
                    </span>
                    : ""
            }
        },
        {
            dataField: 'status', text: 'Status', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },
        {
            dataField: 'created_at', text: 'Dibuat', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i) => {
                return formatDate(c)
            }
        },
        {
            dataField: 'id', text: '',
            //headerStyle: (colum, colIndex) => { return { width: '100px' }; },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (val, row) => {
                return (
                    <>
                        <span
                            onClick={() => {
                                setFormData(row)
                                setFormType('detail')
                            }} className="btn btn-info btn-sm m-1"><i className="fas fa-search"></i></span>
                        <span
                            onClick={() => {
                                setFormData(row)
                                setFormType('form')
                            }} className="btn btn-warning btn-sm m-1"><i className="fas fa-edit"></i></span>
                        <span onClick={() => onDelete(row)} className="btn btn-danger btn-sm m-1"><i className="fas fa-trash"></i></span>
                    </>
                )
            }
        },
    ];

    return (
        <div>

            {formData === null ?
                <>
                    <div className="mb-2 overflow-hidden">
                        <span className="btn btn-primary btn-sm float-right" onClick={() => {
                            setFormData({ id: 0 })
                            setFormType('form')
                        }}>Tambah</span>
                    </div>
                    <BootstrapTable
                        className="bt-table"
                        bootstrap4
                        hover
                        bordered
                        striped
                        condensed
                        noDataIndication={indication}
                        bordered={true}
                        keyField="id"
                        defaultSorted={defaultSorted}
                        data={teacherClassLessonTasks?.result || []}
                        columns={columns}
                    // pagination={paginationFactory({ page, sizePerPage, totalSize })}
                    // onTableChange={onTableChange}
                    />
                    <ModalConfirm
                        show={modalShowConfirm}
                        modalOption={modalOption}
                        onHide={() => setModalShowConfirm(false)}
                    />
                </>
                : formType === 'form' ? <Form form_data={formData} lesson_class_id={id} close={() => onCloseForm()} />
                    : formType === 'detail' ? <Detail data={formData} lesson_class_id={id} close={() => onCloseForm()} />
                        : ""
            }
        </div>
    )
}

export default TeacherClassLessonTasks
