import axios from "axios";
import { LOCAL_USER_KEY } from "../constants/keys";
// import store from "../store/index";
import store from "../redux/store";
// import { openErrorModal } from "../store/actions/index";
import { api_url, notify } from "../constants/utility";

const instance = axios.create({
  baseURL: api_url,
  timeout: 10000,
});

instance.interceptors.request.use(
  (config) => {
    const authState = store.getState().auth;
    const token = authState.data?.access_token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem(LOCAL_USER_KEY);
      window.location = "/signin";
    } else if (error.response?.status === 301) {
      const close = () => window.location = error?.response?.data?.result?.redirect
      notify(error.response.data.message, 'warning', close)
    } else {
      notify(error.response.data.message, 'error')
      return Promise.reject(
        error.response?.data || { message: "Network error!" }
      );
    }
  }
);

export default instance;
