import React from 'react'
import { useDispatch } from 'react-redux'
import { formatDate } from '../../../../../../../../constants/utility'
import { download } from '../../../../../../../../redux/actions/download'
import { getStudentClassLessonTaskSubmit } from '../../../../../../../../redux/actions/sia-activity/student-class-lesson'
import Form from './Form'
const Detail = (props) => {
    const { lesson_class_id, data, close } = props
    const dispatch = useDispatch()

    return (
        <div>
            <div className="d-flex border-bottom mb-4 mt-2 py-2 ">
                <span
                    className="btn btn-sm btn-secondary mr-2"
                    onClick={() => close()}
                    style={{ height: "30px", width: "50px" }}><i className="fas fa-arrow-left"></i></span>
                <h6 className="font-weight-bold p-1">Detail Tugas</h6>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-2">Nama</div>
                <div className="col-sm-12 col-md-10">: {data.name}</div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-2">Keterangan</div>
                <div className="col-sm-12 col-md-10">: {data.description}</div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-2">Lampiran</div>
                <div className="col-sm-12 col-md-10">: {data.attachment}
                    <span
                        className="btn btn-secondary btn-sm"
                        onClick={() => dispatch(download(data.attachment))}>
                        <i className="fas fa-download"></i>
                    </span>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-2">Status</div>
                <div className="col-sm-12 col-md-10">: {data.status}</div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-2">Batas Pengumulan</div>
                <div className="col-sm-12 col-md-10">: {data.open_at ? formatDate(data.deadline_at) : '-'}</div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-2">Dibuka pada</div>
                <div className="col-sm-12 col-md-10">: {data.open_at ? formatDate(data.open_at) : '-'}</div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-2">Ditutup pada</div>
                <div className="col-sm-12 col-md-10">: {data.close_at ? formatDate(data.close_at) : '-'}</div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-12 col-md-2">Dibuat</div>
                <div className="col-sm-12 col-md-10">: {formatDate(data.created_at)}</div>
            </div>
            {/* <hr /> */}
            <Form lesson_class_id={lesson_class_id} id={data.id} />
        </div>
    )
}

export default Detail
