import * as actionTypes from '../../../constants/actionTypes';
import http from "../../../services/NetworkService";

export const setOnloading = (payload) => {
    return {
        type: actionTypes.ON_LOADING,
        data: payload,
    };
};


export const setGetSiaDashboard = (payload) => { return { type: actionTypes.GET_SIA_DASHBOARD, data: payload } }
export const getSiaDashboard = () => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/sia-dashboard`);
        dispatch(setGetSiaDashboard(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}