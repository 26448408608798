import * as actionTypes from "../../../../constants/actionTypes";
import { updateObject } from "../../../../constants/utility";

const initialState = {
    loading: null,
    eduyearCreate: null,
    eduyearUpdate: null,
    eduyearDelete: null,
    eduyear: null,
    eduyears: null,
    importCsv: null
};

const loading = (state, data) => {
    return updateObject(state, {
        loading: data
    })
}


const eduyearCreate = (state, data) => {
    return updateObject(state, {
        eduyearCreate: data,
    });
}

const getEduyear = (state, data) => {
    return updateObject(state, {
        eduyear: data,
    });
}

const getEduyears = (state, data) => {
    return updateObject(state, {
        eduyears: data,
    });
}

const eduyearUpdate = (state, data) => {
    return updateObject(state, {
        eduyearUpdate: data,
    });
}

const eduyearDelete = (state, data) => {
    return updateObject(state, {
        eduyearDelete: data,
    });
}

const setImportCsv = (state, data) => {
    return updateObject(state, {
        importCsv: data,
    });
}

const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
        case actionTypes.POST_IMPORT_CSV:
            return setImportCsv(state, data);
        case actionTypes.EDUYEAR_DELETE:
            return eduyearDelete(state, data);
        case actionTypes.EDUYEAR_UPDATE:
            return eduyearUpdate(state, data);
        case actionTypes.GET_EDUYEARS:
            return getEduyears(state, data);
        case actionTypes.GET_EDUYEAR:
            return getEduyear(state, data);
        case actionTypes.EDUYEAR_CREATE:
            return eduyearCreate(state, data);
        case actionTypes.ON_LOADING:
            return loading(state, data);
        default:
            return state;
    }
};

export default reducer;
