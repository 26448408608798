import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import TextInput from '../../../../../../../../../components/TextInput'
import { formatDate, notify } from '../../../../../../../../../constants/utility'
import { download } from '../../../../../../../../../redux/actions/download'
import { postUploadAttachment, postStudentClassLessonTaskSubmit, putStudentClassLessonTaskSubmit, deleteStudentClassLessonTaskSubmit, getStudentClassLessonTaskSubmit } from '../../../../../../../../../redux/actions/sia-activity/student-class-lesson'

const Form = (props) => {
    const { lesson_class_id, id } = props
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()
    const dispatch = useDispatch()
    const { studentClassLessonTaskSubmit, studentClassLessonTaskSubmitCreate, studentClassLessonTaskSubmitUpdate, studentClassLessonAttachmentUpload } = useSelector((state) => state.studentClassLesson);
    useEffect(() => {
        dispatch(getStudentClassLessonTaskSubmit(lesson_class_id, id))
    }, [])

    const statusOptions = [
        { value: 'draft', label: 'Draft' },
        { value: 'submit', label: 'Submit' },
    ]

    useEffect(() => {
        if (studentClassLessonTaskSubmitCreate || studentClassLessonTaskSubmitUpdate) dispatch(getStudentClassLessonTaskSubmit(lesson_class_id, id))
        if (studentClassLessonTaskSubmitCreate) {
            notify(studentClassLessonTaskSubmitCreate.message, studentClassLessonTaskSubmitCreate.success ? 'success' : 'error')
        }

        if (studentClassLessonTaskSubmitUpdate) {
            notify(studentClassLessonTaskSubmitUpdate.message, studentClassLessonTaskSubmitUpdate.success ? 'success' : 'error')
        }
    }, [studentClassLessonTaskSubmitCreate, studentClassLessonTaskSubmitUpdate])

    const submitTask = (data) => {
        data.attachment = studentClassLessonAttachmentUpload?.result?.filename || studentClassLessonTaskSubmit?.result?.student?.lesson_class_task_submit?.attachment || null
        data.status = data.status.value
        // console.log(data, studentClassLessonAttachmentUpload?.result?.filename)
        if (studentClassLessonTaskSubmit?.result?.student?.lesson_class_task_submit?.id === undefined) {
            dispatch(postStudentClassLessonTaskSubmit(lesson_class_id, id, data))
        } else {
            dispatch(putStudentClassLessonTaskSubmit(lesson_class_id, id, studentClassLessonTaskSubmit?.result?.student?.lesson_class_task_submit?.id, data))
        }
    }

    useEffect(() => {
        if (watch('attachment')?.length > 0) {
            console.log(watch('attachment'))
            let fromData = new FormData();
            fromData.append('attachment', watch('attachment')[0])
            if (studentClassLessonAttachmentUpload) fromData.append('old_attachment', studentClassLessonAttachmentUpload.result.filename)
            dispatch(postUploadAttachment(fromData));
        }
    }, [watch('attachment')])

    console.log('xxas', studentClassLessonTaskSubmit?.result?.student?.lesson_class_task_submit?.id)
    useEffect(() => {
        console.log(studentClassLessonTaskSubmit)
        setValue('answer', studentClassLessonTaskSubmit?.result?.student?.lesson_class_task_submit?.answer || '')
        if (studentClassLessonTaskSubmit?.result?.student?.lesson_class_task_submit?.status)
            setValue('status', statusOptions.filter(item => item.value === studentClassLessonTaskSubmit?.result?.student?.lesson_class_task_submit?.status)?.[0])
        else setValue('status', null)
    }, [studentClassLessonTaskSubmit])

    return (
        <div>
            <div className="d-flex border-bottom mb-4 mt-2 py-2 ">
                <h6 className="font-weight-bold p-1">{studentClassLessonTaskSubmit?.result?.student?.lesson_class_task_submit?.status === 'submit' ? `Detail` : `Form`} Tugas</h6>
            </div>
            {studentClassLessonTaskSubmit?.result?.student?.lesson_class_task_submit?.status === 'submit' ?
                <div>
                    <div className="row mb-2">
                        <div className="col-sm-12 col-md-2">Jawaban/Response</div>
                        <div className="col-sm-12 col-md-10">: {studentClassLessonTaskSubmit?.result?.student?.lesson_class_task_submit?.answer}</div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-sm-12 col-md-2">Lampiran</div>
                        <div className="col-sm-12 col-md-10">:
                            {studentClassLessonTaskSubmit?.result?.student?.lesson_class_task_submit?.attachment}
                            <span
                                className="btn btn-secondary btn-sm"
                                onClick={() =>
                                    dispatch(download(studentClassLessonTaskSubmit?.result?.student?.lesson_class_task_submit?.attachment))}>
                                <i className="fas fa-download"></i>
                            </span>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-sm-12 col-md-2">Status</div>
                        <div className="col-sm-12 col-md-10">: {studentClassLessonTaskSubmit?.result?.student?.lesson_class_task_submit?.status}</div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-sm-12 col-md-2">Submit Pada</div>
                        <div className="col-sm-12 col-md-10">: {formatDate(studentClassLessonTaskSubmit?.result?.student?.lesson_class_task_submit?.submitted_at)}</div>
                    </div>
                </div>

                :
                <form onSubmit={handleSubmit(submitTask)}>
                    <div className="row">
                        <div className="col-md-6">
                            <TextInput
                                layout="horizontal"
                                label="Respon/Jawaban"
                                type="textarea"
                                name="answer"
                                placeholder="Respon/Jawaban"
                                register={register}
                                validations={{ required: "Harus diisi" }}
                                errors={formState.errors}
                            />

                            <Controller
                                name="status"
                                control={control}
                                rules={{ required: 'Harus diisi' }}
                                render={({ field }) =>
                                    <TextInput
                                        layout="horizontal"
                                        label="Status"
                                        type="select"
                                        placeholder="Status"
                                        register={register}
                                        field={field}
                                        errors={formState.errors}
                                        options={statusOptions}
                                    />
                                }
                            />
                        </div>
                        <div className="col-md-6">
                            <TextInput
                                layout="horizontal"
                                label="Lampiran"
                                type="file"
                                name="attachment"
                                placeholder="Lampiran"
                                register={register}
                                accept=".jpg,.jpeg,.png,.doc,.txt,.csv,.xls,.xlsx,.ppt,.pptx,.zip,.rar"
                                errors={formState.errors}
                            />
                        </div>
                        <div className="col-md-12">
                            <hr />
                            <div className="d-flex justify-content-between">
                                {/* <button type="button" className="btn btn-light btn-md" onClick={() => close()}>Kembali</button> */}
                                <button type="submit" className="btn btn-primary btn-md">{studentClassLessonTaskSubmit?.result?.student?.lesson_class_task_submit?.id === undefined ? `Simpan` : `Update`}</button>
                            </div>
                        </div>
                    </div>
                </form>
            }
        </div>
    )
}

export default Form
